import { AsyncPipe, CommonModule, LowerCasePipe, NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import type { AfterViewChecked, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { Router } from '@angular/router';
import type {
  BreakpointsTypes,
  IInventoryItem,
  IInventoryItemsFilterForm,
  IInventoryRequestParams,
  IInventoryShortInfo,
  IShopMeta,
  IShopRequestParams,
  ISkinItem,
  IStoreItemsFilterForm,
} from '@dev-fast/types';
import {
  CardStatusEnum,
  COLLECT_BY_PRICE,
  InventorySortingTypes,
  InventoryTrade,
  ModalNames,
  PRICE_FILTER,
  TransactionWay,
} from '@dev-fast/types';
import { ItemSimpleCardComponent, SelectionMenuSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import type { InViewportAction } from 'ng-in-viewport';
import { InViewportDirective } from 'ng-in-viewport';
import type { NgScrollbar } from 'ngx-scrollbar';
import { NgScrollbarModule } from 'ngx-scrollbar';
import type { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

import { CurrencyComponent } from '@app/core/currency';
import { InventoryService } from '@app/core/inventory-service';
import { AppCurrencyModule } from '@app/shared/pipes';
import { breakPointStage, debounce } from '@app/shared/utils';

@Component({
  selector: 'app-trade-modal',
  templateUrl: './trade-modal.component.html',
  styleUrls: ['./trade-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    TranslateModule,
    AsyncPipe,
    AppCurrencyModule,
    NgClass,
    NgStyle,
    NgScrollbarModule,
    LowerCasePipe,
    InViewportDirective,
    ReactiveFormsModule,
    MatTabsModule,
    NgIf,
    NgForOf,
    CurrencyComponent,
    //ui components
    SelectionMenuSAComponent,
    ItemSimpleCardComponent,
    CommonModule,
  ],
})
export class TradeModalComponent implements OnInit, OnDestroy, AfterViewChecked {
  readonly #inventoryService = inject(InventoryService);
  readonly #router = inject(Router);

  @ViewChild('shopScroll') shopScroll!: NgScrollbar;
  @ViewChild('inventoryScroll') inventoryScroll!: NgScrollbar;
  @ViewChild('tradeModal') tradeModal!: NgScrollbar;
  destroy$ = new Subject();
  breakpointStg = breakPointStage;
  selectionAmount$: Observable<number | null> = this.#inventoryService.selectionAmount$;
  inventoryItems$: Observable<IInventoryItem[]> = this.#inventoryService.items$;

  inventoryParams$: Observable<IInventoryRequestParams> = this.#inventoryService.inventoryParams$;
  shopItems$: Observable<ISkinItem[]> = this.#inventoryService.shopItems$;
  selectedShopItems$: Observable<ISkinItem[]> = this.#inventoryService.contracts$;
  shopParams$: Observable<IShopRequestParams> = this.#inventoryService.shopParams$;
  maxInventoryPages$: Observable<number | null> = this.#inventoryService.maxInventoryPages$;
  shopMeta$: Observable<IShopMeta> = this.#inventoryService.shopMeta$;
  maxShopPages$: Observable<number | null> = this.#inventoryService.maxShopPages$;
  breakpoints$: Observable<BreakpointsTypes | null> = this.#inventoryService.breakpoints$;
  activeTab: InventoryTrade = InventoryTrade.Inventory;
  shopFilter!: FormGroup;
  inventoryFilter!: FormGroup;
  collectionControl = new FormControl<any>('');
  //
  readonly inventoryInfo$: Observable<IInventoryShortInfo> = this.#inventoryService.inventoryShortInfo$;
  readonly selectedItems$: Observable<IInventoryItem[]> = this.#inventoryService.selectedItems$;
  readonly selectedItemsPrice$: Observable<number> = this.#inventoryService.currentSelectedItemsSum$;
  readonly selectedShopItemsPrice$: Observable<number> = this.#inventoryService.selectedShopItemsPrice$;
  readonly isTradeDataLoading = toSignal(this.#inventoryService.inventoryShopLoading$);

  readonly userBalance$: Observable<number> = this.#inventoryService.userBalance$;
  readonly userBalanceDelta$: Observable<number> = this.#inventoryService.userBalanceDelta$;
  //view
  readonly isSelectAll$: Observable<boolean> = this.#inventoryService.isSelectAll$;
  //enums & const
  readonly InventorySortingTypes = InventorySortingTypes;
  readonly priceFilterCfg = PRICE_FILTER;
  readonly collectByPrice = COLLECT_BY_PRICE;

  constructor() {
    this.onGetShopByValue = debounce(this.onGetShopByValue.bind(this), 400);
    this.onRefresh = debounce(this.onRefresh.bind(this), 400);

    this.inventoryFilter = new FormGroup<IInventoryItemsFilterForm>({
      sortBy: new FormControl<InventorySortingTypes>(InventorySortingTypes.MAX_PRICE),
    });
    this.shopFilter = new FormGroup<IStoreItemsFilterForm>({
      sortBy: new FormControl<InventorySortingTypes>(InventorySortingTypes.MAX_PRICE),
      minPrice: new FormControl<number>(this.priceFilterCfg[0].name[0]),
      maxPrice: new FormControl<number>(this.priceFilterCfg[0].name[1]),
      query: new FormControl<string>(''),
    });

    this.collectionControl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe((value: number) => {
        this.#inventoryService.changeShopParams({ selectionSum: value * 100 });
        if (!value) {
          this.#inventoryService.unselectItems('shop');
        }
      });

    this.inventoryFilter.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400)).subscribe((data) => {
      this.#inventoryService.changeParams(data);
      this.#scrollInventoryToTop();
    });

    this.shopFilter.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400)).subscribe((data) => {
      this.#inventoryService.changeShopParams(data);
      this.#scrollShopToTop();
    });
  }

  ngOnInit(): void {
    this.#inventoryService.changeShopParams({ page: 1 });
    this.#inventoryService.changeParams({ page: 1 });
  }

  ngAfterViewChecked(): void {
    if (this.tradeModal.nativeElement.clientWidth < 1260 && this.shopFilter.get('query')?.value) {
      this.shopFilter.patchValue({
        query: '',
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next('');
    this.destroy$.complete();
  }

  getCardStatus(id: number, items: ISkinItem[] | null): CardStatusEnum | null {
    if (this.#isSelected(id, items)) {
      return CardStatusEnum.SELECTED;
    }
    return null;
  }

  onRefresh(payload: 'inventory' | 'trade'): void {
    this.#inventoryService.refresh(payload);
    payload === 'trade' ? this.#scrollShopToTop() : this.#scrollInventoryToTop();
  }

  onCloseModal(): void {
    this.#inventoryService.unselectItems();
    this.#inventoryService.closeModal(ModalNames.TRADE);
  }

  // Обработчики действий

  // инвертирует метод сортировки в форме
  onChangeFormSortBy(form: FormGroup): void {
    const sortBy = form.get('sortBy')?.value;
    form.controls['sortBy'].patchValue(
      sortBy === InventorySortingTypes.MAX_PRICE ? InventorySortingTypes.MIN_PRICE : InventorySortingTypes.MAX_PRICE,
    );
  }

  onPriceSelect(priceIndex: number): void {
    this.shopFilter.patchValue({
      minPrice: this.priceFilterCfg[priceIndex].name[0],
      maxPrice: this.priceFilterCfg[priceIndex].name[1],
    });
  }

  onGetShopByValue(value: number): void {
    this.#inventoryService.changeShopParams({ selectionSum: value });
    this.collectionControl.patchValue(this.#inventoryService.convertValue(value), { emitEvent: false });
  }

  onSelectInventoryItem(id: number): void {
    this.#inventoryService.onItemClick(id);
  }

  onSelectShopItem(id: number): void {
    this.#inventoryService.onShopItemClick(id);
  }

  onNavigateToRefill(): void {
    this.#inventoryService.closeModal(ModalNames.TRADE);
    this.#router.navigate([{ outlets: { modal: ['transaction', TransactionWay.REFILL] } }]);
  }

  onTrade(): void {
    this.#inventoryService.trade();
    this.#inventoryService.unselectItems();
    this.#inventoryService.getShop('old');
  }

  onScrollToBottomInventory(event: InViewportAction, pageNumber: number): void {
    if (pageNumber && event.visible) {
      this.#inventoryService.changeInventoryPage(pageNumber + 1);
    }
  }

  onScrollToBottomShop(event: InViewportAction, pageNumber: number): void {
    if (pageNumber && event.visible) {
      this.#inventoryService.changeShopPage(pageNumber + 1);
    }
  }

  onUnselectItems(payload?: 'shop' | 'inventory'): void {
    this.#inventoryService.unselectItems(payload);
    this.#inventoryService.getShop('old');
    this.collectionControl.setValue('');
  }

  onPurchaseItems(ids: ISkinItem[] | null, userInventoryIds: IInventoryItem[] | null): void {
    this.#inventoryService.purchase(
      (ids || []).map((item) => item.id),
      (userInventoryIds || []).map((item) => item.id),
    );
    this.collectionControl.setValue('');
  }

  onSellItems(ids: IInventoryItem[] | null): void {
    if (ids) {
      this.#inventoryService.sellItems(ids.map((item) => item.id));
    }
  }

  onSelectAll(): void {
    this.#inventoryService.toggleIsSelectAll();
  }

  // Приватные методы

  #isSelected(id: number, items: ISkinItem[] | null): boolean {
    if (!items) {
      return false;
    }
    return items.some((item: ISkinItem) => item.id === id);
  }

  #scrollInventoryToTop(): void {
    this.inventoryScroll.scrollTo({ top: 0 });
  }

  #scrollShopToTop(): void {
    this.shopScroll.scrollTo({ top: 0 });
  }
}
