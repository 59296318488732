import { inject, Injectable } from '@angular/core';
import { ClassicBackendService } from '@dev-fast/backend-services';
import { EClassicPhase } from '@dev-fast/types';
import type { NgxsOnInit, StateContext } from '@ngxs/store';
import { Action, State, Store } from '@ngxs/store';
import { filter, map, switchMap, tap, timer } from 'rxjs';

import { GamesState } from '@app/core/state/games-store';
import { GetInventoryInfo, GetInventoryItems } from '@app/core/state/inventory';

import { ChangePhase, GetHistory, GetUserWinningsItems, UpdatePartialState } from '../../classic.actions';
import { getPrizesDuration, getRafflingDuration } from '../../classic.helpers';
import {
  SubscribeClassicHistorySockets,
  SubscribeClassicSockets,
  UnsubscribeClassicHistorySockets,
  UnsubscribeClassicSockets,
} from './classic-sockets.actions';
import type { IClassicSocketsStateModel } from './classic-sockets.model';
import { CLASSIC_SOCKETS_INITIAL_STATE } from './classic-sockets.model';

@State<IClassicSocketsStateModel>({
  defaults: CLASSIC_SOCKETS_INITIAL_STATE,
  name: 'sockets',
})
@Injectable()
export class ClassicSocketsState implements NgxsOnInit {
  // Подключение сервисов
  readonly #classicBackendService = inject(ClassicBackendService);
  readonly #store = inject(Store);

  // Методы жизненного цикла
  ngxsOnInit(stateContext: StateContext<IClassicSocketsStateModel>): void {
    this.#initGameSocket(stateContext);
    this.#initInventorySocket(stateContext);
    this.#initParticipantsSocket(stateContext);
    this.#initWinnerSocket(stateContext);
  }

  @Action(SubscribeClassicSockets)
  subscribeToAll({ patchState }: StateContext<IClassicSocketsStateModel>): void {
    patchState({ isSubscribedToAll: true });
  }

  @Action(SubscribeClassicHistorySockets)
  subscribeToHistory({ patchState }: StateContext<IClassicSocketsStateModel>): void {
    patchState({ isSubscribedToHistory: true });
  }

  @Action(UnsubscribeClassicSockets)
  unsubscribeToAll({ patchState }: StateContext<IClassicSocketsStateModel>): void {
    patchState({ isSubscribedToAll: false });
  }

  @Action(UnsubscribeClassicHistorySockets)
  unsubscribeToHistory({ patchState }: StateContext<IClassicSocketsStateModel>): void {
    patchState({ isSubscribedToAll: false });
  }

  // Прочие методы
  #initGameSocket({ dispatch, getState }: StateContext<IClassicSocketsStateModel>): void {
    this.#classicBackendService
      .gameSocket()
      .pipe(
        filter(() => getState().isSubscribedToAll),
        tap((response) =>
          dispatch([
            new UpdatePartialState(response),
            new ChangePhase(EClassicPhase.INITIAL),
            ...(getState().isSubscribedToHistory ? [new GetHistory()] : []),
          ]),
        ),
      )
      .subscribe();
  }

  #initInventorySocket({ dispatch, getState }: StateContext<IClassicSocketsStateModel>): void {
    this.#classicBackendService
      .inventorySocket()
      .pipe(
        filter(() => getState().isSubscribedToAll),
        tap(({ items }) => dispatch([new GetInventoryItems(), new GetInventoryInfo(), new GetUserWinningsItems(items)])),
      )
      .subscribe();
  }

  #initParticipantsSocket({ dispatch, getState }: StateContext<IClassicSocketsStateModel>): void {
    this.#classicBackendService
      .participantsSocket()
      .pipe(
        filter(() => getState().isSubscribedToAll),
        tap((response) =>
          dispatch([
            new UpdatePartialState(response),
            ...(response.rafflingTimestampDiff ? [new ChangePhase(EClassicPhase.COUNTDOWN)] : []),
          ]),
        ),
      )
      .subscribe();
  }

  #initWinnerSocket({ dispatch, getState }: StateContext<IClassicSocketsStateModel>): void {
    this.#classicBackendService
      .winnerSocket()
      .pipe(
        filter(() => getState().isSubscribedToAll),
        tap((response) => dispatch([new UpdatePartialState(response), new ChangePhase(EClassicPhase.RAFFLING)])),
        map((response) => {
          const currentGameSettings = this.#store.selectSnapshot(GamesState.currentGameSettings);
          const prizesDuration = getPrizesDuration(currentGameSettings);
          const rafflingDuration = getRafflingDuration(currentGameSettings);
          const rafflingDue = rafflingDuration + (response.rafflingTimestampDiff ?? 0);

          return {
            prizesDuration,
            rafflingDue,
          };
        }),
        switchMap(({ prizesDuration, rafflingDue }) => timer(rafflingDue).pipe(map(() => prizesDuration))),
        tap(() => dispatch(new ChangePhase(EClassicPhase.PRIZES))),
        switchMap((prizesDuration) => timer(prizesDuration)),
        tap(() => dispatch(new ChangePhase(EClassicPhase.RESETTING))),
      )
      .subscribe();
  }
}
