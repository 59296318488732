import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-float-value',
  standalone: true,
  imports: [MatTooltip],
  templateUrl: './float-value.component.html',
  styleUrl: './float-value.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatValueComponent {
  readonly float = input<number | null>();
  readonly numberFormat = input<number>();
  readonly formatedFloat = computed(() => this.formatNumber(this.float(), this.numberFormat()));
  readonly canShowTooltip = computed(() => !!this.numberFormat());

  formatNumber(floatNumber?: number | null, numberFormat?: number): string {
    if (!floatNumber) {
      return '';
    }
    const floatToString = floatNumber.toString();
    if (numberFormat) {
      return floatToString.slice(0, floatToString.indexOf('.') + numberFormat + 1);
    }

    return floatToString;
  }
}
