import type { IMarketplaceKitItem } from '@dev-fast/types';
import { MAX_ACCEPTABLE_RECOMMENDED_PRICE } from '@dev-fast/types';

export const getDiscount = (price: number, recommendedPrice?: number): number => {
  if (recommendedPrice && price < MAX_ACCEPTABLE_RECOMMENDED_PRICE) {
    return Math.round((1 - recommendedPrice / price) * 100);
  }

  return 0;
};

export const getSpecialWearType = (kit: IMarketplaceKitItem): string => {
  return kit.baseItem.statTrak ? 'ST' : kit.baseItem.isSouvenir ? 'SV' : '';
};
