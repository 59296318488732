import { inject, Injectable } from '@angular/core';
import { P2pMarketService } from '@core/p2p-services/p2p-market.service';
import { P2PModalService } from '@core/p2p-services/p2p-modal.service';
import { CasesBackendService, P2pApiNewService } from '@dev-fast/backend-services';
import { UserMarketApiService } from '@dev-fast/backend-services/user-market.api.service';
import type {
  BreakpointsTypes,
  EWearType,
  ICaseItemDtoV2,
  IFilterFormContent,
  IMarketplaceKitData,
  IMarketplaceKitItem,
  IP2pPurchaseItem,
  ISihDataset,
  ISteamStoreInventory,
  IUserStoreProfile,
  ModalNames,
} from '@dev-fast/types';
import { MarketSortingTypes, UserMarketType } from '@dev-fast/types';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { DateTime } from 'luxon';
import type { Observable } from 'rxjs';
import { catchError, map, of } from 'rxjs';

import { LayoutState } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';
import { toShortRevisionWear } from '@app/shared/utils';

import type { SimilarDictionary } from './similar/const';

@Injectable()
export class P2pSkinModalService {
  readonly #casesApiService = inject(CasesBackendService);
  readonly #p2pApiService = inject(P2pApiNewService);
  readonly #p2pModalService = inject(P2PModalService);
  readonly #p2pMarketService = inject(P2pMarketService);
  readonly #userMarketApiService = inject(UserMarketApiService);
  readonly #store = inject(Store);

  readonly sihDataset$: Observable<ISihDataset> = this.#p2pMarketService.sihDataset$;
  readonly purchasing$: Observable<IP2pPurchaseItem[]> = this.#p2pMarketService.purchasing$;
  readonly isAuth$: Observable<boolean> = this.#p2pMarketService.isAuth$;
  readonly breakpoints$: Observable<BreakpointsTypes | null> = this.#store.select(LayoutState.breakpoints);
  readonly isMobileByBreakpoint$: Observable<boolean> = this.breakpoints$.pipe(
    map((breakpoint) => {
      if (breakpoint && breakpoint.native) {
        return ['xxs', 'xs', 's'].includes(breakpoint.native);
      }
      return false;
    }),
  );

  getSellerName(data: IUserStoreProfile | null): string {
    const { user, userMarket } = data ?? {};
    const { id, type } = userMarket ?? {};
    return type === UserMarketType.OPEN ? user?.data?.name ?? '' : id ? `Store №${id}` : '';
  }

  getSellerRating(data: IUserStoreProfile | null): number {
    return (data?.user?.rating?.rating ?? 0) / 100;
  }

  getSellerOnlineStatus(data: IUserStoreProfile | null): boolean {
    const lastLogin = data?.user?.data?.lastlogin;
    if (!lastLogin) {
      return false;
    }

    const parsedTime = DateTime.fromISO(lastLogin, { zone: 'utc' });

    // Получаем текущее локальное время
    const localTime = DateTime.local();

    // Вычисляем разницу во времени в секундах
    const differenceInSeconds = Math.abs(localTime.diff(parsedTime, 'seconds').seconds);

    // Проверяем, меньше ли разница одной минуты (60 секунд)
    return differenceInSeconds < 60;
  }

  getStoreByKitId = (kitId: number): Observable<IUserStoreProfile> => this.#userMarketApiService.reqUserStoreByKitId(kitId);

  getSimilarItems = (item: IMarketplaceKitItem | ISteamStoreInventory): Observable<SimilarDictionary> => {
    const params = this.#getKitRequestParams(item);

    return this.#p2pApiService.getMarketItems(params).pipe(
      map((response) => this.#filterSimilarItems(response.kits, item)),
      map((kits) => this.#similarItemsFactory(kits)),
      catchError(() => of({})),
    );
  };

  getAvailabilityInCases(item: IMarketplaceKitItem | ISteamStoreInventory | null): Observable<ICaseItemDtoV2[]> {
    const { name } = item?.baseItem || { name: '' };

    if (!name) {
      return of([]);
    }
    return this.#casesApiService.getAvailabilityItemInCases({ name }).pipe(catchError(() => of([])));
  }

  getItemSihStats(name: string): void {
    this.#p2pMarketService.getItemSihStats(name);
  }

  toggleSelected(value: IMarketplaceKitData): void {
    this.#p2pMarketService.toggleSelected(value);
  }

  openAuthModal(): void {
    this.#p2pModalService.openAuthModal();
  }

  @Dispatch() openModal = (modalName: ModalNames, payload?: any): OpenModal => new OpenModal(modalName, payload);

  #getKitRequestParams(item: IMarketplaceKitItem | ISteamStoreInventory): IFilterFormContent<MarketSortingTypes> {
    const query = item.baseItem.name.replace(/ *\([^)]*\) */g, '').replace('StatTrak™ ', '');
    const params = {
      minPrice: null,
      maxPrice: null,
      minOverprice: null,
      maxOverprice: null,
      sortBy: MarketSortingTypes.MIN_PRICE,
      query,
    };
    return params;
  }

  #similarItemsFactory(items: IMarketplaceKitData[]): SimilarDictionary {
    return items.reduce<SimilarDictionary>((acc, kit) => {
      const { exterior } = kit.items[0].baseItem;
      if (exterior) {
        const wearType = toShortRevisionWear(exterior) as EWearType;
        acc[wearType] = acc[wearType] || [];
        acc[wearType].push(kit);
      }
      return acc;
    }, {});
  }

  #filterSimilarItems(items: IMarketplaceKitData[], selectedItem: IMarketplaceKitItem): IMarketplaceKitData[] {
    return items.filter((kit) => {
      const onlyOneKit = kit.items.length === 1;
      const kitEqualItem = kit.items[0].id === selectedItem.id;
      return onlyOneKit && !kitEqualItem;
    });
  }
}
