<div class="tw-flex tw-flex-col tw-gap-2">
  <div class="tw-flex tw-gap-3">
    <app-currency [value]="price()" />
    @if (overprice(); as overprice) {
      <app-overprice-tag
        class="tw-flex tw-items-center"
        [overprice]="overprice"
      />
    }
  </div>
  @if (dollarPrice(); as dollarPrice) {
    <div class="tw-text-gray-400 tw-bg-gray-700 tw-text-xs/[150%] tw-px-2 tw-py-[0.125rem] tw-rounded tw-w-fit">
      {{ '$' }}
      {{ dollarPrice | number: '1.0-2' }}
      {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.MONEY_REFILL' | translate }}
    </div>
  }
</div>
