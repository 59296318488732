<div
  class="tw-relative tw-flex tw-flex-col tw-items-center tw-w-full tw-h-full tw-pb-10 tw-px-3 mobile:tw-px-8 tw-rounded-2xl tw-bg-gray-650"
>
  <app-victory-block
    [items]="items()"
    (ondestroy)="closeModal()"
  >
  </app-victory-block>
  <div class="tw-w-full tw-bg-gray-550 tw-my-6 tw-pt-[1px]"></div>
  <button
    (click)="takePrize()"
    class="tw-w-full mobile:tw-w-fit btn btn-primary--design btn-semi-round btn-md !tw-py-3"
  >
    {{ 'GAMES.CLASSIC_GAME.TAKE_PRIZE' | translate }}
  </button>
  <button
    class="!tw-absolute tw-top-4 tw-right-4 btn btn-sm sq btn-round btn-darken--design"
    (click)="closeModal()"
  >
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>
