import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, model, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import type { IMarketplaceKitItem, ISihDataset, ISihStatPeriod, ISteamStoreInventory } from '@dev-fast/types';
import { SIH_STAT_PERIODS, SihDataType } from '@dev-fast/types';
import { GhostComponentModule, TabsComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import type { ChartConfiguration, ChartOptions } from 'chart.js';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';
import { combineLatest, filter } from 'rxjs';
import { tap } from 'rxjs/operators';

import { P2pItemChartComponent } from '@app/ui/components/lib/sih-stats/p2p-item-chart/p2p-item-chart.component';
import { TabComponent } from '@app/ui/components/lib/tabs/components/tab/tab.component';

import { CHART_TYPE, getLineChartOptions, LINE_CHART_DATA, PRICE_CHART_DATASET, SALES_CHART_DATASET } from './sih-chart.config';

@Component({
  selector: 'app-sih-chart',
  standalone: true,
  imports: [DecimalPipe, GhostComponentModule, P2pItemChartComponent, TranslateModule, NgChartsModule, TabComponent, TabsComponent],
  templateUrl: './sih-chart.component.html',
  styleUrl: './sih-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SihChartComponent {
  // inputs
  readonly item = input<IMarketplaceKitItem | ISteamStoreInventory>();
  readonly loading = input<boolean>(true);
  readonly sihDataset = input.required<ISihDataset>();
  //
  readonly chartType: 'line' = CHART_TYPE;
  readonly lineChartOptions = signal<ChartOptions<'line'>>(getLineChartOptions());
  readonly periods = signal<ISihStatPeriod[]>(SIH_STAT_PERIODS);
  readonly selectedPeriod = model<string>(this.periods()[0].value.toString());
  readonly price = signal<number>(0);
  readonly sales = signal<number>(0);
  readonly lineChartData = signal<ChartConfiguration<'line'>['data']>(LINE_CHART_DATA);
  // observables
  readonly selectedPeriod$ = toObservable<string>(this.selectedPeriod);
  readonly sihDataset$ = toObservable<ISihDataset>(this.sihDataset);

  readonly priceInUSD = computed(() => {
    const price = this.price();
    return price / 100;
  });

  readonly sihChartIsEmpty = computed(() => {
    const sihDataset = this.sihDataset();
    return (
      !sihDataset || !sihDataset[SihDataType.STEAMLIVESELL].length || sihDataset[SihDataType.STEAMLIVESELL].every((item) => item[0] === 0)
    );
  });

  readonly canShowPlaceholder = computed(() => {
    const isLoading = this.loading();
    const chartIsEmpty = this.sihChartIsEmpty();
    return !isLoading && chartIsEmpty;
  });

  constructor() {
    combineLatest([this.selectedPeriod$, this.sihDataset$])
      .pipe(
        filter(([selectedPeriod, dataset]) => !!selectedPeriod && !!dataset),
        tap(([selectedPeriod]) => {
          this.#refreshChart(+selectedPeriod);
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  #refreshChart(selectedPeriod: number): void {
    const sihDataset = this.sihDataset();
    if (!sihDataset) {
      return;
    }
    this.lineChartData.set(this.#buildNewChart(sihDataset[SihDataType.STEAMLIVESELL], selectedPeriod));
    const { averagePrice } = this.#getMedianPricePerPeriod(sihDataset, selectedPeriod);
    const sales = this.#getSalesPerPeriod(sihDataset, selectedPeriod);
    this.price.set(averagePrice);
    this.sales.set(sales);
  }

  /**
   * Обновдение графика с текущими данными chartDataset, period = 7|14|30 дней
   */
  #buildNewChart(chartDataset: Array<[number, number[], number[]]>, period = 7): ChartConfiguration<'line'>['data'] {
    const labels: number[] = [];
    const sellsData: number[] = [];
    const pricesData: number[] = [];
    const startIndex = Math.max(chartDataset.length - period, 0); // проверяем не вывалились ли за границы
    const periodData = chartDataset.slice(startIndex); // Берем последние данные согласно периоду
    periodData.forEach((day) => {
      labels.push(day[0]); // Делим данные на сегменты (7 сегментов для 7 дней)
      sellsData.push(day[1][8]); // Наполняем сегменты данными о продажах
      pricesData.push(day[1][2]); // наполняем сегменты данными о ценах
    });

    return {
      labels: this.#formatLabels(labels),
      datasets: [
        {
          ...SALES_CHART_DATASET,
          data: sellsData,
        },
        {
          ...PRICE_CHART_DATASET,
          data: pricesData,
        },
      ],
    };
  }

  /**
   * Переводим заголовки из формата '20230909' в '09.09'
   */
  #formatLabels(dates: number[]): string[] {
    return dates.map((date: number) => {
      const dateString = String(date);
      const format = 'yyyyMMdd';
      const newDate = DateTime.fromFormat(dateString, format);
      const newDateIsValid = newDate.isValid;
      const formattedDate = newDate.toFormat('dd.MM');
      return newDateIsValid ? formattedDate : '';
    });
  }

  /**
   * Устанавливаем среднюю цену за переданный период
   */
  #getSalesPerPeriod(sihData: ISihDataset, period: number): number {
    const salesData = sihData[SihDataType.STEAMLIVESELL];
    if (!salesData) {
      return 0;
    }
    const sum = [...salesData].slice(-period).reduce((sales, data) => sales + data[1][8], 0);
    return Math.ceil(sum);
  }

  /**
   * Устанавливаем количество продаж за переданный период
   */
  #getMedianPricePerPeriod(sihData: ISihDataset, period: number): { averagePrice: number; count: number } {
    const salesData = sihData[SihDataType.STEAMLIVESELL]?.slice(-period) || [];
    const { sum, count } = salesData.reduce(
      (acc, data) => {
        const price = data[1][2]; // Предполагаем, что цена находится в data[1][2]
        if (price !== 0) {
          acc.sum += price;
          acc.count++;
        }
        return acc;
      },
      { sum: 0, count: 0 },
    );

    const averagePrice = count ? sum / count : 0;

    return { averagePrice, count };
  }
}
