import { AsyncPipe, DatePipe } from '@angular/common';
import type { HttpErrorResponse } from '@angular/common/http';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { UserApiService } from '@dev-fast/backend-services';
import type { IPublicGameStatistic, IPublicUserDetailed } from '@dev-fast/types';
import { SocialType } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import type { Observable } from 'rxjs';
import { catchError, map, of, tap } from 'rxjs';

import { GamesState } from '@app/core/state/games-store';
import { UserAvatarComponent } from '@app/ui/components/index';

import { HIDDEN_ITEM_MOCK, HIDDEN_ITEMS_ARR, userCountryLink } from '../assets';
import { BestCardComponent } from '../best-card/best-card.component';

@Component({
  selector: 'app-user-profile-content',
  templateUrl: './profile-content.component.html',
  styleUrls: ['./profile-content.component.scss'],
  standalone: true,
  imports: [AsyncPipe, DatePipe, NgxSkeletonLoaderModule, TranslateModule, MatIconModule, UserAvatarComponent, BestCardComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileContentComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #store = inject(Store);
  readonly #userApiService = inject(UserApiService);

  readonly userId = input<number>();
  readonly close = output<void>();

  readonly hiddenItemsArr: unknown[] = HIDDEN_ITEMS_ARR;
  readonly hiddenItem: IPublicGameStatistic = HIDDEN_ITEM_MOCK;

  readonly socialTypes = SocialType;

  readonly user = signal<IPublicUserDetailed | null>(null);

  ngOnInit(): void {
    this.#user$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe();
  }

  get #user$(): Observable<IPublicUserDetailed | null> {
    const userId = this.userId();
    return this.#userApiService.getUserProfile<IPublicUserDetailed>(userId as number, { needDetailedInfo: true }).pipe(
      tap((user) => {
        this.user.set(user);
      }),
      catchError((error: HttpErrorResponse) => of(null)),
    );
  }

  getGameTitle(key: string): Observable<string | undefined> {
    return this.#store.select(GamesState.gameTitle).pipe(map((filterFn) => filterFn(key)));
  }

  getUserCountry(user: IPublicUserDetailed): string {
    return userCountryLink(user.country);
  }
}
