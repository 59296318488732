import { AsyncPipe, DatePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import type { HttpErrorResponse } from '@angular/common/http';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ExchangeProductCardService } from '@core/p2p-services/exchange-product-card.service';
import { P2pApiNewService, UserApiService } from '@dev-fast/backend-services';
import { UserMarketApiService } from '@dev-fast/backend-services/user-market.api.service';
import type {
  EBackgroundClass,
  EDiscountClass,
  EPhaseClass,
  EWearType,
  IP2pDepositingItem,
  IPublicUserDetailed,
  IUserMarketStats,
  IUserStoreProfile,
} from '@dev-fast/types';
import { ESectionType, MarketSortingTypes, SocialType } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import type { Observable } from 'rxjs';
import { catchError, map, merge, of, startWith, takeWhile, tap, timer } from 'rxjs';

import { LanguageState } from '@app/core/language-service';
import { GamesState } from '@app/core/state/games-store';
import { CaseCardNewComponent } from '@app/shared/components/case-card-new/case-card-new.component';
import { AppPercentPipe, HashPipe } from '@app/shared/pipes';
import { getSkinColorsPalette, SKIN_RARITY_V2 } from '@app/shared/utils';
import { expandYAnimation, replacementAnimation } from '@app/ui/animations';
import { SkinItemHorizontalComponent, SpinnerLoaderSAComponent, TagComponent, UserAvatarComponent } from '@app/ui/components/index';
import type { IProductCardOptions } from '@app/ui/components/lib/product-card/product-card.component';
import { ProductCardComponent } from '@app/ui/components/lib/product-card/product-card.component';

import { userCountryLink } from '../assets';
import { BestCardComponent } from '../best-card/best-card.component';
import { UserProfileMarketChipsComponent } from './profile-chips/profile-chips.component';

@Component({
  selector: 'app-user-profile-market-content',
  templateUrl: './profile-market-content.component.html',
  styleUrls: ['./profile-market-content.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    UpperCasePipe,
    TitleCasePipe,
    HashPipe,
    AppPercentPipe,
    NgxSkeletonLoaderModule,
    RouterModule,
    TranslateModule,
    MatIconModule,
    UserAvatarComponent,
    BestCardComponent,
    TagComponent,
    UserProfileMarketChipsComponent,
    ProductCardComponent,
    CaseCardNewComponent,
    SkinItemHorizontalComponent,
    SpinnerLoaderSAComponent,
  ],
  providers: [ExchangeProductCardService],
  animations: [expandYAnimation, replacementAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileMarketContentComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #store = inject(Store);
  readonly #userApiService = inject(UserApiService);
  readonly #userMarketApiService = inject(UserMarketApiService);
  readonly #p2pApiService = inject(P2pApiNewService);
  readonly #exchangeProductCardService = inject(ExchangeProductCardService);
  readonly #lang = this.#store.selectSnapshot(LanguageState.lang).path.split('_')[0].toUpperCase();

  readonly userId = input<number>();
  readonly close = output<void>();

  readonly user = signal<IPublicUserDetailed | null>(null);
  readonly userStats = signal<IUserMarketStats | null>(null);
  readonly userStore = signal<IUserStoreProfile | null>(null);
  readonly userItemsOnSale = signal<IP2pDepositingItem[]>([]);
  readonly userItemsOnSaleAmount = signal<number | null>(null);

  readonly userOnlineStatus = computed(() => {
    const lastLogin = this.userStore()?.user?.data?.lastlogin;
    if (!lastLogin) {
      return;
    }
    const dateLogin = Date.parse(lastLogin);
    const dateNow = Date.now();
    const lastLoginInSec = (dateNow - dateLogin) / 1000;
    return lastLoginInSec > 900 ? 'offline' : 'online';
  });

  readonly caseItemOptions: IProductCardOptions = {
    hasSelectProduct: false,
    canShowInfoButton: false,
  };

  readonly socialTypes = SocialType;
  readonly ESectionType = ESectionType;

  ngOnInit(): void {
    merge(this.#user$, this.#userStats$, this.#userExchanges$, this.#userKits$, this.#userKitsAmount$)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  get #user$(): Observable<IPublicUserDetailed | null> {
    const userId = this.userId();
    return this.#userApiService.getUserProfile<IPublicUserDetailed>(userId as number, { needDetailedInfo: true }).pipe(
      tap((user) => {
        this.user.set(user);
      }),
      catchError((error: HttpErrorResponse) => of(null)),
    );
  }

  get #userStats$(): Observable<IUserMarketStats | null> {
    const userId = this.userId();
    return this.#userMarketApiService.getUserMarketStats<IUserMarketStats>(userId as number).pipe(
      tap((stats) => {
        this.userStats.set(stats);
      }),
      catchError((error: HttpErrorResponse) => of(null)),
    );
  }

  get #userExchanges$(): Observable<IUserStoreProfile | null> {
    const userId = this.userId();
    return this.#userMarketApiService.reqUserStoreById(userId as number).pipe(
      tap((store) => {
        this.userStore.set(store);
        this.userOnlineStatus();
      }),
      catchError((error: HttpErrorResponse) => of(null)),
    );
  }

  get #userKits$(): Observable<{ kits: IP2pDepositingItem[] } | null> {
    const userId = this.userId();
    const params = {
      'page[size]': 4,
      sortBy: MarketSortingTypes.MAX_PRICE,
      sellerId: userId as number,
    };
    return this.#p2pApiService.getUserItemsById(this.#lang, params).pipe(
      tap((response) => {
        this.userItemsOnSale.set(response.kits);
      }),
      catchError((error: HttpErrorResponse) => of(null)),
    );
  }

  get #userKitsAmount$(): Observable<{ count: number } | null> {
    const userId = this.userId();
    const params = { sellerId: userId as number };
    return this.#p2pApiService.getUserItemsCount(params).pipe(
      tap((response) => {
        this.userItemsOnSaleAmount.set(response.count);
      }),
      catchError((error: HttpErrorResponse) => of(null)),
    );
  }

  getHistoryItemDividerColor(baseItemColor: string): string {
    return getSkinColorsPalette(baseItemColor.toLowerCase(), SKIN_RARITY_V2).hover;
  }

  getGameTitle(key: string): Observable<string | undefined> {
    return this.#store.select(GamesState.gameTitle).pipe(map((filterFn) => filterFn(key)));
  }

  getUserCountry(user: IPublicUserDetailed): string {
    return userCountryLink(user.country);
  }

  getItemImageSrc(item: IP2pDepositingItem): string[] {
    return this.#exchangeProductCardService.getItemImageSrc(item);
  }

  getDiscount(item: IP2pDepositingItem): number {
    return this.#exchangeProductCardService.getDiscount(item);
  }

  getWearType(item: IP2pDepositingItem): EWearType[] {
    return this.#exchangeProductCardService.getWearType(item);
  }

  getItemWear(item: IP2pDepositingItem): number {
    return this.#exchangeProductCardService.getItemWear(item);
  }

  getItemPhase(item: IP2pDepositingItem): string | null | undefined {
    return this.#exchangeProductCardService.getItemPhase(item);
  }

  getItemType(item: IP2pDepositingItem): string {
    return this.#exchangeProductCardService.getItemType(item);
  }

  getItemName(item: IP2pDepositingItem): string {
    return this.#exchangeProductCardService.getItemName(item);
  }

  getItemBadges(item: IP2pDepositingItem): string[] {
    return this.#exchangeProductCardService.getItemBadges(item);
  }

  getItemBackgroundClass(item: IP2pDepositingItem): EBackgroundClass {
    return this.#exchangeProductCardService.getItemBackgroundClass(item);
  }

  getItemDiscountClass(item: IP2pDepositingItem): EDiscountClass {
    return this.#exchangeProductCardService.getItemDiscountClass(item);
  }

  getItemPhaseClass(item: IP2pDepositingItem): EPhaseClass | '' {
    return this.#exchangeProductCardService.getItemPhaseClass(item);
  }

  setTimerByEndDate(endDate: string | number | undefined | null): Observable<number> {
    if (!endDate) {
      return of(0);
    }

    const timerEndDate = typeof endDate === 'string' ? new Date(endDate).getTime() : endDate;

    if (isNaN(timerEndDate)) {
      return of(0);
    }
    return timer(0, 1000).pipe(
      startWith(timerEndDate - Date.now()),
      map(() => Math.max(0, timerEndDate - Date.now())),
      takeWhile((time) => time > 1500),
    );
  }
}
