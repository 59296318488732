import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';

import { OverpriceTagComponent } from '../../shared/overprice-tag/overprice-tag.component';

@Component({
  selector: 'app-price',
  standalone: true,
  imports: [CurrencyComponent, DecimalPipe, OverpriceTagComponent, TranslateModule],
  templateUrl: './price.component.html',
  styleUrl: './price.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceComponent {
  readonly price = input<number>();
  readonly dollarPrice = input<number>(0);
  readonly overprice = input<number>(0);
}
