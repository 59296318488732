export class ResizedEvent {
  newRect: DOMRectReadOnly;
  oldRect?: DOMRectReadOnly;
  isFirst: boolean;

  constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
    this.newRect = newRect;
    this.oldRect = oldRect;
    this.isFirst = oldRect == null;
  }
}
