import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import {
  SubscribeClassicHistorySockets,
  SubscribeClassicSockets,
  UnsubscribeClassicHistorySockets,
  UnsubscribeClassicSockets,
} from '../state/substates/classic-sockets';

@Injectable()
export class ClassicSocketsService {
  @Dispatch() subscribeToAll = (): SubscribeClassicSockets => new SubscribeClassicSockets();
  @Dispatch() subscribeToHistory = (): SubscribeClassicHistorySockets => new SubscribeClassicHistorySockets();
  @Dispatch() unsubscribeToAll = (): UnsubscribeClassicSockets => new UnsubscribeClassicSockets();
  @Dispatch() unsubscribeToHistory = (): UnsubscribeClassicHistorySockets => new UnsubscribeClassicHistorySockets();
}
