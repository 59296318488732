import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PROJECT } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { EnvironmentService } from '@app/core/environment-service';

import { UserProfileContentComponent } from './profile-content/profile-content.component';
import { UserProfileMarketContentComponent } from './profile-market-content/profile-market-content.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  standalone: true,
  imports: [MatIconModule, UserProfileContentComponent, UserProfileMarketContentComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
  readonly #environmentService = inject(EnvironmentService);
  readonly #dialogRef = inject(MatDialogRef<UserProfileComponent>);
  readonly #data = inject<{ userId: number }>(MAT_DIALOG_DATA);

  readonly userId = this.#data.userId;

  readonly project = this.#environmentService.getEnvironment().PROJECT;

  readonly ProjectEnum = PROJECT;

  close(): void {
    this.#dialogRef.close();
  }
}
