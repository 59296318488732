@if (float(); as float) {
  <span
    [matTooltipClass]="['gray-500', 'float-tooltip']"
    [matTooltipDisabled]="!canShowTooltip()"
    matTooltip="{{ float }}"
    matTooltipPosition="above"
  >
    {{ formatedFloat() }}
  </span>
}
