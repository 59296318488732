import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { BAD_RATING_FINISH, GOOD_RATING_START } from './const';

@Component({
  selector: 'app-seller-rating',
  standalone: true,
  imports: [NgClass],
  templateUrl: './seller-rating.component.html',
  styleUrl: './seller-rating.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SellerRatingComponent {
  readonly percentage = input<number>(0);
  readonly isOnline = input<boolean>(false);
  readonly goodRating = computed(() => this.percentage() > GOOD_RATING_START);
  readonly badRating = computed(() => this.percentage() < BAD_RATING_FINISH);
}
