/* eslint-disable no-console */
import type { NgModuleRef } from '@angular/core';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  // Sentry.init({
  //   dsn: environment.SENTRY_DSN,
  //   integrations: [
  //     // Registers and configures the Tracing integration,
  //     // which automatically instruments your application to monitor its
  //     // performance, including custom Angular routing instrumentation
  //     Sentry.browserTracingIntegration(),
  //     // Registers the Replay integration,
  //     // which automatically captures Session Replays
  //     Sentry.replayIntegration(),
  //   ],
  //   ignoreErrors: ['ResizeObserver loop limit exceeded'],
  //   environment: environment.production ? 'prod' : 'dev',
  //   release: `${environment.CLIENT_PROJECT}${environment.VERSION}`,
  //
  //   tracesSampleRate: 1,
  // });
  enableProdMode();
}

const bootstrap = (): Promise<NgModuleRef<any>> => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr && module['hot']) {
  hmrBootstrap(module, bootstrap);
} else {
  bootstrap().catch((err) => console.log(err));
}
