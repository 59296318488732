@if (
  {
    user: user(),
    stats: userStats(),
    store: userStore(),
    items: userItemsOnSale()
  };
  as userData
) {
  @if (userData.user; as user) {
    <div class="profile__wrapper profile__wrapper_header">
      <div
        class="profile__container"
        @expandYAnimation
      >
        <div class="user__avatar-wrapper">
          <app-ui-user-avatar
            [noChevron]="true"
            [noBorder]="true"
            [url]="user.avatar"
          ></app-ui-user-avatar>
          @if (userOnlineStatus(); as onlineStatus) {
            <app-ui-tag
              [class]="onlineStatus"
              @replacementAnimation
              >{{ 'P2P_WIDGETS.RENEW_INFO_MODAL.RENEW_USER_PROFILE.' + (onlineStatus | uppercase) | translate }}</app-ui-tag
            >
          }
        </div>
        <div class="profile__info profile__info_column">
          <div class="user__info">
            <div class="user__country">
              @if (user.country) {
                <img
                  class="user__country-img"
                  [src]="getUserCountry(user)"
                  alt=""
                />
              }
            </div>
            <div class="user__nickname">{{ user.name }}</div>
          </div>
          <span class="user__info user__info_id">{{ 'PROFILE.ID' | translate }} {{ user.id }}</span>
          <span class="user__info user__info_date"
            >{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.REGISTRATION_DATE' | translate }}:
            {{ user.activity?.registered | date: 'dd.MM.yyyy' }}</span
          >
        </div>
      </div>
      @if (userData.store?.user?.rating; as userRating) {
        @if (
          {
            percent: userRating.rating,
            ordersAmount: userRating.success + userRating.fail,
            itemsOnSale: userItemsOnSaleAmount()
          };
          as ratingData
        ) {
          <div
            class="profile__container profile__container_stats"
            @expandYAnimation
          >
            @if (ratingData.percent; as percent) {
              <div class="profile__info">
                <app-user-profile-chips
                  class="rating"
                  [value]="percent | appPercent: { rank: 100 }"
                  [title]="'P2P_WIDGETS.RENEW_INFO_MODAL.RENEW_USER_PROFILE.RATING' | translate"
                >
                </app-user-profile-chips>
                <!-- @if (deliveryTime(); as deliveryTime) {
                  <app-user-profile-chips
                    class="delivery"
                    [value]="'2 years'"
                    [title]="'P2P_WIDGETS.RENEW_INFO_MODAL.RENEW_USER_PROFILE.DELIVERY_SPEED' | translate"
                  >
                  </app-user-profile-chips>
                } -->
              </div>
            }
            @if (ratingData.ordersAmount || ratingData.itemsOnSale) {
              <div
                class="profile__info"
                @expandYAnimation
              >
                @if (ratingData.ordersAmount; as ordersAmount) {
                  <app-user-profile-chips
                    [value]="ordersAmount"
                    [title]="'P2P_WIDGETS.RENEW_INFO_MODAL.RENEW_USER_PROFILE.DEALS' | translate"
                  >
                  </app-user-profile-chips>
                }
                @if (ratingData.itemsOnSale; as itemsOnSale) {
                  <app-user-profile-chips
                    [value]="itemsOnSale || 0"
                    [title]="'P2P_WIDGETS.RENEW_INFO_MODAL.RENEW_USER_PROFILE.ITEMSONSALE' | translate"
                  >
                  </app-user-profile-chips>
                }
              </div>
            }
          </div>
        }
      }
    </div>
    <div class="profile__wrapper profile__wrapper_activities">
      @if (userData.stats; as stats) {
        <div
          class="profile__wrapper"
          @expandYAnimation
        >
          <div class="profile__container profile__container_stats">
            @if (stats.openedCaseCount; as casesOpened) {
              <div class="profile__info">
                <app-user-profile-chips
                  class="activity"
                  [value]="casesOpened"
                  [title]="'PROFILE.OTHER.OPENED' | translate"
                >
                  <mat-icon
                    class="icon"
                    svgIcon="logo-v2-case"
                  ></mat-icon>
                </app-user-profile-chips>
              </div>
            }
            @if (stats.playedBattlesCount; as playedBattlesCount) {
              <div class="profile__info">
                <app-user-profile-chips
                  class="activity"
                  [value]="playedBattlesCount"
                  [title]="'PROFILE.OTHER.COOP_OPEN' | translate"
                >
                  <mat-icon
                    class="icon"
                    svgIcon="logo-v2-case-battle"
                  ></mat-icon>
                </app-user-profile-chips>
              </div>
            }
          </div>
          <div class="profile__container profile__container_stats">
            @if (stats.bestPopularCase?.[0]?.case; as favoriteCase) {
              <a
                class="decoration-none profile__info"
                [routerLink]="'/cases/case/' + favoriteCase.id"
                (click)="close.emit()"
              >
                <app-user-profile-chips
                  class="activity item"
                  [title]="'PROFILE.OTHER.FAVORITE_CASE' | translate"
                  [value]="favoriteCase.name"
                >
                  <div class="profile__chips-content">
                    <app-case-card-new
                      class="tw-aspect-[4/5]"
                      [price]="favoriteCase.lastRevision.price"
                      [image]="favoriteCase.backgroundImage || favoriteCase.itemImage"
                    ></app-case-card-new>
                  </div>
                </app-user-profile-chips>
              </a>
            }
            @if (stats.bestDrop?.caseRevisionItem; as bestDropItem) {
              <div class="decoration-none profile__info">
                <app-user-profile-chips
                  class="activity skin-item"
                  [title]="'PROFILE.OTHER.BEST_DROP' | translate"
                >
                  <app-ui-skin-item-horizontal
                    [itemImageSrc]="bestDropItem.inventoryItem.baseItem.icon | hash"
                    [itemPrice]="bestDropItem.inventoryItem.price"
                    [itemWeaponName]="
                      bestDropItem.inventoryItem.baseItem.shortName || (bestDropItem.inventoryItem.baseItem.weapon | titlecase)
                    "
                    [itemSkinName]="bestDropItem.inventoryItem.baseItem.skin"
                    [dividerBg]="getHistoryItemDividerColor(bestDropItem.inventoryItem.baseItem.color)"
                  >
                  </app-ui-skin-item-horizontal>
                </app-user-profile-chips>
              </div>
            }
          </div>
        </div>
      }
      @if (userData.store?.userMarket?.link && userData.items.length) {
        <div
          class="profile__wrapper"
          @expandYAnimation
        >
          <div class="profile__container profile__container_items">
            <div class="profile__items-title">
              {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.RENEW_USER_PROFILE.ITEMSONSALE' | translate }}
            </div>
            <a
              routerLink="/"
              class="profile__items-link"
              >{{ 'LOCAL.INVENTORY.SHOW_ALL' | translate }}
            </a>
          </div>
          <div class="profile__items-container">
            @for (depositingItem of userItemsOnSale(); track depositingItem.id) {
              <app-ui-product-card
                [images]="getItemImageSrc(depositingItem)"
                [price]="depositingItem.sellerPrice || 0"
                [discount]="getDiscount(depositingItem)"
                [wearType]="getWearType(depositingItem)"
                [wear]="getItemWear(depositingItem)"
                [phaseNumber]="getItemPhase(depositingItem)"
                [type]="getItemType(depositingItem)"
                [sectionType]="ESectionType.EXCHANGE"
                [name]="getItemName(depositingItem)"
                [badges]="getItemBadges(depositingItem)"
                [class]="[getItemBackgroundClass(depositingItem), getItemDiscountClass(depositingItem), getItemPhaseClass(depositingItem)]"
                [options]="{ hasSelectProduct: false, canShowInfoButton: false }"
              >
              </app-ui-product-card>
            }
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="profile__wrapper profile__wrapper_header">
      <app-ui-spinner-loader></app-ui-spinner-loader>
    </div>
  }
}
