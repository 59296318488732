import { JsonPipe, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, model, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import type { BaseType, IMarketplaceKitData, IMarketplaceKitItem } from '@dev-fast/types';
import { EWearType } from '@dev-fast/types';
import { GhostComponentModule, TabsComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { TabComponent } from '@app/ui/components/lib/tabs/components/tab/tab.component';

import { getDiscount, getSpecialWearType } from '../utils';
import type { SimilarDictionary } from './const';
import { MIN_SIMILAR_ITEMS_TO_SHOW, WEAR_TYPES } from './const';
import { SimilarCardComponent } from './similar-card/similar-card.component';

@Component({
  selector: 'app-similar',
  standalone: true,
  imports: [SimilarCardComponent, TranslateModule, TabsComponent, TabComponent, MatIcon, SlicePipe, GhostComponentModule, JsonPipe],
  templateUrl: './similar.component.html',
  styleUrl: './similar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimilarComponent {
  // inputs
  readonly items = input<SimilarDictionary | null>();
  readonly selectedItemIds = input<number[]>();
  readonly loading = input<boolean | null>(true);
  // outputs
  readonly addToCart = output<IMarketplaceKitData>();
  readonly removeFromCart = output<IMarketplaceKitData>();
  readonly showAllItems = output<void>();
  readonly navigateToCart = output();

  readonly selectedWearType = model<EWearType>(EWearType.FN);
  readonly wearTypes = computed<Array<BaseType<EWearType, EWearType>>>(() => {
    const items = this.items();
    return WEAR_TYPES.filter((type) => items?.[type.value]);
  });

  readonly canShowSelf = computed<boolean>(() => {
    const wearTypes = this.wearTypes();
    const isLoading = this.loading();
    return isLoading || wearTypes.length > 1;
  });

  readonly selectedItems = computed(() => this.items()?.[this.selectedWearType()] ?? []);
  readonly canShowAllSkins = computed<boolean>(() => {
    const items = this.selectedItems();
    if (!items) {
      return false;
    }
    return items.length > MIN_SIMILAR_ITEMS_TO_SHOW;
  });

  readonly maxItemsToShow = MIN_SIMILAR_ITEMS_TO_SHOW;

  constructor() {
    effect(
      () => {
        if (this.wearTypes().length) {
          this.selectedWearType.update(() => this.wearTypes()[0].value);
        }
      },
      { allowSignalWrites: true },
    );
  }

  onNavigateToCart(): void {
    this.navigateToCart.emit();
  }

  getKitItem(item: IMarketplaceKitData): IMarketplaceKitItem {
    return item.items[0];
  }

  getSpecialWearType(kit: IMarketplaceKitItem): string {
    return getSpecialWearType(kit);
  }

  getDiscount(item: IMarketplaceKitItem): number {
    return getDiscount(item.price, item.recommendedPrice);
  }

  isSelected(id: number): boolean {
    return this.selectedItemIds()?.includes(id) ?? false;
  }

  onNavigateToSkin(): void {
    this.showAllItems.emit();
  }

  onAddToCart(item: IMarketplaceKitData): void {
    this.addToCart.emit(item);
  }

  onRemoveFromCart(item: IMarketplaceKitData): void {
    this.removeFromCart.emit(item);
  }
}
