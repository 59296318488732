import { Injectable } from '@angular/core';
import type { IP2pDepositingItem } from '@dev-fast/types';
import { Select, Selector } from '@ngxs/store';
import type { Observable } from 'rxjs';

import { TradeCompleteService } from '@app/widgets/p2p-new/modals/p2p-auction-completed-modal/p2p-trade-complete.service';

@Injectable()
export class DebugTradeCompleteService extends TradeCompleteService {
  @Selector()
  static depositingItems(): IP2pDepositingItem[] | any {
    /** Сколько минут доступна сделка (таймер) */
    const tradeDurationMinutes = 20;

    return [
      {
        id: 20100219,
        price: 157,
        sellerPrice: 145,
        overprice: 8,
        express: false,
        items: [
          {
            id: 9755854,
            baseItem: {
              name: 'CZ75-Auto | Tuxedo (Factory New)',
              icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZfwPz3fi9B7eO6nYeDg7mjNuPXwj0I7JwgjLrDpdv3iw3kr0RsZz_0I9fBcQBvZlrV_wLtkr3pm9bi6xlS4tez',
              iconLarge:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZfwPz3fi9B7eO6nYeDg8j4MqnWkyUJvJwh0r-U8I702wK3-0o_MD30IdecIAdoaFvU_lTol-y6jMC-7pWf1zI97f84eslR',
              color: '#4b69ff',
              weapon: 'Pistol',
              type: 'weapon',
              rarity: 'Mil-Spec Grade',
              shortName: 'CZ75-Auto',
              skin: 'Tuxedo',
              exterior: 'Factory New',
              statTrak: false,
              isSouvenir: false,
              steamPrice: 182,
              phase: null,
            },
            price: 157,
            sellerPrice: 145,
            recommendedPrice: 145,
            phase: null,
            float: 0.00339320907369256,
            stickers: [],
            steamInspectLink:
              'steam://rungame/730/76561202255233023/+csgo_econ_action_preview%20S76561198038522646A3003595953D4621327148653723539',
            steamItemPosition: [5, 3, 4],
          },
        ],
        tradeLockEndAt: null,
        recommendedPrice: 145,
        isCrossFast: false,
        itemsCount: 1,
        status: 'wait-for-trade-confirm',
        statusAt: new Date().toISOString(), // Дата начала сделки
        fastDelivery: true,
        nextStatusAt: new Date(new Date().getTime() + tradeDurationMinutes * 60000).toISOString(), // Дата окончания сделки
        nextBidPrice: 157,
        nextSellerPrice: 145,
        isUserSeller: true,
        isLastBidByUser: false,
        order: {
          id: 726,
          steamOfferId: null,
          buyerSteamId: '76561198028500394',
          buyerId: 759,
          buyerTradeLink: 'http://steamcommunity.com/tradeoffer/new/?partner=68234666&token=SLP3sk0c',
          buyerMarket: 'local',
          buyerSteamName: 'Семён csgofastx.com',
          buyerSteamAvatar: 'de96e9ef024dc2dba05b9e03b4a7ad1c7a4cdcba',
          buyerSteamLevel: 21,
          buyerSteamCreatedAt: '2010-08-02T23:26:06.000Z',
          senderAutomatedTrading: true,
        },
      },
    ];
  }

  @Select(DebugTradeCompleteService.depositingItems)
  override readonly depositing$!: Observable<IP2pDepositingItem[]>;
}
