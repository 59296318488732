@if (data$ | async; as data) {
  <div
    #scroll
    class="layout-wrapper"
    (resized)="onResize()"
  >
    <div
      class="header tw-fixed tw-top-0 tw-w-full tw-z-20"
      [class.tw-sticky]="!isStore()"
    >
      <app-new-market-header />
    </div>
    @if (isStore()) {
      <!--  Это банер для маркета, расположен он тут а не в модуле маркета из-за особенностей макета.   -->
      <app-first-banner class="tw-max-w-1800" />
    }

    <div class="content-wrapper">
      <div class="left-sidebar">
        <app-left-sidebar [items]="navigations" />
      </div>
      <div class="content-wrapper__outlet tw-max-w-1800">
        <router-outlet />
      </div>
    </div>
    @if (data.seoData && (data.seoData.h1 || data.seoData.main || data.seoData.faq)) {
      <div class="seo">
        @if (data.seoData.h1; as h1) {
          <h1 [innerHTML]="h1"></h1>
        }
        @if (data.seoData.main; as main) {
          <div [innerHTML]="main"></div>
        }
        @if (data.seoData.faq; as faq) {
          <div [innerHTML]="faq"></div>
        }
      </div>
    }
    <footer>
      <app-ui-new-footer
        [online]="data.online"
        [counts]="data.counts"
        [marketCounts]="data.marketCounts?.marketCounts"
        (openChat)="openTechSupportDialog()"
      />
    </footer>
  </div>

  <app-bottom-control-panel>
    <app-left-sidebar
      left-controls
      class="desktop-hide"
      [items]="navigations"
    />
    <app-refill-banner bottom-banner />
    <app-right-btns
      right-controls
      [hiddenScrollBtn]="!data.isScrollTop"
      [disableTradeBtn]="!data.isAuth || data.panels?.includes(PanelEnum.TRADES)"
      [isMobileMenuOpened]="isMobileMenuOpened"
      (clickScrollTop)="scrollTop()"
      (clickTradeBtn)="openMarket()"
      (clickChatra)="openTechSupportDialog()"
    />
  </app-bottom-control-panel>
  @if (!isServer) {
    <app-notification />
  }
  <ng-template #modalTmpl>
    <router-outlet name="modal" />
  </ng-template>
}
