<div class="tw-flex tw-relative tw-bg-gray-550 tw-rounded">
  @if (isOnline()) {
    <span class="tw-absolute tw-h-3 tw-w-3 tw-right-[-0.2rem] tw-top-[-0.25rem] tw-rounded-full tw-bg-green-500"></span>
  }
  <span
    class="tw-text-xs tw-font-bold leading-normal tw-px-2 tw-py-[0.12rem] tw-text-gray-150"
    [class.tw-text-green-500]="goodRating()"
    [class.tw-text-red-500]="badRating()"
    >{{ percentage() }}%</span
  >
</div>
