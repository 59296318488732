import type { InMemoryScrollingOptions, Route, Routes } from '@angular/router';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { APP_PATHS, MAIN_LANG, TransactionStatus } from '@dev-fast/types';

import { IsAuthGuard } from '@app/auth';
import { AutoGameActivateChildGuard, AutoGameActivateGuard, AutoGameDeactivateGuard } from '@app/core/guards';
import { LanguageService } from '@app/core/language-service';
import { GofastLayoutNewMarketComponent } from '@app/gofast/layout-new-market';
import { NotFoundPageComponent } from '@app/not-found-page';

import { routes as appRoutes } from './app.routes';

const IN_MEMORY_SCROLLING_OPTIONS: InMemoryScrollingOptions = { scrollPositionRestoration: 'enabled' };
const NOT_FOUND_PAGE = {
  path: APP_PATHS.wildcard,
  component: NotFoundPageComponent,
};

export const routes: Routes = [
  {
    path: APP_PATHS.root,
    component: GofastLayoutNewMarketComponent,
    children: [
      {
        path: APP_PATHS.root,
        children: appRoutes,
        providers: [provideRouter(appRoutes, withInMemoryScrolling(IN_MEMORY_SCROLLING_OPTIONS))],
      },
      {
        path: 'transaction',
        outlet: 'modal',
        loadChildren: () => import('@app/modules/transaction/transaction.market.routes').then(({ routes: routesMarket }) => routesMarket),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, IsAuthGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      {
        path: 'fail-payment',
        outlet: 'modal',
        canActivate: [IsAuthGuard],
        loadChildren: () => import('@app/gofast/modals-notification-layout').then((modules) => modules.ModalsNotificationLayoutModule),
        data: { paymentStatus: TransactionStatus.ERRORED },
      },
      {
        path: 'success-payment',
        outlet: 'modal',
        canActivate: [IsAuthGuard],
        loadChildren: () => import('@app/gofast/modals-notification-layout').then((modules) => modules.ModalsNotificationLayoutModule),
        data: { paymentStatus: TransactionStatus.RECEIVED },
      },
      ...(Object.keys(LanguageService.getRouteLanguages())
        .filter((item) => item !== MAIN_LANG)
        .map((lang) => ({
          path: lang,
          children: [...appRoutes, NOT_FOUND_PAGE],
        })) as Route[]),
      NOT_FOUND_PAGE,
    ],
  },
];
