import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TagComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';

import { OverpriceTagComponent } from '../shared/overprice-tag/overprice-tag.component';
import { PriceComponent } from './price/price.component';

@Component({
  selector: 'app-price-action-block',
  standalone: true,
  imports: [TranslateModule, CurrencyComponent, MatIcon, TagComponent, OverpriceTagComponent, DecimalPipe, PriceComponent],
  templateUrl: './price-action-block.component.html',
  styleUrl: './price-action-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceActionBlockComponent {
  readonly price = input<number>();
  readonly dollarPrice = input<number>(0);
  readonly overprice = input<number>(0);
  readonly userBalance = input<number>();
  readonly canBuy = input<boolean>(true);
  readonly isInTrade = input<boolean>(false);
  readonly isAuthorized = input<boolean>(false);
  readonly buyNow = output();
  readonly addToCart = output();
  readonly removeFromCart = output();
  readonly navigateToCart = output();
  readonly refill = output();
  readonly login = output();

  readonly isEnoughBalance = computed(() => this.#isEnoughBalanceComputed());

  onNavigateToCart(): void {
    this.navigateToCart.emit();
  }

  onAddToCart(): void {
    this.addToCart.emit();
  }

  onRemoveFromCart(): void {
    this.removeFromCart.emit();
  }

  onBuyNow(): void {
    this.buyNow.emit();
  }

  onRefill(): void {
    this.refill.emit();
  }

  onLogin(): void {
    this.login.emit();
  }

  #isEnoughBalanceComputed(): boolean {
    const price = this.price();
    const balance = this.userBalance();

    if (balance === undefined) {
      return false;
    }

    if (price === undefined) {
      return true;
    }

    return balance >= price;
  }
}
