import type { ICaseItemDtoV2, INotifyData, IP2pPurchaseItem, TradeOriginalItem } from '@dev-fast/types';
import { CrossServices, TransactionStatus } from '@dev-fast/types';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

export const bonusCases: BonusCases = bonusCasesConstructor();

export const p2pTradeAcceptItem: Observable<IP2pPurchaseItem | any> = of({
  id: 20100219,
  price: 157,
  sellerPrice: 145,
  overprice: 8,
  express: false,
  items: [
    {
      id: 9755854,
      baseItem: {
        name: 'CZ75-Auto | Tuxedo (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZfwPz3fi9B7eO6nYeDg7mjNuPXwj0I7JwgjLrDpdv3iw3kr0RsZz_0I9fBcQBvZlrV_wLtkr3pm9bi6xlS4tez',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZfwPz3fi9B7eO6nYeDg8j4MqnWkyUJvJwh0r-U8I702wK3-0o_MD30IdecIAdoaFvU_lTol-y6jMC-7pWf1zI97f84eslR',
        color: '#4b69ff',
        weapon: 'Pistol',
        type: 'weapon',
        rarity: 'Mil-Spec Grade',
        shortName: 'CZ75-Auto',
        skin: 'Tuxedo',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 182,
        phase: null,
      },
      price: 157,
      sellerPrice: 145,
      recommendedPrice: 145,
      phase: null,
      float: 0.00339320907369256,
      stickers: [],
      steamInspectLink:
        'steam://rungame/730/76561202255233023/+csgo_econ_action_preview%20S76561198038522646A3003595953D4621327148653723539',
      steamItemPosition: [5, 3, 4],
    },
  ],
  tradeLockEndAt: null,
  recommendedPrice: 145,
  isCrossFast: false,
  itemsCount: 1,
  status: 'wait-for-trade-confirm',
  statusAt: new Date().toISOString(), // Дата начала сделки
  fastDelivery: true,
  nextStatusAt: new Date(new Date().getTime() + 20 * 60000).toISOString(), // Дата окончания сделки
  nextBidPrice: 157,
  nextSellerPrice: 145,
  isUserSeller: true,
  isLastBidByUser: false,
});

export function transactionPayloadConstructor(status: TransactionStatus): INotifyData {
  switch (status) {
    case TransactionStatus.RECEIVED:
    case TransactionStatus.COMPLETED:
      return {
        paymentStatus: status,
        params: {
          amount: 10000,
        },
      };
    case TransactionStatus.ERRORED:
    case TransactionStatus.PAYOUT_ERRORED:
      return {
        paymentStatus: status,
        customMessage: 'ERROR.WITHDRAW.WITHDRAW_LOCKED_WIN_MORE',
        params: {
          amount: 10000,
        },
      };
    case TransactionStatus.PAYOUT_SUCCESSFUL:
      return {
        paymentStatus: status,
        params: {
          amount: 10000,
          id: 69,
        },
      };
  }
}

export function viningItemsConstructor(amount: number): TradeOriginalItem[] {
  const items = [];

  for (let i = 0; i < amount; i++) {
    items.push({
      isInventory: false,
      selectedGameId: CrossServices.REAL,
      inventoryItemId: 9111280,
      userInventoryItemId: 9111280,
      name: 'MAC-10 | Sakkaku (Field-Tested)',
      icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou7umeldf0Ob3fDxBvYyJh4GAnPb_JoTck29Y_cg_ibyVodut2wC2rhA5NW30I4KTcwBsMl6C_1joybi515DuuM_BmHA3vT5iuyiyR78uOQ',
      price: 138,
      color: '#8847ff',
    });
  }

  return items;
}

interface BonusCases {
  allbonusCases: Record<number, number>;
  casesDictionary: Record<number, ICaseItemDtoV2>;
}

function bonusCasesConstructor(): BonusCases {
  const allbonusCases: Record<number, number> = {
    3: 4,
    7: 1,
    41: 1,
    45: 8,
    49: 2,
  };

  const casesDictionary: Record<number, any> = {
    3: {
      id: 3,
      name: 'Test Case 2',
      enabled: true,
      publishedAt: '2024-07-25T21:58:27.386Z',
      image: 'https://din8r827idtuo.cloudfront.net/cases/517900d6dcc27ed7ae8007a880e4a766.png?t=1705580815530',
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/517900d6dcc27ed7ae8007a880e4a766.png?t=1705580816032',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/517900d6dcc27ed7ae8007a880e4a766.png?t=1705580816528',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/517900d6dcc27ed7ae8007a880e4a766.png?t=1705580817127',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 460,
        revision: 2,
        price: 300,
        prevPrice: null,
        results: false,
      },
      mainItem: null,
      mainItemName: null,
      minOpensCount: 1,
      category: {
        id: 9,
        name: '1',
        image: 'https://din8r827idtuo.cloudfront.net/cases/471385595e38b4b0825e001586f0723f.png?t=1673973783332',
        cases: [],
        disableAt: '2023-05-16 14:45:25+00',
        grid: '',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    7: {
      id: 7,
      name: 'FAST $1',
      enabled: true,
      publishedAt: '2023-05-26T14:25:10.618Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685111109685',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/e9016d3dd890cffeaf96e2102ac65881.png?t=1666521936786',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/74014e4bccf0aec11de6de41bbd2980c.png?t=1685111110616',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 464,
        revision: 18,
        price: 100,
        prevPrice: 100,
        results: false,
      },
      mainItem: {
        name: 'SG 553 | Cyrex (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopb3wflFf0Ob3YjoXuY-Jl5mZku_LP7LWnn8fsMZ12LuSp9ik0Qzl_BI5N2ulJoGTIFJvNQ3V-wO8yebphZbquZzByGwj5Hd6-umspQ',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopb3wflFf0Ob3YjoXuY-Jl5mZku_LP7LWnn9u5MRjjeyP8Iqnjlblrkc4a2GmLNPHdVU-NA6F-1Prw-3u1JG7v86YyHVquHEmsWGdwUJK3Iesvg',
        color: '#d32ce6',
        weapon: 'Rifle',
        type: 'weapon',
        rarity: 'Classified',
        shortName: 'SG 553',
        skin: 'Cyrex',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 611,
        phase: null,
      },
      mainItemName: 'SG 553 | Cyrex (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 6,
        name: 'Lucky Time',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    8: {
      id: 8,
      name: 'FAST $7',
      enabled: true,
      publishedAt: '2023-05-26T14:33:22.453Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685111601570',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/42580d229155eb866f328fd40abae0f6.png?t=1685111602451',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 305,
        revision: 9,
        price: 700,
        prevPrice: 700,
        results: false,
      },
      mainItem: {
        name: 'AWP | Neo-Noir (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17PLfYQJM6dO4m4mZqPv9NLPF2DpSsJVyiLmRot73jFbt-xJtMjr2cYKRIVBrNVuD-lO4k-a515butJzXiSw01PxfcRM',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17PLfYQJM6dO4m4mZqPv9NLPFqWdQ-sJ0xOqU8Yig31Ls_kZpN2jwd4KUJAY2YVrUrFO5kOfshJK8u5mbz3tr63Y8pSGKcgFBR7U',
        color: '#eb4b4b',
        weapon: 'Sniper Rifle',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'AWP',
        skin: 'Neo-Noir',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 3923,
        phase: null,
      },
      mainItemName: 'AWP | Neo-Noir (Factory New)',
      minOpensCount: 1,
      category: {
        id: 12,
        name: '4',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'halloween',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    9: {
      id: 9,
      name: 'FAST $2',
      enabled: true,
      publishedAt: '2023-06-02T06:52:00.305Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685688719621',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/2bcafda2cb00ba9ee196fcf506c97522.png?t=1685688720303',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 462,
        revision: 12,
        price: 200,
        prevPrice: 200,
        results: false,
      },
      mainItem: {
        name: 'Desert Eagle | Heirloom (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposr-kLAtl7PLZTjhM6863goWPqPPxMrzdk1Rd4cJ5nqeRrNqt0Qzk8hY5NTrzcNCWIFRvaF7QrALtxO_tgZa96s7Bn3VisyUi-z-DyJziBZ6m',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposr-kLAtl7PLZTjhM6863goWPqPPxMrzdk1Rd4cJ5ntbN9J7yjRrs-0c9YjjzJdeXe1RoaVjW_VLsxu3m05Xp7p_LyXBkuHEk7XzVnhy0n1gSOexptizi',
        color: '#8847ff',
        weapon: 'Pistol',
        type: 'weapon',
        rarity: 'Restricted',
        shortName: 'Desert Eagle',
        skin: 'Heirloom',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 622,
        phase: null,
      },
      mainItemName: 'Desert Eagle | Heirloom (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 13,
        name: '50/50',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: ['привет'],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    10: {
      id: 10,
      name: 'FAST $3',
      enabled: true,
      publishedAt: '2023-05-26T14:33:04.120Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685111583132',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/bedcf2b7d2b1e22f764f222a20fd444b.png?t=1685111584119',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 480,
        revision: 15,
        price: 300,
        prevPrice: 300,
        results: false,
      },
      mainItem: {
        name: 'M4A1-S | Nitro (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uOlm7-Ehfb6NL7ul2hS7ctlmdbN_Iv9nBrmqUQ-Y2H1doGTdg5qZw2GrFm3wLjp0JPovp3LmiRjsiYgsXyOy0G0n1gSOY9ZIQF5',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uOlm7-Ehfb6NL7ul2hS7ctlmdbN_Iv9nGu4qgE7Nnf1JoPDdw5tNQvV_FW_l-rrhcXv6p-fnHplvHMh5HrfzRC_gRsYb7Ntm7XAHueE-po5',
        color: '#8847ff',
        weapon: 'Rifle',
        type: 'weapon',
        rarity: 'Restricted',
        shortName: 'M4A1-S',
        skin: 'Nitro',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 772,
        phase: null,
      },
      mainItemName: 'M4A1-S | Nitro (Factory New)',
      minOpensCount: 1,
      category: {
        id: 10,
        name: '2',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    13: {
      id: 13,
      name: 'FAST $8',
      enabled: true,
      publishedAt: '2023-05-26T14:33:59.702Z',
      image: 'https://din8r827idtuo.cloudfront.net/cases/e952c86e1d24e3627bdbba12f6f66c4c.png?t=1688379412289',
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685111639068',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1688379412706',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 309,
        revision: 8,
        price: 800,
        prevPrice: 800,
        results: false,
      },
      mainItem: {
        name: 'AK-47 | Leet Museo (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot7HxfDhnwMzJemkV0920h5SZlvTgDLfYkWNFppQl37DF94-i2wyx-EZpZWmhJ9XGI1Q8NA3U_VjqlO3njJK5v5nNznN9-n51lDBSHyQ',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot7HxfDhnwMzJemkV0920h5SZlvTgDLfYkWNF18lwmO7Eu4-ljVe1-RI-YzqmcYTGdAY6aFmC8wO4xe3qjcC7vsyczXFqsnUl7S7D30vgp4yMCZE',
        color: '#eb4b4b',
        weapon: 'Rifle',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'AK-47',
        skin: 'Leet Museo',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 4036,
        phase: null,
      },
      mainItemName: 'AK-47 | Leet Museo (Factory New)',
      minOpensCount: 1,
      category: {
        id: 12,
        name: '4',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'halloween',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    14: {
      id: 14,
      name: 'FAST $9',
      enabled: true,
      publishedAt: '2023-05-26T14:41:38.779Z',
      image: 'https://din8r827idtuo.cloudfront.net/cases/2714a06262cf5af9402a0e48ca273ffe.png?t=1719484181528',
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1719484181828',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/2714a06262cf5af9402a0e48ca273ffe.png?t=1719484182351',
      backgroundImage: null,
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 394,
        revision: 7,
        price: 45,
        prevPrice: 205,
        results: false,
      },
      mainItem: {
        name: "M4A1-S | Chantico's Fire (Factory New)",
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uO1gb-Gw_alIITCmX5d_MR6j_v--YXygED6qUo4Y2jydoGUJg48YQqB-lS3wru618e06pSbzno1uSZ27S3cnBKz0gYMMLK9MDo_5A',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uO1gb-Gw_alIITCmX5d_MR6j_v--YXygECLpxIuNDztII-We1I4aAqE_Fi_lerthMXttc7OnXRmvyhw4Xndlx3kiUkfa-ZuguveFwseJK64Qw',
        color: '#EB4B4B',
        weapon: 'Rifle',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'M4A1-S',
        skin: "Chantico's Fire",
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 9018,
        phase: null,
      },
      mainItemName: "M4A1-S | Chantico's Fire (Factory New)",
      minOpensCount: 1,
      category: {
        id: 2,
        name: 'CS2 CASES',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: true,
        tags: ['test'],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: false,
      isFavorite: false,
    },
    15: {
      id: 15,
      name: 'FAST $10',
      enabled: true,
      publishedAt: '2023-05-26T14:44:38.520Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/e2720656906b072c3f0193a97bc5f591.png?t=1666350218967',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/da96814abc553ff31f7be102c75e407d.png?t=1666350219699',
      priority: null,
      type: 'paid',
      tags: [],
      minAccountType: 'silver',
      lastRevision: {
        id: 472,
        revision: 6,
        price: 1000,
        prevPrice: 1000,
        results: false,
      },
      mainItem: {
        name: 'M4A1-S | Player Two (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uO1gb-Gw_alIITShWxeupUl0tbN_Iv9nBqxrUA9MWmhINKUIFU9N1rT_AK3lbjnjcLo6ZuazXA1u3R34yzdzha_n1gSORVcLpnc',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uO1gb-Gw_alIITShWxeupUl0tbN_Iv9nGu4qgE7Nnf1IYWUcAQ7MgnS-FW6wOi705Lo75uYmHNh6SZ3tiqMmh210xkZb-Rvm7XAHs3vPuvv',
        color: '#eb4b4b',
        weapon: 'Rifle',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'M4A1-S',
        skin: 'Player Two',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 5085,
        phase: null,
      },
      mainItemName: 'M4A1-S | Player Two (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 5,
        name: 'Over 10%',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    17: {
      id: 17,
      name: 'Orange Case',
      enabled: true,
      publishedAt: '2023-05-26T14:39:34.663Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1666521881345',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/982606881ef8e0b745802de0f79ac817.png?t=1666521881871',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/982606881ef8e0b745802de0f79ac817.png?t=1666521882371',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 481,
        revision: 9,
        price: 60,
        prevPrice: 60,
        results: false,
      },
      mainItem: {
        name: 'P90 | Traction (Well-Worn)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopuP1FABz7OORIQJW_tm3kL-GkvP9JrafkjMHvsAm3LrEoomk0AS1-kNoY2mnIYCccgFqYAmC-Fm2wOe71sC5u4OJlyWu66pOPQ',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopuP1FABz7OORIQJW_tm3kL-GkvP9JrbummpD78A_27qXpt2mi1a3_ERqYGrxJNWdcAc2MljW_we_kubu15HvvJmYwXNmuz5iuyiRpQmd4Q',
        color: '#4b69ff',
        weapon: 'SMG',
        type: 'weapon',
        rarity: 'Mil-Spec Grade',
        shortName: 'P90',
        skin: 'Traction',
        exterior: 'Well-Worn',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 11,
        phase: null,
      },
      mainItemName: 'P90 | Traction (Well-Worn)',
      minOpensCount: 1,
      category: {
        id: 2,
        name: 'CS2 CASES',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: true,
        tags: ['test'],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
      ticketQuest: {
        ticketsAmount: 1,
        ticketColor: 'green-key',
      },
    },
    18: {
      id: 18,
      name: 'FAST $0.7',
      enabled: true,
      publishedAt: '2023-05-26T14:39:20.088Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1666521902502',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/82bc967f0125a420f69993bc6a266d84.png?t=1666521903043',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/82bc967f0125a420f69993bc6a266d84.png?t=1666521903507',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 181,
        revision: 6,
        price: 70,
        prevPrice: 70,
        results: false,
      },
      mainItem: {
        name: 'Glock-18 | Snack Attack (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv2PoNzz2gXt_kRsZ2qlJIKdcw5vNQuF_FG2kOe518C97c7LmnI3uSIj4GGdwUKs2_Yhmw',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
        color: '#d32ce6',
        weapon: 'Pistol',
        type: 'weapon',
        rarity: 'Classified',
        shortName: 'Glock-18',
        skin: 'Snack Attack',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 1350,
        phase: null,
      },
      mainItemName: 'Glock-18 | Snack Attack (Factory New)',
      minOpensCount: 1,
      category: {
        id: 2,
        name: 'CS2 CASES',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: true,
        tags: ['test'],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
      ticketQuest: {
        ticketsAmount: 1,
        ticketColor: 'orange-key',
      },
    },
    20: {
      id: 20,
      name: 'FAST $0.9',
      enabled: true,
      publishedAt: '2023-05-26T14:33:11.582Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685111590989',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/24d201095966a8dd2c48e062797fd7cd.png?t=1666521893808',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/817c8d535d92fb2c08e8820c3e1b983b.png?t=1685111591581',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 461,
        revision: 5,
        price: 90,
        prevPrice: 90,
        results: false,
      },
      mainItem: {
        name: 'P90 | Asiimov (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopuP1FAR17OORIXBD_9W_mY-dqPv9NLPF2GlQucF0iejH8dj2iQDtqUI-a2ilcY_Ge1RtM1mG8lG3xLq815e0uJ3XiSw0ptcExpY',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopuP1FAR17OORIXBD_9W_mY-dqPv9NLPFqWdQ-sJ0xLnC9Nvz31K3-0BuMGD7d4PGIQM-ZwuDrgS3w7zshsO5tJ7PmHoysig8pSGKpkWB0BI',
        color: '#eb4b4b',
        weapon: 'SMG',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'P90',
        skin: 'Asiimov',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 1749,
        phase: null,
      },
      mainItemName: 'P90 | Asiimov (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 13,
        name: '50/50',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: ['привет'],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    25: {
      id: 25,
      name: 'NOQUA',
      enabled: true,
      publishedAt: '2023-06-06T11:22:33.263Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1671687189607',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/7f3e5ecdd3e27dc09f87a1115f014a0c.png?t=1671687190188',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/7f3e5ecdd3e27dc09f87a1115f014a0c.png?t=1671687190823',
      priority: null,
      type: 'free',
      tags: ['Battle', 'New'],
      minAccountType: 'silver',
      lastRevision: {
        id: 483,
        revision: 10,
        price: 15075,
        prevPrice: 10000,
        results: false,
      },
      mainItem: {
        name: '★ Karambit | Tiger Tooth (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf2PLacDBA5ciJlY60g_7zNqnumXlQ5sJ0teXI8oThxg2yrUJvZWqicYLBe1c_ZgnY-Vi6w7jvhcS1vJyfnXJluCkk5X7bnR2pwUYb2myqBHU',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf2PLacDBA5ciJlY60g_7zNqnumXlQ5sJ0teXI8oTht1i1uRQ5fTqnIdecJgFqMFmG-1TsxO3phcO0vpibziZruCYj537dzECwgB9KauZxxavJ_ct1ylw',
        color: '#eb4b4b',
        weapon: 'Knife',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'Karambit',
        skin: 'Tiger Tooth',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 114900,
        phase: null,
      },
      mainItemName: '★ Karambit | Tiger Tooth (Factory New)',
      minOpensCount: 1,
      category: null,
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
      ticketQuest: {
        ticketsAmount: 1,
        ticketColor: 'orange-key',
      },
    },
    26: {
      id: 26,
      name: 'FAST $60',
      enabled: true,
      publishedAt: '2023-05-26T14:44:38.519Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1671687181441',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/dd06418a2a5e9fec617da0e5e98b08dd.png?t=1671687182008',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/dd06418a2a5e9fec617da0e5e98b08dd.png?t=1671687182582',
      priority: null,
      type: 'paid-free',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 304,
        revision: 6,
        price: 6000,
        prevPrice: 6000,
        results: false,
      },
      mainItem: {
        name: 'AWP | Oni Taiji (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17PLfYQJK7dK4jYG0m_7zO6-fkDxS68Ny27qTpoqj3Vfj-xVvMT_zII7BI1BtaA6D-AfowOvqhpXuu4OJlyVvClpuDw',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17PLfYQJK7dK4jYG0m_7zO6_ummpD78A_juqZoomljgW1rhY9MTz1d4fGegI-N1qB8wS5xOi61JS6vcifwCZruz5iuyh5fuIHYA',
        color: '#eb4b4b',
        weapon: 'Sniper Rifle',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'AWP',
        skin: 'Oni Taiji',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 53603,
        phase: null,
      },
      mainItemName: 'AWP | Oni Taiji (Factory New)',
      minOpensCount: 1,
      category: null,
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
      ticketQuest: {
        ticketsAmount: 1,
        ticketColor: 'orange-key',
      },
    },
    27: {
      id: 27,
      name: 'FAST $70',
      enabled: true,
      publishedAt: '2023-05-26T14:44:38.519Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1671687160720',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/fa2901524ea20a6d4fffdbd740d28cd3.png?t=1671687161303',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/fa2901524ea20a6d4fffdbd740d28cd3.png?t=1671687161856',
      priority: null,
      type: 'paid-free',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 477,
        revision: 12,
        price: 6500,
        prevPrice: 7000,
        results: false,
      },
      mainItem: {
        name: '★ Specialist Gloves | Tiger Strike (Well-Worn)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAQ1h3LAVbv6mxFABs3OXNYgJR_Nm1nYGHnuTgDK_YkW5D18pji-fG8LP4jVC9vh5yZzzycY-TdlU5YFqCq1Lvx-np1JO1uMnMmHVm7HQh4SvVzEaz0B5POvsv26LzrVRY1A',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAQ1h3LAVbv6mxFABs3OXNYgJR_Nm1nYGHnuTgDK_YkW5D18pji-fG8LP4jVC9vh4DPzixc9OLdlM_NFHW_gK5wOy91JXtuprPmHdqvnQntHjYyUG0hUxEPbZr1POdHULeWfKt10KdLg',
        color: '#eb4b4b',
        weapon: 'gloves',
        type: 'Gloves',
        rarity: 'Extraordinary',
        shortName: '★ Specialist Gloves',
        skin: 'Tiger Strike',
        exterior: 'Well-Worn',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 56812,
        phase: null,
      },
      mainItemName: '★ Specialist Gloves | Tiger Strike (Well-Worn)',
      minOpensCount: 1,
      category: null,
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    28: {
      id: 28,
      name: 'FAST $80',
      enabled: true,
      publishedAt: '2023-05-26T14:44:38.520Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1671687171869',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/f5ec234e65a04eb04a6c3be9f4fad502.png?t=1671687172483',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/f5ec234e65a04eb04a6c3be9f4fad502.png?t=1671687173154',
      priority: null,
      type: 'paid-free',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 307,
        revision: 6,
        price: 8000,
        prevPrice: 8000,
        results: false,
      },
      mainItem: {
        name: '★ Karambit | Crimson Web (Field-Tested)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf2PLacDBA5ciJnJm0gPL2IITck29Y_cg_3bjCpd_3iwDtqRFrYW2lcdSTJlVoY1qF-VPsleu7h5XuucucnXow6D5iuyhqD-gTcw',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf2PLacDBA5ciJnJm0gPL2IITck29Y_chOhujT8om7iwLn_Ec4NWrwdoDDIFNtZlHT-QW6xOzmgZ_t6pTNznUyvnQg7Crclwv330__CL9_dg',
        color: '#eb4b4b',
        weapon: 'Knife',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'Karambit',
        skin: 'Crimson Web',
        exterior: 'Field-Tested',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 92873,
        phase: null,
      },
      mainItemName: '★ Karambit | Crimson Web (Field-Tested)',
      minOpensCount: 1,
      category: null,
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    29: {
      id: 29,
      name: 'FAST $90',
      enabled: true,
      publishedAt: '2023-05-26T14:44:38.519Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1671687137725',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/fa2901524ea20a6d4fffdbd740d28cd3.png?t=1671687138383',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/27d61a3897e7af71e5d8e3161a93cfd2.png?t=1671687139010',
      priority: null,
      type: 'paid-free',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 475,
        revision: 6,
        price: 9000,
        prevPrice: 9000,
        results: false,
      },
      mainItem: {
        name: '★ Butterfly Knife | Ultraviolet (Field-Tested)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf0ebcZThQ6tCvq5OEqOfhIavdk1Rc7cF4n-SPoYqs2Fbm-UFsZm3wIIaccQNqYQzUrFC8ku6-18DqvZzNwSFnvHYhtmGdwUIGZFUt2g',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf0ebcZThQ6tCvq5OEqOfhIavdk1Rc7cF4n-T--Y3nj1H6qhVtNj3yJ4eSIABqaVnTqAfoxOa5gMC46s6cznFrsyAi4naMmxXhhQYMMLKNBL0DMQ',
        color: '#eb4b4b',
        weapon: 'Knife',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'Butterfly Knife',
        skin: 'Ultraviolet',
        exterior: 'Field-Tested',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 86126,
        phase: null,
      },
      mainItemName: '★ Butterfly Knife | Ultraviolet (Field-Tested)',
      minOpensCount: 1,
      category: {
        id: 12,
        name: '4',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'halloween',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: false,
      isFavorite: false,
    },
    31: {
      id: 31,
      name: '13',
      enabled: true,
      publishedAt: '2023-07-05T06:14:25.243Z',
      image: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1688537106739',
      itemImage: null,
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/2c790f4a7325a4e4bf628b6db83a75b1.png?t=1688537107359',
      priority: null,
      type: 'paid',
      tags: [],
      minAccountType: 'demo',
      lastRevision: {
        id: 168,
        revision: 3,
        price: 400,
        prevPrice: 400,
        results: false,
      },
      mainItem: {
        name: 'USP-S | Monster Mashup (Field-Tested)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq5WYh8j_IbTamXhu5cB1g_zMu4302Abl-0ZqMj_yJ9eccQQ6YQzUq1Tsx-rp0Ze0vpufyHtrvyQg5ynD30vg6hQ86DI',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq5WYh8j_IbTamXhu5cB1g_zMyoD0mlOx5RVpMjiiIISTclI_Z1-BrAO9xefu1Mfv6pvImHBluSJw5y2OmRW-hE5SLrs46BellJY',
        color: '#d32ce6',
        weapon: 'Pistol',
        type: 'weapon',
        rarity: 'Classified',
        shortName: 'USP-S',
        skin: 'Monster Mashup',
        exterior: 'Field-Tested',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 1345,
        phase: null,
      },
      mainItemName: 'USP-S | Monster Mashup (Field-Tested)',
      minOpensCount: 1,
      category: {
        id: 14,
        name: 'Beneficial Street',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    38: {
      id: 38,
      name: '1% jop',
      enabled: true,
      publishedAt: '2023-10-18T06:12:49.034Z',
      image: null,
      itemImage: null,
      historyImage: null,
      backgroundImage: null,
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 436,
        revision: 4,
        price: 50,
        prevPrice: 100,
        results: false,
      },
      mainItem: null,
      mainItemName: null,
      minOpensCount: 1,
      category: {
        id: 9,
        name: '1',
        image: 'https://din8r827idtuo.cloudfront.net/cases/471385595e38b4b0825e001586f0723f.png?t=1673973783332',
        cases: [],
        disableAt: '2023-05-16 14:45:25+00',
        grid: '',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    39: {
      id: 39,
      name: '1%. sss',
      enabled: true,
      publishedAt: '2023-05-26T14:44:38.519Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1671687197599',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/a05195d0f36ff09fb10ca09827169792.png?t=1671687198177',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/a05195d0f36ff09fb10ca09827169792.png?t=1671687198765',
      priority: null,
      type: 'paid-free',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 197,
        revision: 2,
        price: 10000,
        prevPrice: 10000,
        results: false,
      },
      mainItem: null,
      mainItemName: null,
      minOpensCount: 1,
      category: {
        id: 8,
        name: 'wooo',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    41: {
      id: 41,
      name: 'CS:GO Graffiti Box',
      enabled: true,
      publishedAt: '2023-06-02T06:51:03.113Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685688662496',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/2bcafda2cb00ba9ee196fcf506c97522.png?t=1685688663105',
      priority: null,
      type: 'paid-free',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 486,
        revision: 6,
        price: 50000,
        prevPrice: 24,
        results: false,
      },
      mainItem: {
        name: '★ Flip Knife | Marble Fade (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf1f_BYQJD4eO7lZKJm_LLNbrVk1Rd4cJ5nqeW8Yin21a1rRJpa2qiIYTEegE4YliD_AXtx-vmgZHvvZ_BzyAxuSYr-z-DyHvpiuhf',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf1f_BYQJD4eO7lZKJm_LLNbrVk1Rd4cJ5ntbN9J7yjRrh_BJlamqidoCTcQRsMArX_lPqkufp0J7p7sidn3trvichsy7YzRG_n1gSORYEYb_6',
        color: '#eb4b4b',
        weapon: 'Knife',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'Flip Knife',
        skin: 'Marble Fade',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 49262,
        phase: null,
      },
      mainItemName: '★ Flip Knife | Marble Fade (Factory New)',
      minOpensCount: 1,
      category: {
        id: 3,
        name: 'Seasons',
        image: 'https://din8r827idtuo.cloudfront.net/cases/cecef446505341d55cb101f41dd09e0b.png?t=1711458467642',
        cases: [],
        disableAt: null,
        grid: 'row_easter_day',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
      free: {
        count: 170,
        caseId: 41,
        usedAt: '2024-07-30 12:49:31.077+00',
        nextUseAt: '2024-07-30T12:49:31+00:00',
        cooldown: null,
      },
    },
    42: {
      id: 42,
      name: 'freeee',
      enabled: true,
      publishedAt: '2024-05-29T07:23:30.327Z',
      image: 'https://din8r827idtuo.cloudfront.net/cases/5fec438c8480bcffc08423fab270a664.png?t=1676644151542',
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/dd1c6482e81fc6f6f6715e891d6e3d92.png?t=1676643956298',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/66d053f21f547ecad26e8d49a0cff295.png?t=1676643956988',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/5fec438c8480bcffc08423fab270a664.png?t=1676644124128',
      priority: null,
      type: 'free',
      tags: [''],
      minAccountType: 'demo',
      lastRevision: {
        id: 241,
        revision: 3,
        price: 540,
        prevPrice: 550,
        results: false,
      },
      mainItem: {
        name: '★ Flip Knife | Freehand (Field-Tested)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf1f_BYQJD4eO7lZKAkvPLJqvum25V4dB8xLiWrY7z0Ffj8xdlMmGgINPBIAI7aQvU_FC9xLy-0JW0vsydmHZjuiE8pSGKyxfih-4',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf1f_BYQJD4eO7lZKAkvPLJqvum25V4dB8teXA54vwxlDgrkI5MWj3cdPEelc8M1iBqVboyOvrjMO66c7MmnFmsiV37XqIlxOpwUYbxobTCzM',
        color: '#eb4b4b',
        weapon: 'Knife',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'Flip Knife',
        skin: 'Freehand',
        exterior: 'Field-Tested',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 20599,
        phase: null,
      },
      mainItemName: '★ Flip Knife | Freehand (Field-Tested)',
      minOpensCount: 1,
      category: {
        id: 2,
        name: 'CS2 CASES',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: true,
        tags: ['test'],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    45: {
      id: 45,
      name: 'Valentine Day 1',
      enabled: true,
      publishedAt: '2023-05-26T14:39:40.752Z',
      image: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1675836324113',
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1675836324503',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/356f6e16407b91dcc7cf81fc8a956380.png?t=1675836324994',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/aeb09ce4a896bf9ccdaeeee2c7af429c.png?t=1675838228550',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'demo',
      lastRevision: {
        id: 473,
        revision: 3,
        price: 77,
        prevPrice: 0,
        results: false,
      },
      mainItem: {
        name: 'Sawed-Off | Kiss♥Love (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopbuyLgNv1fX3cih9_92hkYSEkfHLOLLChWde_sBOhuDG_Zi72Vbg_EpsMGzwLY7HewQ2ZgzRqFC-k7i81JO7vMnByiBguycg5i7Ungv3308FhEqA0Q',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopbuyLgNv1fX3cih9_92hkYSEkfHLOLLChWde_sBOhuDG_ZjKhFWmrBZyZmynd4aSJAY5Yg3YqVTtlOjq05S96Zmfm3dqvyck53qJzRLl0BpKaPsv26LJyTEEHQ',
        color: '#d32ce6',
        weapon: 'Shotgun',
        type: 'weapon',
        rarity: 'Classified',
        shortName: 'Sawed-Off',
        skin: 'Kiss♥Love',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 1948,
        phase: null,
      },
      mainItemName: 'Sawed-Off | Kiss♥Love (Factory New)',
      minOpensCount: 1,
      category: {
        id: 2,
        name: 'CS2 CASES',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: true,
        tags: ['test'],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    49: {
      id: 49,
      name: 'Valentine Day 5',
      enabled: true,
      publishedAt: '2023-05-26T14:39:46.365Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1675836512072',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/e3e80d33bcd7e33b07971c2307f367c6.png?t=1675838237497',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'demo',
      lastRevision: {
        id: 476,
        revision: 3,
        price: 414,
        prevPrice: 0,
        results: false,
      },
      mainItem: {
        name: '★ Driver Gloves | Diamondback (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAX1R3LjtQurWzLhRfwP_BcjZ9_9K3n4WYnP76DKLUmmde__p9g-7J4cKl2QOy-RBrNTihJtXAIVVrM1zU_lXtweu8jZbq7Z_KwSRnvCIk4SrdgVXp1nhnc4KK',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAX1R3LjtQurWzLhRfwP_BcjZ9_9K3n4WYnP76DKLUmmde__p9g-7J4bP5iUazrl1sYm6lJtWSJFdsYwuFqQLrk-rrgZLvvJiawXI16yIh7SnZmRexhU1McKUx0oSSfN1A',
        color: '#eb4b4b',
        weapon: 'gloves',
        type: 'Gloves',
        rarity: 'Extraordinary',
        shortName: '★ Driver Gloves',
        skin: 'Diamondback',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 27163,
        phase: null,
      },
      mainItemName: '★ Driver Gloves | Diamondback (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 14,
        name: 'Beneficial Street',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: false,
      isFavorite: false,
    },
    50: {
      id: 50,
      name: 'val 6',
      enabled: true,
      publishedAt: '2023-05-26T14:44:38.520Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1675836553674',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/3e8987021ce8766d6f490e75c3dbb86f.png?t=1675838233587',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'demo',
      lastRevision: {
        id: 468,
        revision: 4,
        price: 1477,
        prevPrice: 0,
        results: false,
      },
      mainItem: {
        name: 'Desert Eagle | Blaze (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposr-kLAtl7PLJTjtO7dGzh7-HnvD8J_XSwGkG65d1juqZp4rz3VLhrhc_azqhJtORdgM4YFvR-1C5wry5gpHqot2XnpVn5DmP',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposr-kLAtl7PLJTjtO7dGzh7-HnvD8J4Tdl3lW7Yt1jriVpY-migfh8hBtZTqgcI7Aewc2MgnWqwW-k-zph569uZyfnHMwpGB8slBfgSNs',
        color: '#8847ff',
        weapon: 'Pistol',
        type: 'weapon',
        rarity: 'Restricted',
        shortName: 'Desert Eagle',
        skin: 'Blaze',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 55477,
        phase: null,
      },
      mainItemName: 'Desert Eagle | Blaze (Factory New)',
      minOpensCount: 1,
      category: {
        id: 5,
        name: 'Over 10%',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    52: {
      id: 52,
      name: 'eSports 2013 Winter Case',
      enabled: true,
      publishedAt: '2023-05-26T14:39:54.503Z',
      image: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1678863522866',
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1678863523267',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/79288b3935f4c515a9d3fab56ddecc6d.png?t=1678863523986',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/79288b3935f4c515a9d3fab56ddecc6d.png?t=1678863524764',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'demo',
      lastRevision: {
        id: 463,
        revision: 3,
        price: 30,
        prevPrice: 0,
        results: false,
      },
      mainItem: {
        name: 'CZ75-Auto | Emerald (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZf0v33dDBH_t26kL-HnvD8J_WDkzNUsZ0k37nApo-g3gPsqUBsa2n7cYKRdg8_N1GF-lfsxum618W6ot2Xnn5j1JpH',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZf0v33dDBH_t26kL-HnvD8J4Tdl3lW7Yskib2Wptik2QXg_EQ9a2H3LIOUIVdrZ1HZ8lm5wL2905HvtJrJmiFlpGB8skUfgZfV',
        color: '#4b69ff',
        weapon: 'Pistol',
        type: 'weapon',
        rarity: 'Mil-Spec Grade',
        shortName: 'CZ75-Auto',
        skin: 'Emerald',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 1338,
        phase: null,
      },
      mainItemName: 'CZ75-Auto | Emerald (Factory New)',
      minOpensCount: 1,
      category: {
        id: 3,
        name: 'Seasons',
        image: 'https://din8r827idtuo.cloudfront.net/cases/cecef446505341d55cb101f41dd09e0b.png?t=1711458467642',
        cases: [],
        disableAt: null,
        grid: 'row_easter_day',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    54: {
      id: 54,
      name: 'free casse',
      enabled: true,
      publishedAt: '2023-05-26T14:25:45.559Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685111144915',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/869394a06b353dcd7f08cf62d3416b48.png?t=1685111145557',
      priority: null,
      type: 'paid',
      tags: [],
      minAccountType: 'silver',
      lastRevision: {
        id: 264,
        revision: 6,
        price: 100,
        prevPrice: 0,
        results: false,
      },
      mainItem: {
        name: 'XM1014 | Blue Spruce (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgporrf0e1Y07ODHTjBN_8-JmImMn-O6ZevVxG8Cv8Ah2L-W9N6n2gfl-xZpMWGiddWXd1VtYV3ZqVW3l7q71oj84srX2bLPtQ',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgporrf0e1Y07ODHTjBN_8-JmImMn-PLP7rDkW4fvZYh0r6Yo4r03lGy-RY4Mjj3IdeTIQBrZluD_1i6xOy51pS6uZrByGwj5HdDTKCfRw',
        color: '#b0c3d9',
        weapon: 'Shotgun',
        type: 'weapon',
        rarity: 'Consumer Grade',
        shortName: 'XM1014',
        skin: 'Blue Spruce',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 2,
        phase: null,
      },
      mainItemName: 'XM1014 | Blue Spruce (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 6,
        name: 'Lucky Time',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    55: {
      id: 55,
      name: 'free12',
      enabled: true,
      publishedAt: '2023-05-26T14:25:53.365Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685111152565',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/e1126bd32a132d1c6ff3e197dd3131ec.png?t=1685111153356',
      priority: null,
      type: 'free',
      tags: [],
      minAccountType: 'bronze',
      lastRevision: {
        id: 269,
        revision: 5,
        price: 100,
        prevPrice: 0,
        results: false,
      },
      mainItem: {
        name: 'XM1014 | Blue Spruce (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgporrf0e1Y07ODHTjBN_8-JmImMn-O6ZevVxG8Cv8Ah2L-W9N6n2gfl-xZpMWGiddWXd1VtYV3ZqVW3l7q71oj84srX2bLPtQ',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgporrf0e1Y07ODHTjBN_8-JmImMn-PLP7rDkW4fvZYh0r6Yo4r03lGy-RY4Mjj3IdeTIQBrZluD_1i6xOy51pS6uZrByGwj5HdDTKCfRw',
        color: '#b0c3d9',
        weapon: 'Shotgun',
        type: 'weapon',
        rarity: 'Consumer Grade',
        shortName: 'XM1014',
        skin: 'Blue Spruce',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 2,
        phase: null,
      },
      mainItemName: 'XM1014 | Blue Spruce (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 6,
        name: 'Lucky Time',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    56: {
      id: 56,
      name: 'lux1',
      enabled: true,
      publishedAt: '2023-05-26T14:47:31.826Z',
      image: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1702627192687',
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1702627193070',
      historyImage: 'https://din8r827idtuo.cloudfront.net/cases/e79c7f5b7f03fb2176c545627e1753d2.png?t=1707296038339',
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/e79c7f5b7f03fb2176c545627e1753d2.png?t=1707296039041',
      priority: null,
      type: 'paid-free',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 479,
        revision: 3,
        price: 10000,
        prevPrice: 0,
        results: false,
      },
      mainItem: {
        name: '★ Karambit | Tiger Tooth (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf2PLacDBA5ciJlY60g_7zNqnumXlQ5sJ0teXI8oThxg2yrUJvZWqicYLBe1c_ZgnY-Vi6w7jvhcS1vJyfnXJluCkk5X7bnR2pwUYb2myqBHU',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf2PLacDBA5ciJlY60g_7zNqnumXlQ5sJ0teXI8oTht1i1uRQ5fTqnIdecJgFqMFmG-1TsxO3phcO0vpibziZruCYj537dzECwgB9KauZxxavJ_ct1ylw',
        color: '#eb4b4b',
        weapon: 'Knife',
        type: 'weapon',
        rarity: 'Covert',
        shortName: 'Karambit',
        skin: 'Tiger Tooth',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 114900,
        phase: null,
      },
      mainItemName: '★ Karambit | Tiger Tooth (Factory New)',
      minOpensCount: 1,
      category: {
        id: 17,
        name: 'Happy 2024',
        image: 'https://din8r827idtuo.cloudfront.net/cases/f2b87deac360c4f249bed0688233872f.png?t=1678877585776',
        cases: [],
        disableAt: '2024-05-23 12:00:25+00',
        grid: 'row_valentine',
        sortable: false,
        tags: ['new'],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: false,
      isFavorite: false,
      availableCount: 916,
    },
    61: {
      id: 61,
      name: 'Case Battle #3',
      enabled: true,
      publishedAt: '2023-06-06T12:01:59.215Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1684131711711',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/fdaed042db805a6e394eb2a0ee1e63bd.png?t=1684131712208',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 471,
        revision: 4,
        price: 25,
        prevPrice: null,
        results: false,
      },
      mainItem: {
        name: 'Galil AR | Signal (Field-Tested)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbupIgthwczAaAJF7dC_mL-YnvD6MrfuhG5V18h0juDU-MKljQWyr0tvZWv2cICXd1Q8aVmG_lC5x-nr15e66pTAzSE2s3YityqMgVXp1r7cWgTf',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbupIgthwczAaAJF7dC_mL-YnvD6MrfuhG5V18h0juDU-LP5iUazrl1uYDjxIYbEJwI_MFrR8lDow-ntgZ646pnImnY2viIl5HnVyhaygRBEcKUx0rpBfnQb',
        color: '#8847ff',
        weapon: 'Rifle',
        type: 'weapon',
        rarity: 'Restricted',
        shortName: 'Galil AR',
        skin: 'Signal',
        exterior: 'Field-Tested',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 53,
        phase: null,
      },
      mainItemName: 'Galil AR | Signal (Field-Tested)',
      minOpensCount: 1,
      category: {
        id: 3,
        name: 'Seasons',
        image: 'https://din8r827idtuo.cloudfront.net/cases/cecef446505341d55cb101f41dd09e0b.png?t=1711458467642',
        cases: [],
        disableAt: null,
        grid: 'row_easter_day',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    64: {
      id: 64,
      name: 'Case Battle #6',
      enabled: true,
      publishedAt: '2023-05-26T14:28:17.782Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685111296907',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/6bebbd8c16381fb740f8753a95f094e7.png?t=1685111297780',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 466,
        revision: 3,
        price: 35,
        prevPrice: null,
        results: false,
      },
      mainItem: {
        name: 'StatTrak™ Nova | Wild Six (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpouLWzKjhnwMzGfitD0924l4iEhf7gNoTdn2xZ_Isn07jFpNr0jlXn80M4am_zJ9THd1JtMAzV_VS6lb3pgpC56JqbwXowpGB8sk7yiU0w',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpouLWzKjhnwMzGfitD0924l4iEhf7gNoTdn2xZ_Pp9i_vG8MKh2Q2280VlMWzwJYDHdAFvZw2E_Fa9lLq-08K8tJWYwCZivHMi43mOgVXp1gsRSidD',
        color: '#8847ff',
        weapon: 'Shotgun',
        type: 'weapon',
        rarity: 'Restricted',
        shortName: 'Nova',
        skin: 'Wild Six',
        exterior: 'Factory New',
        statTrak: true,
        isSouvenir: false,
        steamPrice: 281,
        phase: null,
      },
      mainItemName: 'StatTrak™ Nova | Wild Six (Factory New)',
      minOpensCount: 1,
      category: {
        id: 2,
        name: 'CS2 CASES',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: true,
        tags: ['test'],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    71: {
      id: 71,
      name: 'Case Battle #12',
      enabled: true,
      publishedAt: '2023-06-02T07:04:55.106Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685689494518',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/9a8d48996339241e77d4e649e706bdbd.png?t=1685689495102',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 470,
        revision: 3,
        price: 190,
        prevPrice: null,
        results: false,
      },
      mainItem: {
        name: 'SSG 08 | Turbo Peek (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopamie19f0Ob3Yi5F09OgkZKflvzxDLfYkWNFpsMh3rGUo47xiwGx-hBtMDv3doPEc1dsYViFqAO7lO7mhcDttc_JzSF9-n51raaoYu4',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopamie19f0Ob3Yi5F09OgkZKflvzxDLfYkWNF18lwmO7Eu9qn2gLl-0Zta2j7cdSXc1A_Yg3X8lPtlerm1JW_tZrJynRj6CQn7X7D30vgp4shdZk',
        color: '#d32ce6',
        weapon: 'Sniper Rifle',
        type: 'weapon',
        rarity: 'Classified',
        shortName: 'SSG 08',
        skin: 'Turbo Peek',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 567,
        phase: null,
      },
      mainItemName: 'SSG 08 | Turbo Peek (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 13,
        name: '50/50',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: ['привет'],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    76: {
      id: 76,
      name: 'Case Battle #17',
      enabled: true,
      publishedAt: '2023-05-26T14:42:19.924Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685110919838',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/e607490b626bebf075b46a56f05eee28.png?t=1685110048761',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 416,
        revision: 4,
        price: 850,
        prevPrice: 400,
        results: false,
      },
      mainItem: {
        name: '★ Specialist Gloves | Buckshot (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAQ1h3LAVbv6mxFABs3OXNYgJR_Nm1nYGHnuTgDL3ehG5C_PpzmObW-7P5gVO8v11lY2n1doDAIA88ZwvQ8lW4yObuhJDqvs7MynYwsyYhtH2Ll0Pk0k0fcKUx0kFa_qHn',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAQ1h3LAVbv6mxFABs3OXNYgJR_Nm1nYGHnuTgDL3ehG5C_PpzmObW-7P5gVO8vywwMiukcZiccgY4M16FqFi8x73vjZK6tZXIyHQ1uHMn5nuOlhO00BoaZrM81qDNVxzAUHCWWeMx',
        color: '#eb4b4b',
        weapon: 'gloves',
        type: 'Gloves',
        rarity: 'Extraordinary',
        shortName: '★ Specialist Gloves',
        skin: 'Buckshot',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 13341,
        phase: null,
      },
      mainItemName: '★ Specialist Gloves | Buckshot (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 12,
        name: '4',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'halloween',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: false,
      isFavorite: false,
    },
    80: {
      id: 80,
      name: 'Case Battle #20',
      enabled: true,
      publishedAt: '2023-05-26T14:25:35.468Z',
      image: null,
      itemImage: null,
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/11e0cfbd73eeb143f79618cc972ca445.png?t=1685111135464',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 478,
        revision: 4,
        price: 920,
        prevPrice: 450,
        results: false,
      },
      mainItem: {
        name: '★ Sport Gloves | Nocts (Well-Worn)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAQ1JmMR1osbaqPQJz7ODYfi9W9eO8lYeeluXLPr7Vn35cppYljr-Y9tiniQCx8xU4ZDr6JoHGJwA5NA3U-lTswLjs1sS1tJSbyiN9-n51ioSBvE8',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAQ1JmMR1osbaqPQJz7ODYfi9W9eO8lYeeluXLPr7Vn35c18lwmO7Eu9-hjALtqEduMm2mLNDBdVU3Y1-Dr1e5lLvrhZPuvcvKmiBqsilw5i7D30vgeWJUxBU',
        color: '#eb4b4b',
        weapon: 'gloves',
        type: 'Gloves',
        rarity: 'Extraordinary',
        shortName: '★ Sport Gloves',
        skin: 'Nocts',
        exterior: 'Well-Worn',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 29145,
        phase: null,
      },
      mainItemName: '★ Sport Gloves | Nocts (Well-Worn)',
      minOpensCount: 1,
      category: {
        id: 2,
        name: 'CS2 CASES',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: true,
        tags: ['test'],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    87: {
      id: 87,
      name: 'Case Battle #28',
      enabled: true,
      publishedAt: '2023-05-31T09:16:38.706Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685524598103',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/a26ac24a1de0da207df0cd9762eaa346.png?t=1685524598702',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 352,
        revision: 2,
        price: 2700,
        prevPrice: null,
        results: false,
      },
      mainItem: {
        name: '★ Hand Wraps | Overprint (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DfVlxgLQFFibKkJQN3wfLYYgJK7dKyg5KKh8j4NrrFnm5D8fp3i-vT_I_Kj1G7phYoITCgS9TJN1NRPQGHohWgkO3qhsLo6ZTKyXQ3s3J35CqMyUG_hRtPb7dv16OfS1yfA6cbGqmAUG2Q7s4Ma_K2Rg',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DfVlxgLQFFibKkJQN3wfLYYgJK7dKyg5KKh8j4NrrFnm5D8fp3i-vT_I_Kj1G7phYoITCgS9TJN1NRPQGHohXRnb-t0sOi7Z7MyiY37ykh5XmJlkfigE0dOLFngfadSA6eVfNIHqCDAnGCtpBxHjLqR03yK1V2Uw',
        color: '#eb4b4b',
        weapon: 'gloves',
        type: 'gloves',
        rarity: 'Extraordinary',
        shortName: null,
        skin: 'Overprint',
        exterior: 'Minimal Wear',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 62331,
        phase: null,
      },
      mainItemName: '★ Hand Wraps | Overprint (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 11,
        name: 'Luxury',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    93: {
      id: 93,
      name: 'Case Battle #34',
      enabled: true,
      publishedAt: '2023-06-06T10:24:27.772Z',
      image: null,
      itemImage: null,
      historyImage: null,
      backgroundImage: null,
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 370,
        revision: 4,
        price: 7000,
        prevPrice: null,
        results: false,
      },
      mainItem: {
        name: 'M4A1-S | Icarus Fell (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uO-jb-ClPbmJqjummJW4NE_0uiV99zwjA3j-EpoZW3zJ9KUJ1c6Y1rZrgXqwebphpPv7s-cm3Y36D5iuyjUCbMLPg',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uO-jb-ClPbmJqjummJW4NFOhujT8om73FWy-xJlMWjyJoSRdQc2YF7S_lO4we_vhJXouJrBmCY2vHEi43bazQv330_2CgpYYg',
        color: '#8847ff',
        weapon: 'Rifle',
        type: 'weapon',
        rarity: 'Restricted',
        shortName: 'M4A1-S',
        skin: 'Icarus Fell',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 43421,
        phase: null,
      },
      mainItemName: 'M4A1-S | Icarus Fell (Factory New)',
      minOpensCount: 1,
      category: {
        id: 6,
        name: 'Lucky Time',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    97: {
      id: 97,
      name: 'Case Battle #38',
      enabled: true,
      publishedAt: '2024-05-31T15:42:47.093Z',
      image: null,
      itemImage: null,
      historyImage: null,
      backgroundImage: null,
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 378,
        revision: 2,
        price: 800,
        prevPrice: null,
        results: false,
      },
      mainItem: {
        name: 'AK-47 | Panthera onca (Factory New)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot7HxfDhjxszJegJI7dujlZK0m_7zO6-fk21X7ZAp273DoNXz2wfiqBdtZmnzI47GJABsNFHV-1Lskurrh8O5voOJlyVD-NChGw',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot7HxfDhjxszJegJI7dujlZK0m_7zO6_ummpD78A_07vF8dSgjAHtr0FqYmymddScclRoaV6E_QS6lbjp0MO16Z3AzHNguD5iuyhm3xovwQ',
        color: '#d32ce6',
        weapon: 'Rifle',
        type: 'weapon',
        rarity: 'Classified',
        shortName: 'AK-47',
        skin: 'Panthera onca',
        exterior: 'Factory New',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 28853,
        phase: null,
      },
      mainItemName: 'AK-47 | Panthera onca (Factory New)',
      minOpensCount: 1,
      category: {
        id: 12,
        name: '4',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'halloween',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    102: {
      id: 102,
      name: 'Case Battle #43',
      enabled: true,
      publishedAt: '2023-10-18T06:12:52.579Z',
      image: null,
      itemImage: null,
      historyImage: null,
      backgroundImage: null,
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 391,
        revision: 1,
        price: 140,
        prevPrice: null,
        results: false,
      },
      mainItem: null,
      mainItemName: null,
      minOpensCount: 1,
      category: {
        id: 9,
        name: '1',
        image: 'https://din8r827idtuo.cloudfront.net/cases/471385595e38b4b0825e001586f0723f.png?t=1673973783332',
        cases: [],
        disableAt: '2023-05-16 14:45:25+00',
        grid: '',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    104: {
      id: 104,
      name: 'ST only',
      enabled: true,
      publishedAt: '2023-06-06T15:39:48.583Z',
      image: null,
      itemImage: 'https://din8r827idtuo.cloudfront.net/cases/c85d872a3baab7a5652079af3d419afb.png?t=1685525274373',
      historyImage: null,
      backgroundImage: 'https://din8r827idtuo.cloudfront.net/cases/801d27ef60dfdd193b9eecfaa8e18dd9.png?t=1685525274975',
      priority: null,
      type: 'paid',
      tags: ['new'],
      minAccountType: 'silver',
      lastRevision: {
        id: 400,
        revision: 2,
        price: 100,
        prevPrice: null,
        results: false,
      },
      mainItem: {
        name: 'StatTrak™ SCAR-20 | Bloodsport (Minimal Wear)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopbmkOVUw7PTbTi5B7c7kxL-Jm_j7N6jBmXlF18l4jeHVu4jw2gXsrkU9MG-gLdSdd1Q4N1HT-QS_yLi9hJG5uZnLznYwuCgh7S7D30vgKJHATJw',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopbmkOVUw7PTbTi5B7c7kxL-Jm_j7N6jBmXlF18l4jeHVyoD0mlOx5Rc4amClcdXGIAU_NVqFqVO3x7y80ZC-vMybnXprv3UksyrYn0GzhU1SLrs42O1g5dc',
        color: '#d32ce6',
        weapon: 'Sniper Rifle',
        type: 'weapon',
        rarity: 'Classified',
        shortName: 'SCAR-20',
        skin: 'Bloodsport',
        exterior: 'Minimal Wear',
        statTrak: true,
        isSouvenir: false,
        steamPrice: 796,
        phase: null,
      },
      mainItemName: 'StatTrak™ SCAR-20 | Bloodsport (Minimal Wear)',
      minOpensCount: 1,
      category: {
        id: 6,
        name: 'Lucky Time',
        image: null,
        cases: [],
        disableAt: null,
        grid: 'default',
        sortable: false,
        tags: [],
      },
      limitedEndAt: null,
      battles: true,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
    105: {
      id: 105,
      name: 'UUUUUUUUUUUX',
      enabled: true,
      publishedAt: '2023-08-29T08:08:36.850Z',
      image: null,
      itemImage: null,
      historyImage: null,
      backgroundImage: null,
      priority: null,
      type: 'free',
      tags: ['new'],
      minAccountType: 'demo',
      lastRevision: {
        id: 425,
        revision: 3,
        price: 50,
        prevPrice: 0,
        results: false,
      },
      mainItem: {
        name: 'M4A1-S | Nightmare (Field-Tested)',
        icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uO1gb-Gw_alIITfn2xZ_MhwmOz--InxgUG55UVoNjryJNCWJwNvZVqGr1C8leu5h5O0vM-cwHRiv3Z3tn-JyxHjh0tSLrs4UmHXRtw',
        iconLarge:
          '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uO1gb-Gw_alIITfn2xZ_MhwmOz--InxgUG5lB89IT6mOtLAIFNvYQrRqFC9l-vq0MO9vZ7OzXYx6XNx43mMnUGx0EsZO-w506GACQLJbw9g1os',
        color: '#d32ce6',
        weapon: 'Rifle',
        type: 'weapon',
        rarity: 'Classified',
        shortName: 'M4A1-S',
        skin: 'Nightmare',
        exterior: 'Field-Tested',
        statTrak: false,
        isSouvenir: false,
        steamPrice: 906,
        phase: null,
      },
      mainItemName: 'M4A1-S | Nightmare (Field-Tested)',
      minOpensCount: 1,
      category: null,
      limitedEndAt: null,
      battles: false,
      onlyBattles: false,
      visibleInAll: true,
      isFavorite: false,
    },
  };

  return {
    allbonusCases,
    casesDictionary,
  };
}
