<div class="sidebar-wrapper">
  @for (link of items(); track link.path) {
    <a
      langRouterLink
      [routerLink]="link.path"
      [queryParams]="link.queryParams"
      class="market-btn decoration-none"
      draggable="false"
      (click)="link.callback && link.callback()"
    >
      <div class="market-btn__img">
        <img
          [src]="link.icon"
          alt=""
          class="w-100"
          width="100px"
        />
      </div>
    </a>
  }
</div>
