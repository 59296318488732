import { inject, Injectable } from '@angular/core';
import {
  ActiveRoute,
  EClassicPhase,
  IClassicDepositConfig,
  IClassicHistory,
  IClassicPlayerConfig,
  IClassicWidgetConfig,
  IClassicWinnerConfig,
  IGameSettings,
  ModalNames,
  Panel,
  SubPanel,
  TClassicHistoryMode,
  TClassicViewMode,
  Trade,
  TradeOriginalItem,
  Widgets,
} from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { StateReset } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import { GamesState, SetCurrentGame } from '@app/core/state/games-store';
import { ChangeActivePanel, ChangeActiveWidgets, SetActiveRoute } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';

import {
  ClassicState,
  ClearWinningsItems,
  GetHistory,
  GetState,
  IClassicStateModel,
  ResetPartialState,
  ToggleHistoryMode,
  ToggleViewMode,
} from '../state';

@Injectable()
export class ClassicMainService {
  @Select(ClassicState.deposits)
  readonly deposits$!: Observable<IClassicDepositConfig[]>;

  @Select(ClassicState.history)
  readonly history$!: Observable<IClassicHistory[]>;

  @Select(ClassicState.historyMode)
  readonly historyMode$!: Observable<TClassicHistoryMode>;

  @Select(ClassicState.me)
  readonly me$!: Observable<IClassicPlayerConfig | null>;

  @Select(ClassicState.phase)
  readonly phase$!: Observable<EClassicPhase | null>;

  @Select(ClassicState.players)
  readonly players$!: Observable<IClassicPlayerConfig[]>;

  @Select(ClassicState.prize)
  readonly prize$!: Observable<number>;

  @Select(ClassicState.rafflingTimestampDiff)
  readonly rafflingTimestampDiff$!: Observable<number | null>;

  @Select(ClassicState.roundHash)
  readonly roundHash$!: Observable<string>;

  @Select(ClassicState.roundNumber)
  readonly roundNumber$!: Observable<number>;

  @Select(ClassicState.roundSecret)
  readonly roundSecret$!: Observable<string | null>;

  @Select(GamesState.currentGameSettings)
  readonly settings$!: Observable<IGameSettings | null>;

  @Select(ClassicState.tape)
  readonly tape$!: Observable<string[] | null>;

  @Select(ClassicState.totalItemsNumber)
  readonly totalItemsNumber$!: Observable<number>;

  @Select(ClassicState.trades)
  readonly trades$!: Observable<Trade[]>;

  @Select(ClassicState.viewMode)
  readonly viewMode$!: Observable<TClassicViewMode>;

  @Select(ClassicState.widgets)
  readonly widgets$!: Observable<IClassicWidgetConfig[]>;

  @Select(ClassicState.winner)
  readonly winner$!: Observable<IClassicWinnerConfig | null>;

  @Select(ClassicState.winningItems)
  readonly winningItems$!: Observable<TradeOriginalItem[]>;

  readonly #authService: AuthService = inject(AuthService);

  readonly isAuth$: Observable<boolean> = this.#authService.isAuth$;

  /** classic */
  @Dispatch() clearWinningItems = (): ClearWinningsItems => new ClearWinningsItems();
  @Dispatch() getHistory = (): GetHistory => new GetHistory();
  @Dispatch() getState = (): GetState => new GetState();

  @Dispatch() resetPartialState = (propertiesToClear: (keyof IClassicStateModel)[]): ResetPartialState =>
    new ResetPartialState(propertiesToClear);

  @Dispatch() resetState = (): StateReset => new StateReset(ClassicState);
  @Dispatch() toggleHistoryMode = (historyMode: TClassicHistoryMode): ToggleHistoryMode => new ToggleHistoryMode(historyMode);
  @Dispatch() toggleViewMode = (viewMode: TClassicViewMode): ToggleViewMode => new ToggleViewMode(viewMode);

  /** games */
  @Dispatch() setCurrentGame = (key: string): SetCurrentGame => new SetCurrentGame(key);

  /** layout */
  @Dispatch() changeActiveWidgets = (widgets: Widgets[]): ChangeActiveWidgets => new ChangeActiveWidgets(widgets);
  @Dispatch() openInventory = (): ChangeActivePanel => new ChangeActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.BET });
  @Dispatch() setActiveRoute = (route: ActiveRoute): SetActiveRoute => new SetActiveRoute(route);

  /** modals */
  @Dispatch() openModal = (name: ModalNames, payload: any): OpenModal => new OpenModal(name, payload);
}
