<app-price
  [price]="price()"
  [overprice]="overprice()"
  [dollarPrice]="dollarPrice()"
/>
@if (canBuy()) {
  <div class="tw-flex tw-gap-2 tw-text-sm tw-font-medium tw-tracking-wide tw-leading-5">
    @if (isAuthorized()) {
      @if (this.isEnoughBalance()) {
        <button
          class="tw-px-6 tw-py-[0.65rem] tw-bg-yellow-500 tw-rounded-lg tw-text-gray-800 tw-whitespace-nowrap"
          (click)="onBuyNow()"
        >
          {{ 'P2P_SETTINGS.ITEM_DETAIL.BUY_NOW' | translate }}
        </button>

        @if (isInTrade()) {
          <div class="tw-flex tw-gap-0 tw-justify-start">
            <button
              class="tw-flex tw-justify-center tw-items-center tw-px-6 tw-py-[0.65rem] tw-rounded-l-lg tw-border-2 tw-border-blue-500 tw-border-solid hover:tw-text-gray-200"
              (click)="onRemoveFromCart()"
            >
              <mat-icon
                class="!tw-w-5 !tw-h-5"
                [svgIcon]="'shopping-cart-off'"
              />
            </button>
            <button
              class="tw-flex tw-gap-2 tw-justify-center tw-items-center tw-px-6 tw-py-[0.65rem] -tw-ml-[2px] tw-text-sm tw-font-medium tw-tracking-wide tw-leading-5 tw-text-white tw-rounded-r-lg tw-border-2 tw-border-blue-500 tw-border-solid tw-bg-blue-500 hover:tw-bg-blue-500 hover:tw-text-gray-800"
              (click)="onNavigateToCart()"
            >
              <span>{{ 'P2P_SETTINGS.ITEM_DETAIL.TO_CART' | translate }}</span>
              <mat-icon
                class="!tw-w-5 !tw-h-5"
                [svgIcon]="'arrow-right'"
              />
            </button>
          </div>
        } @else {
          <button
            class="tw-px-6 tw-py-[0.65rem] tw-text-yellow-500 tw-rounded-lg tw-border-2 tw-border-yellow-500 tw-border-solid tw-whitespace-nowrap hover:tw-bg-yellow-500 hover:tw-text-gray-800"
            (click)="onAddToCart()"
          >
            {{ 'P2P_SETTINGS.ITEM_DETAIL.ADD_TO_CART' | translate }}
          </button>
        }
      } @else {
        <button
          class="tw-px-6 tw-py-[0.65rem] tw-bg-yellow-500 tw-rounded-lg tw-text-gray-800 tw-whitespace-nowrap"
          (click)="onRefill()"
        >
          {{ 'ACTIONS.REFILL' | translate }}
        </button>
      }
    } @else {
      <button
        class="tw-px-6 tw-py-[0.65rem] tw-bg-yellow-500 tw-rounded-lg tw-text-gray-800 tw-whitespace-nowrap"
        (click)="onLogin()"
      >
        {{ 'LOGIN' | translate }}
      </button>
    }
  </div>
}
