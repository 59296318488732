import type { BaseType, IMarketplaceKitData } from '@dev-fast/types';
import { EWearType } from '@dev-fast/types';

export const WEAR_TYPES: Array<BaseType<EWearType, EWearType>> = [
  {
    value: EWearType.FN,
    name: EWearType.FN,
  },
  {
    value: EWearType.MW,
    name: EWearType.MW,
  },
  {
    value: EWearType.FT,
    name: EWearType.FT,
  },
  {
    value: EWearType.WW,
    name: EWearType.WW,
  },
  {
    value: EWearType.BS,
    name: EWearType.BS,
  },
];
export const MIN_SIMILAR_ITEMS_TO_SHOW = 4;

export type SimilarDictionary = { [K in EWearType]?: IMarketplaceKitData[] };
