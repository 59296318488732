import { SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import type { ICaseItemDtoV2 } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import maxBy from 'lodash-es/maxBy';
import { RepeatPipe } from 'ngxtension/repeat-pipe';

import { CaseCardNewComponent } from '@app/shared/components/case-card-new/case-card-new.component';
import { ResizedDirective } from '@app/shared/directives/resized/resized.directive';
import type { ResizedEvent } from '@app/shared/directives/resized/resized.event';

import { MAX_ITEMS_TO_SHOW, MAX_ITEMS_TO_SHOW_TABLE, MIN_ITEMS_TO_SHOW } from './const';

@Component({
  selector: 'app-in-cases',
  standalone: true,
  imports: [CaseCardNewComponent, TranslateModule, RepeatPipe, SlicePipe, ResizedDirective],
  templateUrl: './in-cases.component.html',
  styleUrl: './in-cases.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InCasesComponent {
  readonly cases = input<ICaseItemDtoV2[] | null>(null);
  readonly isMobile = input<boolean>();

  readonly openCase = output<number>();
  readonly showAllCases = output<void>();

  readonly isLoading = computed<boolean>(() => !this.cases());
  readonly canShowSelf = computed<boolean>(() => {
    const cases = this.cases();
    const isLoading = this.isLoading();
    return isLoading || (!!cases && cases.length > 1);
  });

  readonly countEmptyCases = computed<number>(() => {
    const cases = this.cases() || [];
    return Math.max(this.itemsToShow() - cases?.length, 0);
  });

  readonly canShowAllCases = computed<boolean>(() => this.countEmptyCases() <= 0);
  readonly containerWidth = signal<number>(0);

  readonly itemsToShow = computed(() => {
    const width = this.containerWidth();
    if (this.isMobile()) {
      return MAX_ITEMS_TO_SHOW;
    }
    if (!width) {
      return MAX_ITEMS_TO_SHOW;
    }
    return width > 552 ? MAX_ITEMS_TO_SHOW : width < 476 ? MIN_ITEMS_TO_SHOW : MAX_ITEMS_TO_SHOW_TABLE;
  });

  getMaxChance(item: ICaseItemDtoV2): number {
    const revisionItems = item.lastRevision.items.map((el) => el);
    const itemWithMaxChance = maxBy(revisionItems, 'count');
    return itemWithMaxChance?.count || 0;
  }

  onCase(item: ICaseItemDtoV2): void {
    this.openCase.emit(item.id);
  }

  onShowAllCases(): void {
    this.showAllCases.emit();
  }

  // по макету 4 карточки на десктопе, 2 на мобилке, на планшете 3 и еще есть в дестопе в некторых кейсах
  // а на это подвязана еще логика, поэтому только css медиа запросы не подходят
  onResize($event: ResizedEvent): void {
    const width = $event.newRect.width;
    this.containerWidth.set(width);
  }
}
