import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { UserApiService } from '@dev-fast/backend-services';
import type { IPublicGameStatistic, IPublicUserDetailed } from '@dev-fast/types';
import { GameMode, PROJECT, SocialType } from '@dev-fast/types';
import { UserProfileBlockModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import type { Observable } from 'rxjs';
import { map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GamesState } from '@app/core/state/games-store';
import { IsProjectModule, LangRouterModule } from '@app/shared/directives';

@Component({
  selector: 'app-user-profile-new',
  templateUrl: './user-profile-new.component.html',
  styleUrls: ['./user-profile-new.component.scss', './user-profile-new.component.media.scss'],
  standalone: true,
  imports: [TranslateModule, UserProfileBlockModule, MatIcon, AsyncPipe, RouterLink, LangRouterModule, IsProjectModule],
})
export class UserProfileNewComponent {
  dialogRef = inject(MatDialogRef<UserProfileNewComponent>);

  #userService = inject(UserApiService);
  #store = inject(Store);
  #data: { userId: number } = inject(MAT_DIALOG_DATA);

  userId: number;
  $user: Observable<IPublicUserDetailed | null>;
  hiddenItemsArr: unknown[];
  hiddenItem: IPublicGameStatistic;
  socialTypes = SocialType;
  country!: string | null;
  projects: typeof PROJECT = PROJECT;

  constructor() {
    this.userId = this.#data.userId;
    // FIXME мне не нравится что тут делается запрос и таким образом. Но пока ничего лучше в голову не пришло
    this.$user = this.#userService
      .getUserProfile<IPublicUserDetailed>(this.userId, { needDetailedInfo: true })
      .pipe(catchError(() => of(null)));
    this.hiddenItemsArr = new Array(6);
    this.hiddenItem = {
      key: 'baccarat',
      mode: GameMode.PVE,
      top: {
        isCurrency: true,
        key: 'baccarat_max_bet',
        title: 'Max bet',
        value: '9999',
      },
    };
  }

  getGameTitle(key: string): Observable<string> {
    return this.#store.select(GamesState.gameTitle).pipe(map((filterFn) => filterFn(key)));
  }

  close(): void {
    this.dialogRef.close();
  }
}
