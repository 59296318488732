import type { ChartConfiguration, ChartOptions } from 'chart.js';
import type { ChartDataset, ScriptableContext } from 'chart.js/dist/types';

export const PRICE_COLORS = {
  bgGradient: ['rgba(72, 255, 41, 0.60)', 'rgba(72, 255, 41, 0.00)'],
  borderColor: '#34E517',
  pointBorderColor: '#34E517',
  pointBackgroundColor: '#34E517',
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: 'rgba(148,159,177,0.8)',
  gridColor: '#3F4354',
  tickColor: '#34E517',
};
export const SALES_COLORS = {
  bgGradient: ['rgba(23, 155, 229, 0.60)', 'rgba(13, 86, 127, 0.00)'],
  borderColor: 'rgba(0,0,0,0)',
  pointBorderColor: '#179BE5',
  pointBackgroundColor: '#179BE5',
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: 'rgba(148,159,177,0.8)',
  gridColor: '#3F4354',
  tickColor: '#179BE5',
};
export const PERIOD_COLORS = {
  gridColor: '#3F4354',
  tickColor: '#727488',
};
export const CHART_TYPE = 'line';
export const LINE_CHART_OPTIONS: ChartOptions<'line'> & { plugins: any } = {
  elements: {
    point: {
      radius: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 7,
        display: true,
        color: PERIOD_COLORS.tickColor,
      },
      grid: {
        display: true,
        color: PERIOD_COLORS.gridColor,
      },
    },
    y: {
      position: 'left',
      ticks: {
        count: 5,
        display: true,
        callback: (value, index) => {
          return '$' + (+value / 100).toFixed(2);
        },
        color: PRICE_COLORS.tickColor,
      },

      grid: {
        display: true,
      },
    },
    y1: {
      beginAtZero: true,
      position: 'right',
      grid: {
        color: SALES_COLORS.gridColor,
      },
      weight: 1,
      ticks: {
        count: 5,
        color: SALES_COLORS.tickColor,
        callback: (val, index) => {
          return +val % 1 === 0 ? val : '';
        },
        display: true,
      },
    },
  },
  interaction: {
    intersect: false,
    mode: 'index' as any,
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: any) => {
          if (context?.datasetIndex === 1) {
            return '$' + (context?.parsed?.y / 100).toFixed(2);
          }
          return context?.parsed?.y || '';
        },
      },
    },
    legend: {
      display: false,
      labels: {
        boxWidth: 0,
        usePointStyle: true,
      },
    },
  },
};
export const PRICE_CHART_DATASET: ChartDataset<'line'> = {
  data: [],
  label: '',
  tension: 0,

  borderColor: PRICE_COLORS.borderColor,
  backgroundColor: (context) => getBackgroundColor(context, PRICE_COLORS.bgGradient),
  pointBorderColor: PRICE_COLORS.pointBorderColor,
  pointBackgroundColor: PRICE_COLORS.pointBackgroundColor,
  pointHoverBackgroundColor: PRICE_COLORS.pointHoverBackgroundColor,
  pointHoverBorderColor: PRICE_COLORS.pointHoverBorderColor,
  fill: 'origin',
};
export const SALES_CHART_DATASET: ChartDataset<'line'> = {
  data: [],
  tension: 0,
  borderColor: SALES_COLORS.borderColor,
  backgroundColor: (context) => getBackgroundColor(context, SALES_COLORS.bgGradient),
  pointBorderColor: SALES_COLORS.pointBorderColor,
  pointBackgroundColor: SALES_COLORS.pointBackgroundColor,
  label: '',
  yAxisID: 'y1',
  pointHoverBackgroundColor: SALES_COLORS.pointHoverBackgroundColor,
  pointHoverBorderColor: SALES_COLORS.pointHoverBorderColor,
  fill: 'origin',
};
export const LINE_CHART_DATA: ChartConfiguration<'line'>['data'] = {
  datasets: [
    {
      ...PRICE_CHART_DATASET,
    },
    {
      ...SALES_CHART_DATASET,
    },
  ],
  labels: [],
};
export const getLineChartOptions = (labelsX?: string[]): ChartOptions<'line'> => {
  if (!labelsX) {
    return LINE_CHART_OPTIONS;
  }
  return {
    ...LINE_CHART_OPTIONS,
    scales: {
      ...LINE_CHART_OPTIONS.scales,
      x: {
        ...LINE_CHART_OPTIONS.scales?.['x'],
        ticks: {
          ...LINE_CHART_OPTIONS.scales?.['x']?.ticks,
          callback: (value, index) => {
            return index === labelsX.length - 1 ? '' : labelsX[index] || value;
          },
        },
      },
    },
  };
};

export const getBackgroundColor = (context: ScriptableContext<'line'>, colors: string[]): CanvasGradient | undefined => {
  // Массив цветов в формате RGBA с прозрачностью 0.2

  // Проверка, существует ли chartArea
  if (!context.chart.chartArea) {
    return;
  }

  const {
    ctx,
    chartArea: { top, bottom },
  } = context.chart;

  // Создание линейного градиента сверху вниз
  const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);

  // Расчёт шага для равномерного распределения цветов
  const colorTranches = 1 / (colors.length - 1);

  // Цикл по цветам и добавление их в градиент
  for (let i = 0; i < colors.length; i++) {
    gradientBg.addColorStop(i * colorTranches, colors[i]);
  }

  // Возвращаем созданный градиент
  return gradientBg;
};
export const getLineWidth = (context: any, labels: string[]): number => {
  return context.index === labels.length - 1 ? 0 : 1;
};
