import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { toShortRevisionWear } from '@app/shared/utils';

import { FloatValueComponent } from '../shared/float-value/float-value.component';

@Component({
  selector: 'app-name-float-block',
  standalone: true,
  imports: [FloatValueComponent],
  templateUrl: './name-float-block.component.html',
  styleUrl: './name-float-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameFloatBlockComponent {
  // inputs
  isSouvenir = input<boolean | null>();
  isStatTrak = input<boolean | null>();
  wearType = input<string | null>();
  name = input<string>();
  float = input<number | null>();
  // computed
  shortWearType = computed(() => this.toShortRevision(this.wearType()));
  quality = computed(() => (this.float() || 0) * 100);

  // methods
  toShortRevision(revision?: string | null): string {
    return revision ? toShortRevisionWear(revision) : '';
  }
}
