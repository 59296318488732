import type { OnInit } from '@angular/core';
import { Component, computed, inject, Injector, signal } from '@angular/core';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import type { IPromoCode, PROJECT } from '@dev-fast/types';
import {
  ModalNames,
  NotificationStatus,
  NotificationType,
  PromocodeTypes,
  ReferralCampaignTypes,
  SteamErrorsEnum,
  SteamTradeStatus,
  TransactionStatus,
} from '@dev-fast/types';
import { Store } from '@ngxs/store';

import { ENVIRONMENTS } from '@app/core/environment-service';
import { ModalsService } from '@app/core/modal-service';
import { NotificationsService } from '@app/core/notification-service';
import { OpenModal } from '@app/core/state/modals';
import { SelectionMenuSAComponent } from '@app/ui/components/index';

import { bonusCases, p2pTradeAcceptItem, transactionPayloadConstructor, viningItemsConstructor } from './data.mock';
import type { P2pErrorsDescriptionDialogData } from './interfaces/debug-p2p-errors-description.interface';
import { modalList, registerModalList } from './modal-list.const';

@Component({
  selector: 'app-debug-menu',
  templateUrl: 'debug-menu.component.html',
  styleUrl: 'debug-menu.component.scss',
  standalone: true,
  imports: [MatTabsModule, MatSelect, MatOption, SelectionMenuSAComponent],
  providers: [],
})
export class DebugMenuComponent implements OnInit {
  // Инжекторы компонента
  readonly #environment = inject(ENVIRONMENTS);
  readonly #commonModalsService = inject(ModalsService);
  readonly #store = inject(Store);
  readonly injector = inject(Injector);
  readonly #notifyService = inject(NotificationsService);

  // Переменные компонента
  readonly #project = this.#environment.PROJECT as PROJECT;
  readonly modalNames = ModalNames;

  readonly #availableModals = modalList
    .filter((item) => item.projects.includes(this.#project))
    .sort((a, b) => a.name.localeCompare(b.name));

  readonly options = this.#availableModals.map((item) => ({
    title: item.name,
    name: item.name,
  }));

  readonly #selectedIndex = signal<number>(0);

  readonly selectedModal = computed<ModalNames>(() => this.#availableModals[this.#selectedIndex()].name);
  readonly selectedDescription = computed<string>(() => this.#availableModals[this.#selectedIndex()].description);

  // Для нотификаций
  readonly notifyTypes = Object.values(NotificationType);
  readonly selectedNotifyType = signal<NotificationType>(NotificationType.Error);
  readonly notifyCategory = signal<string>('КАТЕГОРИЯ');
  readonly notifyMessage = signal<string>('Текст уведомления');

  // Для VICTORY и CASE_BATTLE_WINNERS
  readonly viningItemsAmount = signal<number>(10);

  // Для TRANSACTION_NOTIFICATION
  readonly transactionStatuses = Object.values(TransactionStatus);
  readonly selectedTransactionStatus = signal<TransactionStatus>(TransactionStatus.COMPLETED);

  // Для P2P_ERRORS_DESCRIPTION
  readonly steamTradeStatus = SteamTradeStatus;
  readonly steamTradeStatuses = Object.values(SteamTradeStatus);
  readonly steamErrors = Object.values(SteamErrorsEnum);
  readonly selectedTradeStatus = signal<SteamTradeStatus>(SteamTradeStatus.NEED_PERMISSIONS_ADD);
  readonly selectedSteamError = signal<SteamErrorsEnum | undefined>(undefined);
  readonly selectedP2pErrorsDescriptionData = computed<P2pErrorsDescriptionDialogData>(() => {
    const steamError = this.selectedSteamError();
    return {
      status: this.selectedTradeStatus(),
      permissions: {
        success: true,
        canTrade: steamError
          ? {
            success: false,
            error: steamError,
          }
          : undefined,
      },
    };
  });

  // Для REFERRAL
  readonly referralTypes = [...Object.values(ReferralCampaignTypes), ...Object.values(PromocodeTypes)];
  readonly referralCode = signal<string>('PROMOCODE');
  readonly referralReward = signal<number>(1000);
  readonly selectedReferralType = signal<ReferralCampaignTypes | PromocodeTypes>(PromocodeTypes.MONEY);
  readonly isOnReferrer = signal<boolean>(true);
  readonly #referralData = computed<IPromoCode>(() => this.#referralDataComputed());

  ngOnInit(): void {
    this.#commonModalsService.registerModals(registerModalList(this.injector));
  }

  // Для нотификаций
  callNotify(): void {
    this.#notifyService.addNotification({
      id: Date.now(),
      type: this.selectedNotifyType(),
      icon: this.selectedNotifyType(),
      category: this.notifyCategory(),
      message: this.notifyMessage(),
      createDate: Date.now(),
      status: NotificationStatus.new,
      system: true,
    });
  }

  notifyMessageChange(event: Event): void {
    this.notifyMessage.set((event.target as HTMLTextAreaElement).value);
  }

  notifyCategoryChange(event: Event): void {
    this.notifyCategory.set((event.target as HTMLInputElement).value);
  }

  // Для модалок
  onModalSelect(index: number): void {
    this.#selectedIndex.set(index);
  }

  openModal(): void {
    const modalName = this.selectedModal();

    switch (modalName) {
      case ModalNames.VICTORY:
        this.#store.dispatch(new OpenModal(modalName, { items: viningItemsConstructor(this.viningItemsAmount()) }));
        break;
      case ModalNames.BONUS_CASE:
        this.#store.dispatch(
          new OpenModal(modalName, { allBonusCases: bonusCases.allbonusCases, casesDictionary: bonusCases.casesDictionary }),
        );
        break;
      case ModalNames.P2P_AUCTION_COMPLETED:
        this.#store.dispatch(new OpenModal(modalName, 20100219));
        break;
      case ModalNames.BAN:
        this.#store.dispatch(new OpenModal(modalName, 'Because of you are the best!'));
        break;
      case ModalNames.TRANSACTION_NOTIFICATION:
        this.#store.dispatch(new OpenModal(modalName, transactionPayloadConstructor(this.selectedTransactionStatus())));
        break;
      case ModalNames.P2P_ERRORS_DESCRIPTION:
        this.#store.dispatch(new OpenModal(modalName, this.selectedP2pErrorsDescriptionData()));
        break;
      case ModalNames.REFERRAL:
        this.#store.dispatch(new OpenModal(modalName, this.#referralData()));
        break;
      case ModalNames.CASE_BATTLE_WINNERS:
        this.#store.dispatch(new OpenModal(modalName, { items: viningItemsConstructor(this.viningItemsAmount()), canInteraction: false }));
        break;
      case ModalNames.P2P_TRADE_ACCEPT:
        this.#store.dispatch(new OpenModal(modalName, p2pTradeAcceptItem));
        break;
      case ModalNames.P2P_RWT:
        this.#store.dispatch(new OpenModal(modalName, true));
        break;
      case ModalNames.SIH_INSTALL:
        this.#store.dispatch(new OpenModal(modalName));
        break;
      case ModalNames.CASE_BATTLE_GAME_BREAK:
        this.#store.dispatch(new OpenModal(modalName));
        break;
    }
  }

  // Для VICTORY и CASE_BATTLE_WINNERS
  viningItemsAmountChange(event: Event): void {
    let amount = 0;
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue !== '') {
      amount = Number(inputValue);
    }
    if (amount > 100) {
      amount = 100;
    }
    if (amount < 0) {
      amount = 0;
    }
    this.viningItemsAmount.set(amount);
  }

  // Для REFERRAL
  referralCodeChange(event: Event): void {
    this.referralCode.set((event.target as HTMLInputElement).value);
  }

  referralRewardChange(event: Event): void {
    let reward = 0;
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue !== '') {
      reward = Number(inputValue);
    }
    this.referralReward.set(reward);
  }

  isOnReferrerChange(event: Event): void {
    this.isOnReferrer.set((event.target as HTMLInputElement).checked);
  }

  #referralDataComputed(): IPromoCode {
    const user = {
      user: {
        id: 1114,
        name: 'Warrior`s Dance',
        avatar: 'https://avatars.steamstatic.com/be767e9564fd0ac1c04dc09f625a91c374989063_full.jpg',
        profileType: 'silver',
      },
    };
    return {
      code: this.referralCode(),
      reward: this.referralReward(),
      type: this.selectedReferralType(),
      rewarded: false,
      referrer: this.isOnReferrer() ? user : undefined,
    };
  }

  // Для P2P_ERRORS_DESCRIPTION
  onSteamErrorChange(event: Event): void {
    this.selectedSteamError.set((event.target as HTMLSelectElement).value as SteamErrorsEnum);
  }
}
