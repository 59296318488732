<div class="tw-flex tw-flex-col tw-gap-2">
  @if (wearType(); as wearType) {
    <div class="tw-flex tw-whitespace-nowrap tw-text-sm tw-font-normal tw-gap-1">
      @if (isSouvenir()) {
        <span class="souvenir"> SV </span>
        @if (wearType) {
          |
        }
      }
      @if (isStatTrak()) {
        <span class="stat-track"> ST </span>
        @if (wearType) {
          |
        }
      }
      <span class="tw-text-gray-200">
        {{ shortWearType() }}
      </span>
    </div>
  }

  <div class="tw-text-[1.375rem] tw-font-medium tw-leading-[1.3] tw-text-gray-100 tw-w-11/12">
    {{ name() }}
  </div>

  @if (float(); as float) {
    <div class="tw-mt-[2px]">
      <div class="tw-w-full">
        <div
          class="dot tw-relative"
          [style.left.%]="quality()"
        ></div>
      </div>
      <div class="gradient-line tw-rounded-full"></div>
    </div>
    <span class="tw-text-xs tw-font-normal tw-text-gray-200">
      <app-float-value [float]="float" />
    </span>
  }
</div>
