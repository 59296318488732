import type { Signal } from '@angular/core';
import { afterNextRender, AfterRenderPhase, ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import type { Params } from '@angular/router';
import { RouterLink } from '@angular/router';
import type { IFilterFormContent, IInventoryShortInfo, ILocales, IUserDetailed, MarketSortingTypes } from '@dev-fast/types';
import { BrandComponentModule, SpinnerLoaderSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { DesktopAppActionMenuModule } from '@app/desktop-app/action-menu';
import { ButtonComponent } from '@app/ui/components/lib/button/button.component';
import { WidgetsNotificationsNewModule } from '@app/widgets/notifications-new';

import { CategoryMenuComponent } from './category-menu/category-menu.component';
import { SearchInputComponent } from './search-input/search-input.component';
import type { HeaderEngine } from './symbols';
import { HEADER_ENGINE } from './symbols';

@Component({
  selector: 'app-new-market-header',
  standalone: true,
  imports: [
    BrandComponentModule,
    MatIcon,
    CurrencyComponent,
    DesktopAppActionMenuModule,
    SpinnerLoaderSAComponent,
    TranslateModule,
    WidgetsNotificationsNewModule,
    ButtonComponent,
    SearchInputComponent,
    CategoryMenuComponent,
    RouterLink,
  ],
  templateUrl: './new-market-header.component.html',
  styleUrl: './new-market-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewMarketHeaderComponent {
  readonly #headerEngine: HeaderEngine = inject(HEADER_ENGINE);

  readonly locales: Signal<ILocales[] | undefined> = toSignal(this.#headerEngine.locales$);
  readonly currentLocale: Signal<ILocales | undefined> = toSignal(this.#headerEngine.currentLocale$);
  readonly user: Signal<IUserDetailed | null | undefined> = toSignal(this.#headerEngine.user$);
  readonly canLevelUp: Signal<boolean | undefined> = toSignal(this.#headerEngine.canLevelUp$);
  readonly userBalance: Signal<number | undefined> = toSignal(this.#headerEngine.userBalance$);
  readonly inventoryShortInfo: Signal<IInventoryShortInfo | undefined> = toSignal(this.#headerEngine.inventoryShortInfo$);
  readonly marketFilters: Signal<IFilterFormContent<MarketSortingTypes> | undefined> = toSignal(this.#headerEngine.marketFilters$);
  readonly marketQuery: Signal<string | undefined> = computed(() => this.marketFilters()?.query);

  readonly showOnClient = signal(false);

  categoriesMenuOpened = false;

  constructor() {
    afterNextRender(
      () => {
        this.showOnClient.set(true);
      },
      { phase: AfterRenderPhase.Write },
    );
  }

  onLogin = (): void => this.#headerEngine.login();
  changeQuery = (query: Params): void => this.#headerEngine.changeMarketFilters(query, 'merge');
}
