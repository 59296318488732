import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import type { IUserSelfExclusionData } from '@dev-fast/types';
import { CountdownComponentModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { CommonStoreService } from '@app/core-state-common';

@Component({
  selector: 'app-self-exclusion-modal',
  templateUrl: './self-exclusion-modal.component.html',
  styleUrls: ['./self-exclusion-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, UpperCasePipe, TranslateModule, CountdownComponentModule],
})
export class SelfExclusionModalComponent {
  readonly dialogRef = inject(MatDialogRef<SelfExclusionModalComponent>);
  readonly commonService = inject(CommonStoreService);
  readonly data = inject(MAT_DIALOG_DATA);

  payload: IUserSelfExclusionData | null = this.data;
  time = 59000;

  constructor() {
    this.#confirmSelfBlock();
  }

  close(): void {
    this.dialogRef.close();
  }

  sendConfirmAgain(): void {
    this.time = 59000;
    this.#confirmSelfBlock();
  }

  #confirmSelfBlock(): void {
    if (this.payload) {
      this.commonService.demandSelfExclusion(this.payload.duration);
      this.time = 59000;
    }
  }
}
