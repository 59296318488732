import type { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ClassicBackendService } from '@dev-fast/backend-services';
import type {
  EClassicPhase,
  EClassicViewMode,
  IClassicDepositConfig,
  IClassicHistory,
  IClassicPlayerConfig,
  IClassicWidgetConfig,
  IClassicWinnerConfig,
  IGameBetResponseDto,
  ITradeItem,
  TClassicHistoryMode,
  Trade,
  TradeOriginalItem,
} from '@dev-fast/types';
import { CrossServices, EClassicHistoryMode, NotificationCategory } from '@dev-fast/types';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { reduce, sumBy } from 'lodash-es';
import type { Observable } from 'rxjs';
import { catchError, switchMap, tap, throwError } from 'rxjs';

import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { GamesState, SuccessfulBid } from '@app/core/state/games-store';
import { GetInventoryInfo } from '@app/core/state/inventory';
import type { UserStateModel } from '@app/core/state/user-store';
import { UserState } from '@app/core/state/user-store';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import {
  AddBet,
  AddBetSuccess,
  ChangePhase,
  ClearWinningsItems,
  GetHistory,
  GetState,
  GetUserWinningsItems,
  ResetPartialState,
  ToggleViewMode,
  UpdatePartialState,
} from './classic.actions';
import { getPhase, getPlayers, getWinnerPlayer, getWinnerTicket, getWinnerWeapon, mapStateResponse } from './classic.helpers';
import type { IClassicStateModel } from './classic.model';
import { CLASSIC_INITIAL_STATE } from './classic.model';
import { ClassicSocketsState } from './substates/classic-sockets';

@State<IClassicStateModel>({
  children: [ClassicSocketsState],
  defaults: CLASSIC_INITIAL_STATE,
  name: 'classic',
})
@Injectable()
export class ClassicState {
  /** Возвращает массив данных о сделанных ставках */
  @Selector()
  static deposits({ players, prize, trades }: IClassicStateModel): IClassicDepositConfig[] {
    return trades
      ? trades.map((trade) => {
        const items = trade.items[CrossServices.CSGO];
        const chance = parseFloat(
          ((items.reduce((priceSum: number, { price }: ITradeItem) => priceSum + price, 0) / prize) * 100).toFixed(1),
        );
        const player = players.find(({ id }) => id === trade.playerId) as IClassicPlayerConfig;

        return {
          chance,
          id: trade.id,
          items,
          player,
          tickets: trade.tickets,
          totalPrice: trade.totalPrice,
        };
      })
      : [];
  }

  @Selector()
  static winningItems({ winningItems }: IClassicStateModel): TradeOriginalItem[] {
    return winningItems;
  }

  /** Возвращает массив данных истории */
  @Selector()
  static history({ history }: IClassicStateModel): IClassicHistory[] {
    return history;
  }

  /** Возвращает текущий режим просмотра истории */
  @Selector()
  static historyMode({ historyMode }: IClassicStateModel): TClassicHistoryMode {
    return historyMode;
  }

  /** Возвращает данные текущего авторизованного пользователя или null */
  @Selector([ClassicState, UserState])
  static me({ players }: IClassicStateModel, { user }: UserStateModel): IClassicPlayerConfig | null {
    const player = user ? players.find(({ id }) => id === user.id) : null;

    return player ?? null;
  }

  @Selector([ClassicState, ClassicState.me])
  static myTradeItems({ trades }: IClassicStateModel, me: IClassicPlayerConfig | null): TradeOriginalItem[] | null {
    const myTrade = me && trades ? trades.filter(({ playerId }) => playerId === me.id) : null;

    return myTrade && myTrade.length ? reduce(myTrade, (acc: TradeOriginalItem[], trade) => acc.concat(trade.original), []) : null;
  }

  /** Возвращает текущую фазу игры */
  @Selector()
  static phase({ phase }: IClassicStateModel): EClassicPhase | null {
    return phase;
  }

  /** Возвращает массив данных об участвующих в текущем раунде игроках */
  @Selector()
  static players({ players }: IClassicStateModel): IClassicPlayerConfig[] {
    return players;
  }

  /** Возвращает разыгрываемую в текущем раунде сумму */
  @Selector()
  static prize({ prize }: IClassicStateModel): number {
    return prize;
  }

  @Selector()
  static rafflingTimestampDiff({ rafflingTimestampDiff }: IClassicStateModel): number | null {
    return rafflingTimestampDiff;
  }

  /** Возвращает хэш текущего раунда */
  @Selector()
  static roundHash({ roundHash }: IClassicStateModel): string {
    return roundHash;
  }

  /** Возвращает порядковый номер текущего раунда */
  @Selector()
  static roundNumber({ roundNumber }: IClassicStateModel): number {
    return roundNumber;
  }

  /** Возвращает secret текущего раунда */
  @Selector()
  static roundSecret({ roundSecret }: IClassicStateModel): string | null {
    return roundSecret;
  }

  /** Возвращает массив ссылок на аватары игроков текущего раунда */
  @Selector()
  static tape({ tape }: IClassicStateModel): string[] | null {
    return tape;
  }

  /** Возвращает общее количество предметов, поставленных в текущем раунде */
  @Selector()
  static totalItemsNumber({ players }: IClassicStateModel): number {
    return sumBy(players, 'itemsNumber');
  }

  /** Возвращает массив данных о сделанных в текущем раунде ставках */
  @Selector()
  static trades({ trades }: IClassicStateModel): Trade[] | null {
    return trades;
  }

  /** Возвращает текущий режим просмотра сделанных ставок */
  @Selector()
  static viewMode({ viewMode }: IClassicStateModel): EClassicViewMode {
    return viewMode;
  }

  /** Возвращает массив данных для виджетов */
  @Selector([ClassicState])
  static widgets(state: IClassicStateModel | undefined): IClassicWidgetConfig[] {
    if (!state || !state.lastWinner || !state.luckyPlayer || !state.biggestBet) {
      return [];
    }

    return [
      {
        color: '#01a9ff',
        data: state.lastWinner[0],
        gradient: ['#00a3ff', '#01d1ff'],
        title: 'Last winner',
      },
      {
        color: '#26d93c',
        data: state.luckyPlayer[0],
        gradient: ['#189d03', '#23ff00'],
        title: 'Luck of the day',
      },
      {
        color: '#ffd941',
        data: state.biggestBet[0],
        gradient: ['#9d5603', '#ffd941'],
        title: 'Biggest bet',
      },
    ];
  }

  /** Возвращает информацию о победителе текущего раунда или null */ //TODO:
  @Selector()
  static winner({ players, prize, roundSecret, trades, winnerId }: IClassicStateModel): IClassicWinnerConfig | null {
    const ticketNumber = getWinnerTicket(prize, roundSecret);
    const winner = getWinnerPlayer(players, winnerId);
    if (!winner) {
      return null;
    }
    const winnerWeapon = ticketNumber && trades && trades.length ? getWinnerWeapon(ticketNumber, trades) : null;

    return winnerId ? { ...winner, ticketNumber, winnerWeapon } : null;
  }

  readonly #classicBackendService = inject(ClassicBackendService);
  readonly #isServer: boolean = inject(IS_SERVER_TOKEN);
  readonly #localStorageService = inject(LocalStorageService);
  readonly #notificationsService = inject(NotificationsService);
  readonly #store = inject(Store);

  ngxsOnInit(stateContext: StateContext<IClassicStateModel>): void {
    const { patchState } = stateContext;

    // TODO: возможно стоит завести какой-то комплексный объект с параметрами для различных игр?
    const classicTypeView = this.#localStorageService.get('classicTypeView');

    if (classicTypeView) {
      patchState({ viewMode: classicTypeView });
    }
  }

  @Action(AddBet)
  addBet({ dispatch }: StateContext<IClassicStateModel>, { bet }: AddBet): Observable<IGameBetResponseDto | void> {
    return this.#classicBackendService.addBet(bet).pipe(
      switchMap((response) => dispatch([new GetInventoryInfo(), new AddBetSuccess(response), new SuccessfulBid('classic')])),
      catchError((error) => this.#handleError(error, 'ERROR.CLASSIC_SERVICE_NEW.NOTHING_BET')),
    );
  }

  // Изменяет фазу игры
  @Action(ChangePhase)
  changePhase({ getState, patchState }: StateContext<IClassicStateModel>, { newPhase }: ChangePhase): void {
    const { phase } = getState();

    if (phase !== newPhase) {
      patchState({ phase: newPhase });
    }
  }

  @Action(GetState)
  getState(stateContext: StateContext<IClassicStateModel>): Observable<Partial<IClassicStateModel> | void> {
    const { patchState } = stateContext;

    return this.#classicBackendService.getState().pipe(
      tap((response) => {
        const currentGameSettings = this.#store.selectSnapshot(GamesState.currentGameSettings);
        const mappedResponse = mapStateResponse(response);
        const partialState: Partial<IClassicStateModel> = {};

        if (!this.#isServer) {
          partialState.phase = getPhase(stateContext, mappedResponse.rafflingTimestampDiff, currentGameSettings);
        }

        // Инициализирует состояние игроков текущего раунда только при наличии ненулевых prize и trades в ответе api
        if (mappedResponse.prize && mappedResponse.trades) {
          partialState.players = getPlayers(mappedResponse.prize, mappedResponse.trades);
        }

        patchState({
          ...mappedResponse,
          ...partialState,
        });
      }),
      catchError((error) => this.#handleError(error)),
    );
  }

  @Action(GetHistory)
  getHistory({ getState, patchState }: StateContext<IClassicStateModel>): Observable<IClassicHistory[] | void> {
    const { historyMode } = getState();

    const my = historyMode === EClassicHistoryMode.MY;

    return this.#classicBackendService.getHistory(my).pipe(
      tap((history) => patchState({ history })),
      catchError((error) => this.#handleError(error)),
    );
  }

  @Action(ResetPartialState)
  resetPartialState({ patchState }: StateContext<IClassicStateModel>, { propertiesToClear }: ResetPartialState): void {
    const patchStateVal = propertiesToClear.reduce(
      (val: Partial<IClassicStateModel>, property) => ({ ...val, [property]: CLASSIC_INITIAL_STATE[property] }),
      {},
    );

    patchState(patchStateVal);
  }

  // Переключает режим просмотра ставок
  @Action(ToggleViewMode)
  toggleViewMode({ patchState }: StateContext<IClassicStateModel>, { newViewMode }: ToggleViewMode): void {
    patchState({ viewMode: newViewMode });

    this.#localStorageService.set('classicTypeView', newViewMode);
  }

  // Обновляет состояние частично на основании данных сокета
  @Action(UpdatePartialState)
  updatePartialState({ getState, patchState }: StateContext<IClassicStateModel>, { partialResponse }: UpdatePartialState): void {
    const { trades } = getState();

    const mappedResponse = mapStateResponse(partialResponse);
    const partialState: Partial<IClassicStateModel> = {};

    // Добавляет trades полученным ранее при наличии ненулевых prize и trades в ответе api
    // (приходят ненулевые от participants сокета при добавлении к текущему раунду нового игрока)
    // (приходит всегда массив из одного последнего trade)
    if (mappedResponse.prize && mappedResponse.trades) {
      const [newTrade] = mappedResponse.trades;
      const isDuplicate = trades?.some(({ betId }) => betId === newTrade.betId);

      partialState.trades = trades ?? [];

      if (!isDuplicate) {
        partialState.trades = [...partialState.trades, newTrade];
        partialState.players = getPlayers(mappedResponse.prize, partialState.trades);
      }
    }

    // Обновляет состояние текущего раунда до начального при нулевом trades
    // (приходит нулевой от game сокета в конце текущего раунда)
    if (mappedResponse.trades === CLASSIC_INITIAL_STATE.trades) {
      partialState.players = CLASSIC_INITIAL_STATE.players;
      partialState.rafflingTimestampDiff = CLASSIC_INITIAL_STATE.rafflingTimestampDiff;
    }

    patchState({
      ...mappedResponse,
      ...partialState,
    });
  }

  @Action(GetUserWinningsItems)
  getUserWinningsItems(stateContext: StateContext<IClassicStateModel>, { winningItems }: GetUserWinningsItems): void {
    const { patchState } = stateContext;
    patchState({
      winningItems,
    });
  }

  @Action(ClearWinningsItems)
  clearTotalSum({ patchState }: StateContext<IClassicStateModel>): void {
    patchState({ winningItems: [] });
  }

  #handleError(error: HttpErrorResponse, optMsg = 'Error in Classic game'): Observable<void> {
    this.#notificationsService.addErrorNotification(
      error.error && error.error.message ? error.error.message : typeof error.error === 'string' ? error.error : optMsg,
      { category: NotificationCategory.GAME },
    );

    return throwError(() => error);
  }
}
