import type { Routes } from '@angular/router';
import { APP_PATHS } from '@dev-fast/types';
import { NoIndexComponent, PlugComponent } from '@dev-fast/ui-components';

import { IsAuthGuard } from '@app/auth';
import {
  AutoGameActivateChildGuard,
  AutoGameActivateGuard,
  AutoGameDeactivateGuard,
  BanActivateGuard,
  OnlyDevGuard,
  OnlyServerGuard,
} from '@app/core/guards';
import { SeoType } from '@app/core/seo-service';
import { DebugMenuComponent } from '@app/debug';

export const routes: Routes = [
  {
    path: APP_PATHS.root,
    children: [
      {
        path: APP_PATHS.root,
        loadChildren: () => import('@app/modules/p2p-new').then((modules) => modules.P2pNewModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
        data: { seo: 'MARKET' },
      },

      // ИГРЫ
      {
        // Case-battle
        path: APP_PATHS.games.caseBattle.base,
        canActivate: [BanActivateGuard],
        loadChildren: () =>
          import('@app/games/lib/case-battle/battle-market/battle-market.routes').then(({ routes: caseBattleRoutes }) => caseBattleRoutes),
      },
      {
        // Cases
        path: APP_PATHS.games.cases.base,
        canActivate: [BanActivateGuard],
        loadChildren: () => import('@app/games/lib/cases/cases-market/cases-market.routes').then(({ routes: casesRoutes }) => casesRoutes),
      },
      {
        path: APP_PATHS.userStore,
        loadChildren: () =>
          import('@app/modules/p2p-new/lib/pages/user-store/user-store.routes').then(({ userStoreRoutes }) => userStoreRoutes),
      },
      {
        // страница заглушка для пререндера неважных сео страниц
        path: 'noindex',
        canActivate: [OnlyServerGuard],
        canLoad: [OnlyServerGuard],
        component: NoIndexComponent,
      },
      {
        path: APP_PATHS.store,
        redirectTo: APP_PATHS.root,
      },
      {
        path: 'tos',
        data: { seo: 'TAC_MARKET', seoType: [SeoType.TITLE], noAlternative: true },
        loadChildren: () => import('@app/modules/tos-market').then((modules) => modules.TosMarketModule),
      },
      {
        path: 'privacy-policy',
        data: { seo: 'PRIVACY_POLICY_MARKET', seoType: [SeoType.TITLE], noAlternative: true },
        loadChildren: () => import('@app/modules/privacy-policy-market').then((modules) => modules.PrivacyPolicyMarketModule),
      },
      {
        path: 'cookie-policy',
        data: { seo: 'COOKIE_MARKET', seoType: [SeoType.TITLE], noAlternative: true },
        loadChildren: () => import('@app/modules/cookie-policy-market').then((modules) => modules.CookiePolicyMarketModule),
      },
      {
        path: 'balance/withdrawal',
        redirectTo: 'withdrawal/method',
        pathMatch: 'full',
      },
      {
        path: 'buy-coins',
        redirectTo: 'refill',
        pathMatch: 'full',
      },
      {
        path: 'cases',
        redirectTo: 'game/cases',
        pathMatch: 'full',
      },
      {
        path: 'classic',
        redirectTo: 'game/classic',
        pathMatch: 'full',
      },
      {
        path: 'fast',
        redirectTo: 'game/fast',
        pathMatch: 'full',
      },
      {
        path: 'double',
        redirectTo: 'game/double',
        pathMatch: 'full',
      },
      {
        path: 'x50',
        redirectTo: 'game/x50',
        pathMatch: 'full',
      },
      {
        path: 'slot',
        redirectTo: 'game/slot',
        pathMatch: 'full',
      },
      {
        path: 'personal',
        redirectTo: 'account',
        pathMatch: 'full',
      },
      {
        path: 'personal/balance',
        redirectTo: 'account/balance',
        pathMatch: 'full',
      },
      {
        path: 'market/inventory',
        redirectTo: 'inventory',
        pathMatch: 'full',
      },
      {
        path: 'faucet',
        redirectTo: 'bonuses',
        pathMatch: 'full',
      },
      {
        path: 'support',
        redirectTo: 'game',
        pathMatch: 'full',
      },
      {
        path: 'support/requests',
        redirectTo: 'game',
        pathMatch: 'full',
      },
      {
        path: 'faq',
        loadChildren: () => import('@app/modules/faq-home').then((modules) => modules.FaqHomeModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      {
        path: 'inventory',
        loadChildren: () => import('@app/modules/inventory').then((modules) => modules.InventoryModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, IsAuthGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      {
        path: 'fail-payment',
        children: [
          {
            path: ':id',
            redirectTo: '/(modal:fail-payment/:id)',
          },
          {
            path: '**',
            redirectTo: '/',
          },
        ],
      },
      {
        path: 'success-payment',
        children: [
          {
            path: ':id',
            redirectTo: '/(modal:success-payment/:id)',
          },
          {
            path: '**',
            redirectTo: '/',
          },
        ],
      },

      {
        path: 'skins',
        loadChildren: () => import('@app/modules/skins-new').then(({ routes: skinPageRoutes }) => skinPageRoutes),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      {
        path: 'account',
        loadChildren: () => import('@app/modules/account').then((modules) => modules.AccountModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, IsAuthGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },

      {
        path: 'block',
        component: PlugComponent,
      },
      {
        path: 'best-offers',
        loadComponent: () => import('@app/modules/best-offers-page').then((m) => m.BestOffersPageComponent),
      },
      {
        path: 'debug',
        component: DebugMenuComponent,
        canActivate: [OnlyDevGuard],
      },
      {
        path: 'tickets',
        loadComponent: () => import('@app/modules/tickets').then((m) => m.TicketsComponent),
      },
    ],
  },
];
