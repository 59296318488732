import { inject, Injectable } from '@angular/core';
import { ModalModel, ModalNames } from '@dev-fast/types';

import { ModalsService } from '@app/core/modal-service';

import { ClassicFairGameModalComponent } from '../modals/classic-fair-game-modal/classic-fair-game-modal.component';
import { ClassicVictoryModalComponent } from '../modals/classic-victory-modal/classic-victory-modal.component';

@Injectable()
export class ClassicModalsService {
  #commonModalsService = inject(ModalsService);

  #modals: ModalModel[] = [
    {
      component: ClassicFairGameModalComponent,
      config: {
        backdropClass: 'classic-fair-game-modal-backdrop',
        panelClass: 'classic-fair-game-modal-panel',
      },
      name: ModalNames.FAIR_GAME,
    },
    {
      component: ClassicVictoryModalComponent,
      config: {
        backdropClass: 'classic-victory-modal-backdrop',
        panelClass: 'classic-victory-modal-panel',
      },
      name: ModalNames.VICTORY,
    },
  ];

  registerModals(): void {
    this.#commonModalsService.registerModals(this.#modals);
  }
}
