import { KeyValuePipe } from '@angular/common';
import type { AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, inject, signal, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import type { ICaseItemDtoV2 } from '@dev-fast/types';
import Flicking from '@egjs/flicking';
import { Perspective, Sync } from '@egjs/flicking-plugins';
import type { FlickingOptions, NgxFlickingComponent, Plugin, WillChangeEvent } from '@egjs/ngx-flicking';
import { NgxFlickingModule } from '@egjs/ngx-flicking';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';

import { LangRouterModule } from '@app/shared/directives';
import { CaseCardComponentModule } from '@app/ui/components/index';

@Component({
  selector: 'app-bonus-case',
  templateUrl: './bonus-case.component.html',
  styleUrls: ['./bonus-case.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    KeyValuePipe,
    LangRouterModule,
    TranslateModule,
    MatDialogModule,
    MatIconModule,
    CaseCardComponentModule,
    NgxFlickingModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BonusCaseModalComponent implements AfterViewInit {
  // Инжекторы
  readonly #dialogRef = inject(MatDialogRef<BonusCaseModalComponent>);
  readonly payload = inject<{
    allBonusCases: Record<number, number>;
    casesDictionary: Record<number, ICaseItemDtoV2[]>;
  }>(MAT_DIALOG_DATA);

  // Сигналы
  readonly changedCaseItemId = signal<string | undefined>(undefined);
  readonly isStartAnimation = signal(true);
  readonly isStartDuration = signal(true);

  readonly casesIds = computed(() => Object.keys(this.caseItems()));
  readonly defaultIndex = computed(() => Math.floor(this.casesIds().length / 2));
  readonly caseItems = computed<Record<number, ICaseItemDtoV2[]>>(() => {
    return Object.entries(this.payload.allBonusCases).reduce((acc, [caseId, casesAmount]) => {
      const caseItem = this.payload.casesDictionary[+caseId];
      return { ...acc, [+caseId]: new Array(casesAmount > 7 ? 7 : casesAmount).fill(caseItem) };
    }, {});
  });

  readonly uniqCasesAmount = computed(() => Object.keys(this.caseItems()).length);

  readonly flicking = viewChild(Flicking);
  readonly currentPanelIndex = toSignal(of(this.flicking()?.currentPanel.index));

  ngAfterViewInit(): void {
    this.setDefaultCase();

    setTimeout(() => {
      this.isStartAnimation.set(false);
    }, 100);

    setTimeout(() => {
      this.isStartDuration.set(false);
    }, 700);
  }

  flickingPlugins(flicking: NgxFlickingComponent): Plugin[] {
    return [
      new Perspective({ rotate: 0.1, scale: 1, perspective: 1000 }),
      new Sync({
        type: 'index',
        synchronizedFlickingOptions: [
          {
            flicking: flicking,
            isClickable: true,
            isSlidable: false,
          },
        ],
      }),
    ];
  }

  setDefaultCase(): void {
    const defaultIndex = this.defaultIndex();
    const id = this.casesIds()[defaultIndex];

    this.changedCaseItemId.set(id);
  }

  flickingOptions(): Partial<FlickingOptions> {
    const defaultIndex = this.defaultIndex();

    return {
      circular: true,
      defaultIndex,
      disableOnInit: this.uniqCasesAmount() === 1,
      align: 'center',
    };
  }

  close(): void {
    this.#dialogRef.close();
  }

  cardsRotation(index: number, itemsAmount: number): Record<string, string> {
    const rotate = (itemsAmount / index) % 2 === 0 ? index * -1 : index;

    return {
      transform: `rotate(${rotate * 2}deg)`,
      'z-index': `${index === 0 ? 8 : index}`,
    };
  }

  isNeedDuration(index: number): boolean {
    const defaultIndex = this.defaultIndex();

    return index !== defaultIndex;
  }

  startTranslate(index: number): string {
    const defaultIndex = this.defaultIndex();

    if (index === defaultIndex) {
      return '';
    }

    const shift = defaultIndex - index;
    const dir = shift > 0 ? 'R' : 'L';
    const shiftAbs = Math.abs(shift);

    return `--translate${dir}${shiftAbs}`;
  }

  changeCaseItem(e: WillChangeEvent<NgxFlickingComponent>): void {
    const panelIndex = e.index;
    const id = this.casesIds()[panelIndex];

    this.changedCaseItemId.set(id);
  }
}
