import { ChangeDetectionStrategy, Component, computed, effect, inject, model } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollCommonService } from '@app/core/scroll-service/scroll-common.service';
import { ButtonComponent } from '@app/ui/components/lib/button/button.component';

import type { IDropdownConfigItem } from './category-menu.const';
import { DROPDOWN_CONFIG } from './category-menu.const';
import type { CategoriesFilter } from './dropdown/dropdown.component';
import { CategoriesDropdownComponent } from './dropdown/dropdown.component';

@Component({
  selector: 'app-category-menu',
  standalone: true,
  templateUrl: './category-menu.component.html',
  styleUrl: './category-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TranslateModule, MatIcon, CategoriesDropdownComponent],
})
export class CategoryMenuComponent {
  readonly #scrollCommonService = inject(ScrollCommonService);

  readonly items = computed<CategoriesFilter[] | null>(() => this.generateFilters());
  readonly categoriesMenuOpened = model<boolean>(false);

  constructor() {
    effect(() => {
      this.#handleShowScroll(this.categoriesMenuOpened());
    });
  }

  generateFilters = (): CategoriesFilter[] | null => {
    const filters: CategoriesFilter[] | null = [];
    DROPDOWN_CONFIG.forEach((configItem) => {
      filters.push({
        label: configItem.mainCategory.label,
        key: configItem.mainCategory.category?.key || '',
        value: configItem.mainCategory.category?.value || '',
        icon: configItem.mainCategory.icon,
        items: this.#generateCategory(configItem),
      });
    });
    return filters;
  };

  toggleCategoriesMenu = (): void => {
    this.categoriesMenuOpened.set(!this.categoriesMenuOpened());
  };

  #generateCategory(configItem: IDropdownConfigItem): CategoriesFilter[] {
    if (configItem.subcategories) {
      return this.#getSubcategory(configItem as Required<IDropdownConfigItem>);
    }

    return this.#getMainCategory(configItem as Required<IDropdownConfigItem>);
  }

  #getSubcategory(configItem: Required<IDropdownConfigItem>): CategoriesFilter[] {
    const { subcategories, mainCategory } = configItem;

    return subcategories
      .filter((subcategoryItem) => !!subcategoryItem.items)
      .map((subcategoryItem) => {
        const { label, category, items: subItems = [], type } = subcategoryItem;
        const { key = '', value = '' } = category || {};
        const items: CategoriesFilter[] = subItems.map((categoryItem) => ({
          // FIXME пересмотреть поля для фронта, уменьшить т.к. дублирование ненужное
          // FIXME Вообще это все можно объединить в один метод, переделать вместе с фиксом
          value: categoryItem,
          label: categoryItem,
          icon: categoryItem,
          key: type?.key,
        }));

        return {
          label,
          key,
          value,
          icon: mainCategory.icon,
          items,
        };
      });
  }

  #getMainCategory(configItem: Required<IDropdownConfigItem>): CategoriesFilter[] {
    const { mainCategory } = configItem;

    return (
      mainCategory.items?.map((categoryItem) => ({
        // FIXME пересмотреть поля для фронта, уменьшить т.к. дублирование ненужное
        value: categoryItem,
        label: categoryItem,
        icon: categoryItem,
        key: mainCategory.type?.key,
      })) ?? []
    );
  }

  #handleShowScroll(isOpened: boolean): void {
    isOpened ? this.#scrollCommonService.blockScroll() : this.#scrollCommonService.unblockScroll();
  }
}
