@for (item of item()?.items; track item.id) {
  <app-ui-product-card
    [images]="getItemImageSrc(item)"
    [price]="getKitPrice(item)"
    [discount]="getDiscount(item)"
    [wearType]="getWearType(item)"
    [wear]="getItemWear(item)"
    [phaseNumber]="getItemPhase(item)"
    [type]="getItemType(item)"
    [name]="getItemName(item)"
    [badges]="getItemBadges(item)"
    [class]="[getItemBackgroundClass(item), getItemDiscountClass(item), getItemPhaseClass(item), getSelectedClass(item)]"
    [options]="{ hasSelectProduct: true, canShowInfoButton: false }"
    (onProduct)="onSelectItem(item)"
  >
  </app-ui-product-card>
}
