export class SubscribeClassicHistorySockets {
  static readonly type = '[Classic Sockets] Subscribe Classic History Sockets';
}

export class SubscribeClassicSockets {
  static readonly type = '[Classic Sockets] Subscribe Classic Sockets';
}

export class UnsubscribeClassicHistorySockets {
  static readonly type = '[Classic Sockets] Unsubscribe Classic History Sockets';
}

export class UnsubscribeClassicSockets {
  static readonly type = '[Classic Sockets] Unsubscribe Classic Sockets';
}
