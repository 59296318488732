// export const playersRouletteColors = [
//   '41b32f', '30d8d8', 'b3792c', 'b22227', '942bb6', 'd62026', '432bd5', 'd6258c', '08e45a',
//   '2bb7b8', 'a02428', 'a1702c', '87a431', 'bc2bd8', '26956e', '382aa6', '2158a4', 'a02772',
//   '29a6a8', '6a282d', '317130', '5a2a76', '2c8e5d', '2e2a76', 'b2267b', '6a2958', '247377',
//   '214675', 'd78c2d', '71842b', 'b2267b', '432bd5', '96b531', '2369d3', 'd6258c', '30d8d8',
//   '8d252a', '8e662d', '799430', '225db3', '782a96', '352a95', '2ba05e', '215294', '8d286a',
//   '5b7230', '279597', '6a552f', '30c56c', '3b2ab6', '38922f'];

export const playersRouletteColors: string[] = [
  '41b32f',
  '30d8d8',
  'b3792c',
  'b22227',
  '942bb6',
  'd62026',
  '432bd5',
  'd6258c',
  '08e45a',
  '2bb7b8',
  'a02428',
  'a1702c',
  '87a431',
  'bc2bd8',
  '26956e',
  '382aa6',
  '2158a4',
  'a02772',
  '29a6a8',
  '6a282d',
  '41b32f',
  '30d8d8',
  'b3792c',
  'b22227',
  '942bb6',
  'd62026',
  '432bd5',
  'd6258c',
  '08e45a',
  '2bb7b8',
  'a02428',
  'a1702c',
  '87a431',
  'bc2bd8',
  '26956e',
  '382aa6',
  '2158a4',
  'a02772',
  '29a6a8',
  '6a282d',
  // '00ACC1', '546E7A', '7CB342', 'C0CA33', '1976D2', '388E3C', 'FFB300', '5D4037', '039BE5',
  // 'FFA000', 'D32F2F', '0288D1', '00796B', '0097A7', 'F57C00', '757575', '3949AB', '00796B',
  // 'E53935', '7CB342', '00897B', 'FFA000', '00897B', 'D81B60', '7B1FA2', '512DA8', 'FBC02D',
  // 'FFB300', '1E88E5', 'E64A19', '5D4037', '3949AB', '00ACC1', 'AFB42B', '8E24AA', '43A047',
  // '546E7A', 'D81B60', '616161', 'F57C00', '5E35B1', '616161', '1976D2', 'FDD835', 'FB8C00',
  // 'E64A19', 'C2185B', 'D32F2F', '455A64', '757575', 'FDD835', '388E3C', 'C0CA33', '455A64',
  // '7B1FA2', '6D4C41', '1E88E5', '5E35B1', '8E24AA', '303F9F', 'F4511E', '6D4C41', '689F38',
  // '43A047', '0097A7', '512DA8', 'E53935', 'C2185B', '0288D1', 'FB8C00', '039BE5', 'F4511E',
  // 'FBC02D', 'AFB42B', '303F9F', '689F38',
];

export const getPlayerColorById = (id: number): string => {
  const color = playersRouletteColors[id % playersRouletteColors.length];
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  return `${r}, ${g}, ${b}`;
};

export const getPlayerColor = (index: number): string => {
  const color = playersRouletteColors[index];
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  return `${r}, ${g}, ${b}`;
};

export const calculateItemPosition = (offset: number, i: number): { left: number; top: number } => {
  offset = offset < 900 ? 900 : offset;
  const itemsInRow = Math.floor(offset / 128);
  // 48 - отступ, 12 - grid-gap слева и справа, отсекаеися.
  const gap = itemsInRow * 12 - (12 * 2 - 48 - 48);
  const result = Math.floor((offset - gap) / 128);
  return {
    left: (i % result) * 136 + (i % result) * 4,
    top: -Math.floor(i / result) * 136 - Math.floor(i / result) * 4,
  };
};

export const WINNER_SPACE_POSITION = 25;

export const PLAYERS_LENT_LENGTH = 64;

export const WINNER_TAPE_POSITION = 45;
