import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import type { INotifyData } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { AppCurrencyModule } from '@app/shared/pipes';

import { TransactionsDialogComponent } from './transactions-dialog/transactions-dialog.component';

@Component({
  selector: 'app-transactions-notification-modal-layout',
  templateUrl: './transactions-notification-modal-layout.component.html',
  styleUrls: ['./transactions-notification-modal-layout.component.scss'],
  standalone: true,
  imports: [MatIconModule, TranslateModule, AppCurrencyModule, TransactionsDialogComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsNotificationModalComponent {
  readonly dialogRef = inject(MatDialogRef<TransactionsNotificationModalComponent>);
  readonly data: INotifyData | undefined = inject(MAT_DIALOG_DATA);

  refillData: INotifyData | undefined = this.data;

  closeModal(): void {
    this.dialogRef.close();
  }
}
