<div class="tw-flex tw-flex-col tw-mt-5 tw-p-6 tw-bg-gray-500 tw-rounded-xl tw-gap-4 tw-w-[500px]">
  <mat-tab-group class="tabs-default">
    <mat-tab label="Modals">
      <div class="tw-mt-3 tw-flex tw-flex-col tw-gap-4">
        @if (options.length) {
          <div class="tw-flex tw-gap-4">
            <app-ui-selection-menu
              class="price-select tw-flex-grow"
              [formClass]="'ui-form'"
              [panelClass]="'ui-menu'"
              [selectionItems]="{ items: options, activeIndex: 0 }"
              (activeTabChanged)="onModalSelect($event)"
            ></app-ui-selection-menu>

            <button
              class="tw-bg-yellow-500 tw-px-6 tw-py-2 tw-rounded-xl tw-text-gray-900"
              (click)="openModal()"
            >
              Open
            </button>
          </div>
          @switch (selectedModal()) {
            @case ([modalNames.VICTORY, modalNames.CASE_BATTLE_WINNERS].includes(selectedModal()) ? selectedModal() : '') {
              <div class="tw-flex tw-flex-col tw-gap-1 tw-p-2 tw-rounded-xl tw-border-[1px] tw-border-solid tw-border-yellow-500">
                <span>Количество выигранных предметов:</span>
                <input
                  class="tw-text-gray-900"
                  type="number"
                  max="100"
                  min="0"
                  name="viningItemsAmount"
                  [value]="viningItemsAmount()"
                  (change)="viningItemsAmountChange($event)"
                />
              </div>
            }
            @case (modalNames.TRANSACTION_NOTIFICATION) {
              <div class="tw-flex tw-flex-col tw-gap-1 tw-p-2 tw-rounded-xl tw-border-[1px] tw-border-solid tw-border-yellow-500">
                @for (status of transactionStatuses; track status) {
                  <label>
                    <input
                      type="radio"
                      name="transactionStatus"
                      [value]="status"
                      [checked]="selectedTransactionStatus() === status"
                      (change)="selectedTransactionStatus.set(status)"
                    />
                    {{ status }}
                  </label>
                }
              </div>
            }
            @case (modalNames.P2P_ERRORS_DESCRIPTION) {
              <div class="tw-flex tw-flex-col tw-gap-1 tw-p-2 tw-rounded-xl tw-border-[1px] tw-border-solid tw-border-yellow-500">
                @for (status of steamTradeStatuses; track status) {
                  <label>
                    <input
                      type="radio"
                      name="tradeStatus"
                      [value]="status"
                      [checked]="selectedTradeStatus() === status"
                      (change)="selectedTradeStatus.set(status)"
                    />
                    {{ status }}
                  </label>
                }
                @if (selectedTradeStatus() === steamTradeStatus.NEED_PERMISSIONS_ADD) {
                  <select
                    class="tw-text-gray-900"
                    [value]="selectedSteamError()"
                    (change)="onSteamErrorChange($event)"
                  >
                    <option [value]="undefined"></option>
                    @for (error of steamErrors; track error) {
                      <option [value]="error">
                        {{ error }}
                      </option>
                    }
                  </select>
                }
              </div>
            }
            @case (modalNames.REFERRAL) {
              <div class="tw-flex tw-flex-col tw-gap-2 tw-p-2 tw-rounded-xl tw-border-[1px] tw-border-solid tw-border-yellow-500">
                <div class="tw-flex tw-flex-col tw-gap-1">
                  <span>Промокод:</span>
                  <input
                    class="tw-text-gray-900"
                    type="text"
                    name="referralPromocode"
                    [value]="referralCode()"
                    (change)="referralCodeChange($event)"
                  />
                </div>

                <div class="tw-flex tw-flex-col tw-gap-1">
                  <span>Бонус в монетах или процентах:</span>
                  <input
                    class="tw-text-gray-900"
                    type="number"
                    min="0"
                    name="referralReward"
                    [value]="referralReward()"
                    (change)="referralRewardChange($event)"
                  />
                </div>

                <label>
                  <input
                    type="checkbox"
                    name="referrer"
                    [checked]="isOnReferrer()"
                    (change)="isOnReferrerChange($event)"
                  />
                  Включить отображение от кого промокод
                </label>

                <div class="tw-flex tw-flex-col tw-gap-1">
                  @for (type of referralTypes; track type) {
                    <label>
                      <input
                        type="radio"
                        name="tradeStatus"
                        [value]="type"
                        [checked]="selectedReferralType() === type"
                        (change)="selectedReferralType.set(type)"
                      />
                      {{ type }}
                    </label>
                  }
                </div>
              </div>
            }
            @case (modalNames.P2P_ERRORS_DESCRIPTION) {
              <div class="tw-flex tw-flex-col">
                @for (status of steamTradeStatuses; track status) {
                  <label>
                    <input
                      type="radio"
                      name="tradeStatus"
                      [value]="status"
                      [checked]="selectedTradeStatus() === status"
                      (change)="selectedTradeStatus.set(status)"
                    />
                    {{ status }}
                  </label>
                }
              </div>
            }
          }
          <div class="tw-flex tw-flex-col tw-gap-1">
            <span class="tw-text-lg">Описание:</span>
            <span class="tw-text-base"> {{ selectedDescription() }} </span>
          </div>
        } @else {
          <span class="tw-text-lg">Для этого проекта еще нет доступных для дебага модалок</span>
        }
      </div>
    </mat-tab>

    <mat-tab label="Notify">
      <div class="tw-mt-3 tw-flex tw-flex-col tw-gap-4">
        <div class="tw-flex tw-flex-col tw-gap-2 tw-p-2 tw-rounded-xl tw-border-[1px] tw-border-solid tw-border-yellow-500">
          <div class="tw-flex tw-flex-col tw-gap-1">
            @for (type of notifyTypes; track type) {
              <label>
                <input
                  type="radio"
                  name="notifyType"
                  [value]="type"
                  [checked]="selectedNotifyType() === type"
                  (change)="selectedNotifyType.set(type)"
                />
                {{ type }}
              </label>
            }
          </div>

          <div class="tw-flex tw-flex-col tw-gap-1">
            <span>Категория:</span>
            <input
              class="tw-text-gray-900"
              type="text"
              name="referralPromocode"
              [value]="notifyCategory()"
              (change)="notifyCategoryChange($event)"
            />
          </div>

          <div class="tw-flex tw-flex-col tw-gap-1">
            <span>Текст:</span>
            <textarea
              class="tw-text-gray-900"
              [value]="notifyMessage()"
              (input)="notifyMessageChange($event)"
            ></textarea>
          </div>
        </div>

        <button
          class="tw-bg-yellow-500 tw-px-6 tw-py-2 tw-rounded-xl tw-text-gray-900"
          (click)="callNotify()"
        >
          Call
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
