@if (
  {
    selectedItem: selectedItem()
  };
  as data
) {
  <div class="skin-modal-container tw-bg-gray-750 tw-flex tw-p-6 tw-relative tw-rounded-2xl tw-w-full">
    <button
      class="!tw-absolute tw-right-4 tw-top-4 tw-z-50 tw-w-8 tw-h-8 tw-rounded-full tw-bg-gray-750 tw-flex tw-items-center tw-justify-center"
      (click)="close()"
    >
      <mat-icon
        class="!tw-h-4 !tw-w-4"
        [svgIcon]="'close'"
      ></mat-icon>
    </button>
    @if (isMobile()) {
      <ng-container
        *ngTemplateOutlet="
          mobileView;
          context: {
            selectedItem: data.selectedItem
          }
        "
      />
    } @else {
      <ng-container
        *ngTemplateOutlet="
          desktopView;
          context: {
            selectedItem: data.selectedItem
          }
        "
      />
    }
  </div>
}

<ng-template
  #singleSkinView
  let-image="image"
  let-stickers="stickers"
  let-rarity="rarity"
>
  <div class="tw-flex tw-flex-col">
    <app-skin-viewer
      class="tw-flex tw-max-h-[30rem] tw-aspect-square"
      [skinImg]="image"
      [skinRarity]="rarity"
      [animate]="true"
    />
    <div class="tw-flex tw-gap-1">
      @for (sticker of stickers; track $index) {
        <app-p2p-sticker-image
          [sticker]="sticker"
          [index]="$index"
        />
      }
    </div>
  </div>
</ng-template>
<ng-template
  #mainInfoBlock
  let-selectedItem="selectedItem"
  let-isMobile="isMobile"
  let-isSingleSkin="isSingleSkin"
>
  <div
    class="tw-flex tw-flex-col tw-gap-10 tw-w-full md:tw-w-[min(28rem,calc(100vw-3rem))] tw-overflow-auto custom-scroll custom-scroll--hide"
  >
    <div class="tw-flex tw-flex-col tw-gap-6">
      <app-name-float-block
        [isSouvenir]="selectedItem.baseItem.isSouvenir"
        [isStatTrak]="selectedItem.baseItem.statTrak"
        [float]="selectedItem.float"
        [name]="skinName()"
        [wearType]="selectedItem.baseItem.exterior"
      />
      @if (isMobile) {
        <ng-container
          *ngTemplateOutlet="
            singleSkinView;
            context: {
              image: skinImage(),
              rarity: skinRarity(),
              stickers: selectedItem.stickers
            }
          "
        />
      }

      @if (isSingleSkin) {
        @if (mainItem(); as mainItem) {
          <app-price-action-block
            [isAuthorized]="isAuth()"
            [price]="mainItem.price"
            [overprice]="mainItemOverprice()"
            [dollarPrice]="mainItemDollarPrice()"
            [canBuy]="canBuyMainItem()"
            [isInTrade]="mainItemInCart()"
            [userBalance]="userBalance()"
            (addToCart)="onAddToCart(mainItem)"
            (removeFromCart)="onRemoveFromCart(mainItem)"
            (buyNow)="onBuyNow(mainItem)"
            (navigateToCart)="onNavigateToCart()"
            (refill)="onRefill()"
            (login)="onLogin()"
          />
        }
      } @else {
        <app-price
          [price]="selectedItem.price"
          [overprice]="selectedItemOverprice()"
          [dollarPrice]="selectedItemDollarPrice()"
        />
      }
    </div>
    <div class="tw-flex tw-flex-col tw-gap-10">
      <app-sih-chart
        class="tw-w-full"
        [item]="selectedItem"
        [loading]="sihChartIsLoading()"
        [sihDataset]="sihDataset()"
      />

      @if (
        {
          similarItems: similarItems$ | async
        };
        as data
      ) {
        <app-similar
          [items]="data.similarItems"
          [loading]="!data.similarItems"
          [selectedItemIds]="selectedItemIds()"
          (addToCart)="onAddToCart($event)"
          (removeFromCart)="onRemoveFromCart($event)"
          (showAllItems)="navigateToSkinPage(selectedItem)"
          (navigateToCart)="onNavigateToCart()"
        />
      }
    </div>

    @if (!isSingleSkin || (isSingleSkin && isMobile)) {
      <app-in-cases
        class="tw-flex"
        [isMobile]="isMobile"
        [cases]="cases$ | async"
        (openCase)="onOpenCase($event)"
        (showAllCases)="navigateToSkinPage(selectedItem)"
      />
    }
  </div>
</ng-template>
<ng-template #packSkinSelector>
  @if (mainItem(); as mainItem) {
    <div class="tw-flex tw-flex-col tw-gap-4 tw-overflow-auto custom-scroll custom-scroll--hide">
      <span class="tw-font-roboto tw-text-[1.375rem] tw-font-medium tw-leading-[1.7875rem] tw-text-gray-100">
        {{ 'P2P_SETTINGS.ITEM_DETAIL.PACK' | translate: { count: countItemsInPack() } }}
      </span>
      <app-price-action-block
        [isAuthorized]="isAuth()"
        [price]="mainItem.price"
        [overprice]="mainItemOverprice()"
        [dollarPrice]="mainItemDollarPrice()"
        [canBuy]="canBuyMainItem()"
        [isInTrade]="mainItemInCart()"
        [userBalance]="userBalance()"
        (addToCart)="onAddToCart(mainItem)"
        (removeFromCart)="onRemoveFromCart(mainItem)"
        (buyNow)="onBuyNow(mainItem)"
        (navigateToCart)="onNavigateToCart()"
        (refill)="onRefill()"
        (login)="onLogin()"
      />

      <app-pack-viewer
        [item]="mainItem"
        [selectedItem]="selectedItem()"
        (clickOnItem)="onItemInPack($event)"
      />
    </div>
  }
</ng-template>
<ng-template
  #mobileView
  let-selectedItem="selectedItem"
>
  <!--  <div class="tw-overflow-auto custom-scroll custom-scroll&#45;&#45;hide tw-flex tw-w-full">-->
  @if (isSingleSkin()) {
    <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full">
      @if (sellerInfo()) {
        <app-about-seller
          [percentage]="sellerRating()"
          [sellerName]="sellerName()"
          [isOnline]="sellerIsOnline()"
        />
      }
      <ng-container
        *ngTemplateOutlet="
          mainInfoBlock;
          context: {
            selectedItem: selectedItem,
            isMobile: true,
            isSingleSkin: true
          }
        "
      />
    </div>
  } @else {
    @if (!selectedItem) {
      <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        @if (sellerInfo()) {
          <app-about-seller
            [percentage]="sellerRating()"
            [sellerName]="sellerName()"
            [isOnline]="sellerIsOnline()"
          />
        }
        <ng-container *ngTemplateOutlet="packSkinSelector"></ng-container>
      </div>
    } @else {
      <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        <button
          class="tw-flex tw-gap-1 tw-items-center tw-mb-4 tw-text-sm tw-font-normal tw-text-gray-400"
          (click)="onBack()"
        >
          <mat-icon
            class="!tw-h-4 !tw-w-4"
            [svgIcon]="'arrow'"
          />
          {{ 'ACTIONS.BACK' | translate }}
        </button>
        <ng-container
          *ngTemplateOutlet="
            mainInfoBlock;
            context: {
              selectedItem: selectedItem,
              isMobile: true,
              isSingleSkin: false
            }
          "
        />
      </div>
    }
  }
  <!--  </div>-->
</ng-template>
<ng-template
  #desktopView
  let-selectedItem="selectedItem"
>
  <div class="tw-flex tw-gap-8 tw-w-full tw-justify-between">
    <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full">
      @if (sellerInfo()) {
        <app-about-seller
          [percentage]="sellerRating()"
          [sellerName]="sellerName()"
          [isOnline]="sellerIsOnline()"
        />
      }
      @if (isSingleSkin()) {
        @if (selectedItem) {
          <div class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-overflow-auto custom-scroll custom-scroll--hide">
            <ng-container
              *ngTemplateOutlet="
                singleSkinView;
                context: {
                  image: skinImage(),
                  rarity: skinRarity(),
                  stickers: selectedItem.stickers
                }
              "
            />

            <app-in-cases
              class="tw-flex"
              [isMobile]="false"
              [cases]="cases$ | async"
              (openCase)="onOpenCase($event)"
              (showAllCases)="navigateToSkinPage(selectedItem)"
            />
          </div>
        }
      } @else {
        <ng-container *ngTemplateOutlet="packSkinSelector"></ng-container>
      }
    </div>
    <div class="tw-flex tw-w-full tw-max-w-md md:tw-w-1/2">
      <ng-container
        *ngTemplateOutlet="
          mainInfoBlock;
          context: {
            selectedItem: selectedItem,
            isMobile: false,
            isSingleSkin: isSingleSkin()
          }
        "
      />
    </div>
  </div>
</ng-template>
