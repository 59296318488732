<div class="classic-fair-game-modal tw-flex tw-flex-col tw-gap-y-6 tw-w-[38rem] tw-p-8 tw-bg-gray-600 tw-rounded-2xl tw-text-gray-100">
  <div class="tw-flex tw-justify-center tw-font-medium tw-text-lg">
    <span class="tw-text-nowrap">{{ 'GAMES.CLASSIC_GAME.FAIR_GAME' | translate }} #{{ roundNumber() }}</span>
  </div>

  <div class="tw-flex tw-flex-col tw-gap-y-1 tw-text-sm">
    <span>{{ 'GAMES.CLASSIC_GAME.ROUND_HASH' | translate }}</span>

    {{ roundHash() }}
  </div>

  <div class="tw-flex tw-flex-col tw-gap-1 tw-text-sm">
    <span>{{ 'GAMES.ROUND_SECRET' | translate }}</span>
    {{ roundSecret() ?? 'hidden' }}
  </div>

  <div class="tw-flex tw-flex-col tw-items-center tw-gap-3">
    <a
      class="btn btn-primary--design tw-w-full !tw-no-underline !tw-rounded-xl tw-text-gray-850"
      href="https://emn178.github.io/online-tools/sha224.html"
      target="_blank"
    >
      {{ 'LOCAL.PROVABLY_FAIR.TABS.CHECK_ROLL' | translate }}
    </a>

    @if (!roundSecret()) {
      <span class="tw-font-normal tw-text-gray-400 tw-text-sm">{{ 'GAMES.ROUND_IN_PROGRESS' | translate }}</span>
    }
  </div>
</div>
