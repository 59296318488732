import type { OnDestroy } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import type { IClassicVictoryModalPayload } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { CommonStoreService } from '@app/core-state-common';

import { VictoryBlockComponent } from '../../../shared/components';
import { ClassicMainService } from '../../services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, TranslateModule, VictoryBlockComponent],
  providers: [ClassicMainService],
  selector: 'app-classic-victory-modal',
  standalone: true,
  styleUrls: ['./classic-victory-modal.component.scss'],
  templateUrl: './classic-victory-modal.component.html',
})
export class ClassicVictoryModalComponent implements OnDestroy {
  /** Подключение сервисов */
  readonly #classicMainService: ClassicMainService = inject(ClassicMainService);
  readonly #commonStoreService: CommonStoreService = inject(CommonStoreService);
  readonly #dialogRef: MatDialogRef<any, any> = inject(MatDialogRef<ClassicVictoryModalComponent>);
  readonly #payload: IClassicVictoryModalPayload = inject(MAT_DIALOG_DATA);

  /** Переменные сервисов */
  readonly items = signal(this.#payload.items);

  /** Методы жизненного цикла */
  ngOnDestroy(): void {
    this.#classicMainService.clearWinningItems();
  }

  /** Прочие методы */
  closeModal(): void {
    this.#dialogRef.close();
  }

  takePrize(): void {
    this.#commonStoreService.getInventoryInfo();
    this.#dialogRef.close();
  }
}
