<div class="tw-flex tw-gap-5 tw-justify-between tw-px-4 tw-py-2 tw-w-full tw-rounded-xl tw-bg-gray-740 hover:tw-bg-gray-700">
  <div class="tw-flex tw-flex-col tw-my-auto">
    <p class="tw-text-xs tw-text-gray-200">
      @if (float()) {
        <app-float-value
          [float]="float()"
          [numberFormat]="10"
        />
      } @else {
        <span class="tw-font-['Inter']">
          {{ '-----' }}
        </span>
      }
    </p>
    <div class="tw-flex tw-gap-1 tw-mt-1">
      @for (sticker of stickers(); track $index) {
        <app-p2p-sticker-image
          class="tw-h-[1.125rem] tw-w-[1.125rem]"
          [sticker]="sticker"
          [index]="$index"
        />
      }
    </div>
  </div>

  <div class="tw-flex tw-gap-5 tw-items-center tw-justify-end">
    <div class="tw-self-stretch tw-my-auto tw-text-sm tw-font-medium tw-text-right tw-text-orange-300">{{ specialWearType() }}</div>
    <app-overprice-tag [overprice]="overprice()" />
    <div class="tw-flex tw-gap-4">
      <div class="tw-flex tw-gap-1 tw-my-auto tw-text-sm tw-font-medium tw-text-right tw-text-white">
        <app-currency [value]="price()" />
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
