import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import type { IPromoCode, IReferrerUser } from '@dev-fast/types';
import { PromocodeTypes, ReferralCampaignTypes } from '@dev-fast/types';
import { UserAvatarComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import type { Observable } from 'rxjs';

import { AuthModalsModule, AuthService } from '@app/auth';
import { CurrencyComponent } from '@app/core/currency';
import { LangRouterModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';

@Component({
  selector: 'app-referral-modal',
  templateUrl: './referral-modal.component.html',
  styleUrls: ['./referral-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIcon,
    UserAvatarComponent,
    TranslateModule,
    UpperCasePipe,
    AppCurrencyModule,
    CurrencyComponent,
    AsyncPipe,
    RouterLink,
    LangRouterModule,
    AuthModalsModule,
  ],
})
export class ReferralModalComponent {
  readonly #authService = inject(AuthService);
  readonly #dialogRef = inject(MatDialogRef<ReferralModalComponent>);
  readonly #data: IPromoCode = inject(MAT_DIALOG_DATA);

  isAuth$: Observable<boolean | null> = this.#authService.isAuth$;
  currentReferral: IPromoCode | null = this.#data;
  imageError = false;

  referralCampaignEnum = ReferralCampaignTypes;

  promocodeTypesEnum = PromocodeTypes;

  close(): void {
    this.#dialogRef.close();
  }

  codeMessage(referrerUser: IReferrerUser | undefined): string {
    if (referrerUser) {
      return 'PROMOCODE.REF_USED';
    }
    return 'PROMOCODE.CODE_USED';
  }

  onImgError(): void {
    this.imageError = true;
  }

  iconSrc(reward: number): string {
    return `https://d2lomvz2jrw9ac.cloudfront.net/common/discount/${reward}.png`;
  }
}
