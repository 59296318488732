import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import type { ISticker } from '@dev-fast/types';
import { TagComponent } from '@dev-fast/ui-components';

import { CurrencyComponent } from '@app/core/currency';
import { P2pStickerImageComponent } from '@app/shared/components/p2p-sticker-image/p2p-sticker-image.component';

import { FloatValueComponent } from '../../shared/float-value/float-value.component';
import { OverpriceTagComponent } from '../../shared/overprice-tag/overprice-tag.component';

@Component({
  selector: 'app-similar-card',
  standalone: true,
  imports: [MatIcon, FloatValueComponent, P2pStickerImageComponent, CurrencyComponent, TagComponent, OverpriceTagComponent],
  templateUrl: './similar-card.component.html',
  styleUrl: './similar-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimilarCardComponent {
  readonly overprice = input<number>(0);
  readonly price = input<number>(0);
  readonly specialWearType = input<string | null | undefined>();
  readonly float = input<number | null | undefined>();
  readonly stickers = input<ISticker[]>();
  readonly isLoading = input(true);
}
