import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { EDiscountClass } from '@dev-fast/types';
import { TagComponent } from '@dev-fast/ui-components';

@Component({
  selector: 'app-overprice-tag',
  standalone: true,
  imports: [CommonModule, TagComponent],
  templateUrl: './overprice-tag.component.html',
  styleUrl: './overprice-tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverpriceTagComponent {
  readonly overprice = input<number>(0);
  readonly discountClass = computed(() => {
    const discount = this.overprice();
    if (discount > 50) {
      return EDiscountClass.RED;
    }
    if (discount > 20) {
      return EDiscountClass.ORANGE;
    }
    return EDiscountClass.GREEN;
  });
}
