import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import type {
  EBackgroundClass,
  EDiscountClass,
  EPhaseClass,
  EWearType,
  IMarketplaceKitData,
  IMarketplaceKitItem,
  ISteamStoreInventory,
} from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { ProductCardComponent } from '@app/ui/components/lib/product-card/product-card.component';

import { PackProductService } from './pack-product-card.service';

function toKitData(value: IMarketplaceKitData | ISteamStoreInventory | null): IMarketplaceKitData | null {
  if (!value) {
    return null;
  }
  return 'items' in value ? value : null;
}

function toKitItem(value: IMarketplaceKitItem | ISteamStoreInventory | null): IMarketplaceKitItem | null {
  if (!value) {
    return null;
  }
  return 'id' in value ? value : null;
}

@Component({
  selector: 'app-pack-viewer',
  standalone: true,
  imports: [AsyncPipe, MatIcon, ProductCardComponent, TitleCasePipe, TranslateModule],
  providers: [PackProductService],
  templateUrl: './pack-viewer.component.html',
  styleUrl: './pack-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackViewerComponent {
  readonly #packProductService = inject(PackProductService);
  readonly item = input<IMarketplaceKitData | null, IMarketplaceKitData | ISteamStoreInventory | null>(null, {
    transform: toKitData,
  });

  readonly selectedItem = input<IMarketplaceKitItem | null, IMarketplaceKitItem | ISteamStoreInventory | null>(null, {
    transform: toKitItem,
  });

  readonly clickOnItem = output<IMarketplaceKitItem>();

  onSelectItem(item: IMarketplaceKitItem): void {
    const isSelected = this.isItemSelected(item.id);
    if (isSelected) {
      return;
    }
    this.clickOnItem.emit(item);
  }

  getItemImageSrc(item: IMarketplaceKitItem): string[] {
    return this.#packProductService.getItemImageSrc(item);
  }

  getKitPrice(item: IMarketplaceKitItem): number {
    return this.#packProductService.getKitPrice(item);
  }

  getDiscount(item: IMarketplaceKitItem): number {
    return this.#packProductService.getDiscount(item);
  }

  getWearType(item: IMarketplaceKitItem): EWearType[] {
    return this.#packProductService.getWearType(item);
  }

  getItemWear(item: IMarketplaceKitItem): number {
    return this.#packProductService.getItemWear(item);
  }

  getItemPhase(item: IMarketplaceKitItem): string | null | undefined {
    return this.#packProductService.getItemPhase(item);
  }

  getItemType(item: IMarketplaceKitItem): string {
    return this.#packProductService.getItemType(item);
  }

  getItemName(item: IMarketplaceKitItem): string {
    return this.#packProductService.getItemName(item);
  }

  getItemBadges(item: IMarketplaceKitItem): string[] {
    return this.#packProductService.getItemBadges(item);
  }

  getItemBackgroundClass(item: IMarketplaceKitItem): EBackgroundClass {
    return this.#packProductService.getItemBackgroundClass(item);
  }

  getItemDiscountClass(item: IMarketplaceKitItem): EDiscountClass {
    return this.#packProductService.getItemDiscountClass(item);
  }

  getItemPhaseClass(item: IMarketplaceKitItem): EPhaseClass | '' {
    return this.#packProductService.getItemPhaseClass(item);
  }

  getSelectedClass(item: IMarketplaceKitItem): string {
    return this.isItemSelected(item.id) ? 'selected' : '';
  }

  isItemSelected(id?: number): boolean {
    return !!this.selectedItem() && this.selectedItem()?.id === id;
  }
}
