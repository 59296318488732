import type { Environments } from '@app/core/environment-service';
import { ENVIRONMENTS_DEFAULT_MARKET } from '@app/core/environment-service';
import { getStageMarketGateway } from '@app/shared/utils';
import { getLocation } from '@app/shared/utils/location';

const location = getLocation('csmarket.gg');

// NOTE: Отдельная среда по которой будет собираться проект на отдельный стейдж. По дефолту дублирует дев среду
// https://staging.csmarket.gg/ адрес стейджа
export const environment: Environments = {
  ...ENVIRONMENTS_DEFAULT_MARKET,
  production: false,
  PROJECT: 'market',
  BUILD_NAME: 'stage-client',
  HOSTNAME: `${location.host}`,
  SUPPORTS_EMAIL: 'support@csmarket.gg',
  // ---------- URLS
  WS_GATEWAY_URL: getStageMarketGateway(location.hostname),
  GATEWAY_URL: getStageMarketGateway(location.hostname),
  PAYMENT_URL: 'https://pay.csmarket.gg',
  PAYMENT_URL_2: 'https://pay.csmarket.gg',
  LOCALIZATION_URL: 'https://locales.csmarket.gg/api',
  WITHDRAWAL_URL: getStageMarketGateway(location.hostname),
  // ---------- MODULES
  MODULES: {
    account: {
      main: [],
      balance: [],
      // levels: [],
      // premium: [],
      // statistics: [],
      // referrals:[],
    },
    transaction: {
      refill: [],
    },
    soundToggler: true,
    inventory: {},
    games: [
      // 'classic',
      // 'case-battle',
      // 'fast',
      // 'double',
      // 'poggi',
      // 'baccarat',
      // 'hilo',
      // 'x50',
      // 'crash',
      // 'slot',
      // 'tower',
      // 'fortune-wheel',
      // 'cases',
      // 'match-bets',
      // 'craft',
    ],
  },
};
