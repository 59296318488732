import { Injector } from '@angular/core';
import type { ModalModel } from '@dev-fast/types';
import { ModalNames, PROJECT } from '@dev-fast/types';

import { GameBreakModalSAComponent } from '@app/games/lib/case-battle/modals/game-break-modal/game-break-modal.component';
import { BattleWinnerModalSAComponent } from '@app/games/lib/case-battle/modals/winners-modal/winners-modal.component';
import { BonusCaseModalComponent } from '@app/games/lib/cases/modals/bonus-case/bonus-case.component';
import { ClassicVictoryModalComponent } from '@app/games/lib/classic/modals/classic-victory-modal/classic-victory-modal.component';
import { P2pNewAuctionCompletedModalComponent } from '@app/widgets/p2p-new';
import { TradeCompleteService } from '@app/widgets/p2p-new/modals/p2p-auction-completed-modal/p2p-trade-complete.service';

import { DebugTradeCompleteService } from './services/debugTradeComplete.service';

export interface ModalList {
  name: ModalNames;
  projects: PROJECT[];
  description: string;
}

/** Список модалок с описанием, доступный для debug-menu, разделенный на проекты, в которых модалка доступна */
export const modalList: ModalList[] = [
  {
    name: ModalNames.VICTORY,
    projects: [PROJECT.FAST_NEW],
    description: 'Победная модалка в Classic Game',
  },
  {
    name: ModalNames.BONUS_CASE,
    projects: [PROJECT.FAST_NEW],
    description: 'Вызывается при получении бонусного кейса',
  },
  {
    name: ModalNames.P2P_AUCTION_COMPLETED,
    projects: [PROJECT.FAST_NEW, PROJECT.MARKET],
    description: 'Когда кто-то купил твой предмет, выставленный на продажу P2P, модалка подтверждения',
  },
  {
    name: ModalNames.BAN,
    projects: [PROJECT.FAST_NEW, PROJECT.MARKET],
    description: 'Модалка информирующая о бане',
  },
  {
    name: ModalNames.TRANSACTION_NOTIFICATION,
    projects: [PROJECT.FAST_NEW, PROJECT.MARKET],
    description: 'Вызывается при событиях транзакций (пополнение, вывод, ошибки)',
  },
  {
    name: ModalNames.P2P_ERRORS_DESCRIPTION,
    projects: [PROJECT.FAST_NEW, PROJECT.MARKET],
    description: 'P2P сообщения об ошибках',
  },
  {
    name: ModalNames.REFERRAL,
    projects: [PROJECT.FAST_NEW, PROJECT.MARKET],
    description: 'Вызывается при получении реферального промокода',
  },
  {
    name: ModalNames.CASE_BATTLE_WINNERS,
    projects: [PROJECT.FAST_NEW, PROJECT.MARKET],
    description: 'Вызывается при победе в Case-Battle',
  },
  {
    name: ModalNames.P2P_TRADE_ACCEPT,
    projects: [PROJECT.MARKET],
    description: 'Вызывается, когда ставка выиграла',
  },
  {
    name: ModalNames.P2P_RWT,
    projects: [PROJECT.FAST_NEW, PROJECT.MARKET],
    description: 'Соглашение о запрете Real-World Trading',
  },
  {
    name: ModalNames.SIH_INSTALL,
    projects: [PROJECT.FAST_NEW, PROJECT.MARKET],
    description: 'Доступ к Steam Inventory Helper',
  },
  {
    name: ModalNames.CASE_BATTLE_GAME_BREAK,
    projects: [PROJECT.FAST_NEW, PROJECT.MARKET],
    description: 'Технический сбой в игре Case-Battle',
  },
];

/** Модалки, которые нужно зарегистрировать, чтобы был к ним доступ из debug-меню, либо которым нужно прокинуть инжектор
 * @returns Список модалок для регистрации
 * @param parentInjector - инжектор родителя, который будет использоваться для создания инжектора для модалки
 */
export function registerModalList(parentInjector: Injector): ModalModel[] {
  return [
    {
      component: ClassicVictoryModalComponent,
      config: {
        backdropClass: 'classic-victory-modal-backdrop',
        panelClass: 'classic-victory-modal-panel',
      },
      name: ModalNames.VICTORY,
    },
    {
      name: ModalNames.BONUS_CASE,
      component: BonusCaseModalComponent,
      config: {
        panelClass: 'revision-modal',
        maxWidth: '50rem',
        mobileViewAllowed: true,
      },
    },
    {
      name: ModalNames.P2P_AUCTION_COMPLETED,
      component: P2pNewAuctionCompletedModalComponent,
      config: {
        panelClass: 'p2p-auction-completed-modal',
        injector: Injector.create({
          providers: [{ provide: TradeCompleteService, useClass: DebugTradeCompleteService }],
          parent: parentInjector,
        }),
      },
    },
    {
      name: ModalNames.CASE_BATTLE_WINNERS,
      component: BattleWinnerModalSAComponent,
      config: {
        panelClass: 'revision-modal',
      },
    },
    {
      name: ModalNames.CASE_BATTLE_GAME_BREAK,
      component: GameBreakModalSAComponent,
      config: {
        panelClass: 'case-battle-game-break',
      },
    },
  ];
}
