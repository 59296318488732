import type { Portal } from '@angular/cdk/portal';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Injector, ViewContainerRef } from '@angular/core';
import type { Observable } from 'rxjs';

import { PortalService } from '@app/core/portal-service';

@Component({
  selector: 'app-routable-modal-container',
  templateUrl: './routable-modal-container.component.html',
  styleUrls: ['./routable-modal-container.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, CdkPortalOutlet],
})
export class RoutableModalContainerComponent {
  readonly vcr = inject(ViewContainerRef);
  readonly injector = inject(Injector);
  readonly portalService = inject(PortalService);

  modalPortal$: Observable<Portal<any> | undefined> = this.portalService.routableModalPortal.value$;
}
