import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { SellerRatingComponent } from '../shared/seller-rating/seller-rating.component';

@Component({
  selector: 'app-about-seller',
  standalone: true,
  imports: [SellerRatingComponent, NgClass],
  templateUrl: './about-seller.component.html',
  styleUrl: './about-seller.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutSellerComponent {
  readonly percentage = input<number>(0);
  readonly isOnline = input<boolean>(false);
  readonly sellerName = input<string>('');
}
