import type { IPublicGameStatistic } from '@dev-fast/types';
import { GameMode } from '@dev-fast/types';

export const HIDDEN_ITEMS_ARR: unknown[] = new Array(6);

export const HIDDEN_ITEM_MOCK: IPublicGameStatistic = {
  key: 'baccarat',
  mode: GameMode.PVE,
  top: {
    isCurrency: true,
    key: 'baccarat_max_bet',
    title: 'Max bet',
    value: '9999',
  },
};
