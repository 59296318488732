import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, model, signal, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import type { IMarketplaceKitData, IUICheckboxLabel, IUserP2pPermissionInterface } from '@dev-fast/types';
import { BuyViewVariant, ModalNames, SteamErrorsEnum, SteamErrorsEnumLocales } from '@dev-fast/types';
import { UiCheckboxSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { filter, Subject, take } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CurrencyComponent } from '@app/core/currency';
import { NewWarningBannerComponent } from '@app/new-market/p2p/new-warning-banner/new-warning-banner.component';
import type { ISelectionMenuItem } from '@app/ui/components/lib/form-elements/autocomplete-menu/shared';
import { SihRedirectBlockComponent } from '@app/widgets/sih';

import { SimpleSortingComponent } from '../shared/simple-sorting/simple-sorting.component';
import { BasketItemCardComponent } from './basket-item-card/basket-item-card.component';
import { CHECKBOX_LABELS, SORTING_METHODS, TAB_OPTIONS } from './const';
import { P2pBasketService } from './new-basket.service';

@Component({
  selector: 'app-new-basket',
  standalone: true,
  providers: [P2pBasketService],
  templateUrl: './new-basket.component.html',
  styleUrl: './new-basket.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    TranslateModule,
    UpperCasePipe,
    MatIcon,
    NgScrollbarModule,
    BasketItemCardComponent,
    CurrencyComponent,
    UiCheckboxSAComponent,
    ReactiveFormsModule,
    NewWarningBannerComponent,
    SihRedirectBlockComponent,
    SimpleSortingComponent,
  ],
})
export class NewBasketComponent {
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dialogRef = inject(MatDialogRef<NewBasketComponent>);

  readonly #p2pBasketService = inject(P2pBasketService);

  permissions = toSignal<IUserP2pPermissionInterface>(this.#p2pBasketService.p2pPermissions$);
  nativeBreakpoint = toSignal<string>(this.#p2pBasketService.nativeBreakpoint$);
  selectedPurchaseItems = toSignal<IMarketplaceKitData[]>(this.#p2pBasketService.selectedPurchaseItems$);
  countSelectedItems = toSignal<number>(
    this.#p2pBasketService.countSelectedItems$.pipe(
      tap((count) => {
        if (count === 0) {
          this.onClose();
        }
      }),
    ),
  );

  summaryPrice = toSignal<number>(this.#p2pBasketService.summaryPrice$);
  profit = toSignal<number | null>(this.#p2pBasketService.profit$);

  selectedId = model(BuyViewVariant.SINGLE);
  tabOptions = signal(TAB_OPTIONS);

  sortingMethods = signal<[ISelectionMenuItem, ISelectionMenuItem]>(SORTING_METHODS);

  permissionErrorBtnText = computed<string>(() => this.#formatErrBtnText(this.permissions()));
  canProceedWithTrade = computed(() => {
    const { success, canTrade, canSteamAPI } = this.permissions() || {};
    return success && canTrade?.success && canSteamAPI?.success;
  });

  // rwt
  checkboxLabels: IUICheckboxLabel[] = CHECKBOX_LABELS;
  agreement = new FormControl(false, {
    nonNullable: true,
    validators: [Validators.requiredTrue],
  });

  checkValidation$: Subject<void> = new Subject();

  //
  constructor() {
    // если кликнули по ссылке на правила, то закрываем корзину при переходе в них.
    this.#router.events
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter((event) => event instanceof NavigationEnd),
        take(1),
        tap(() => {
          this.onClose();
        }),
      )
      .subscribe();
  }

  onBuy(): void {
    const ids = this.#getItemsIds();
    if (this.agreement.invalid) {
      this.agreement.markAsDirty();
      this.checkValidation$.next();
      return;
    }
    this.#p2pBasketService.buyItems(ids);

    this.onClose();
  }

  onSortingTypeChange(sortValue: ISelectionMenuItem): void {
    this.#p2pBasketService.changeSorting(sortValue.name === 'ASC');
  }

  onClose(): void {
    this.#dialogRef.close();
  }

  removeSelectedItem = (item: IMarketplaceKitData): void => {
    this.#p2pBasketService.unselectByIds([item.id]);
  };

  onRemoveAll(): void {
    const ids = this.#getItemsIds();
    this.#p2pBasketService.unselectByIds(ids);
  }

  onSetupSteam = (): void => {
    this.#dialogRef.close();
    this.#p2pBasketService.openModal(ModalNames.P2P_SETUP);
  };

  isKit = (item: IMarketplaceKitData): boolean => item.items.length > 1;

  handleErrorAction(permissions?: IUserP2pPermissionInterface | null): void {
    // Если нет permissions, выходим
    if (!permissions) {
      return;
    }
    // Получаем сообщение об ошибке из permissions
    const errorMsg: string | undefined = permissions.canTrade?.error || permissions.canSteamAPI?.error || permissions.error;
    // Определяем карту URL на основе сообщения об ошибке
    const urlMap: Record<string, string> = {
      [SteamErrorsEnum.UNBINDING_STEAM]: '/account/main',
      [SteamErrorsEnum.STEAM_GUARD]: 'https://store.steampowered.com/mobile',
      [SteamErrorsEnum.PRIVATE_INVENTORY]: 'https://steamcommunity.com/my/edit/settings',
    };
    // Открываем соответствующий URL в новой вкладке, если он есть в карте
    if (errorMsg && urlMap[errorMsg]) {
      window.open(urlMap[errorMsg], '_blank');
    }
    // Если ошибка связана с невалидной торговой ссылкой или отсутствием API ключа, или ошибка не указана
    else if (errorMsg === SteamErrorsEnum.INVALID_TRADELINK || errorMsg === SteamErrorsEnum.NO_API_KEY || !errorMsg) {
      this.onSetupSteam();
    }
  }

  #getItemsIds(): number[] {
    const items = this.selectedPurchaseItems() || [];
    return items.map((item) => item.id);
  }

  #formatErrBtnText(permissions?: IUserP2pPermissionInterface): string {
    if (!permissions) {
      return '';
    }
    let title: string | undefined = 'P2P_SETTINGS.SETUP_TITLE';
    const errorMsg = permissions?.canTrade?.error || permissions?.canSteamAPI?.error || permissions?.error;
    if (errorMsg) {
      title = SteamErrorsEnumLocales[errorMsg];
    }
    return title ?? 'P2P_SETTINGS.SETUP_TITLE';
  }
}
