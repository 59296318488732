import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, inject, Signal, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationsService } from '@app/core/notification-service';
import { CustomAsyncPipeModule } from '@app/shared/pipes';

import { ClassicMainService } from '../../services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CustomAsyncPipeModule, TranslateModule],
  providers: [ClassicMainService],
  selector: 'app-classic-fair-game-modal',
  standalone: true,
  styleUrls: ['./classic-fair-game-modal.component.scss'],
  templateUrl: './classic-fair-game-modal.component.html',
})
export class ClassicFairGameModalComponent {
  /** Подключение сервисов */
  readonly #classicMainService: ClassicMainService = inject(ClassicMainService);
  readonly #clipboard: Clipboard = inject(Clipboard);
  readonly #dialogRef: MatDialogRef<any, any> = inject(MatDialogRef<ClassicFairGameModalComponent>);
  readonly #notificationsService: NotificationsService = inject(NotificationsService);

  /** Переменные сервисов */
  readonly roundHash: Signal<string> = toSignal(this.#classicMainService.roundHash$, { initialValue: '' });
  readonly roundNumber: Signal<number> = toSignal(this.#classicMainService.roundNumber$, { initialValue: 0 });
  readonly roundSecret: Signal<string | null> = toSignal(this.#classicMainService.roundSecret$, { initialValue: null });

  /** Прочие методы */
  close(): void {
    this.#dialogRef.close();
  }

  copy(code: string): void {
    this.#clipboard.copy(code);
    this.#notificationsService.addSuccessNotification('GAMES.COPIED');
  }
}
