import { AsyncPipe, JsonPipe, KeyValuePipe, NgTemplateOutlet } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import type {
  ICaseItemDtoV2,
  IMarketplaceKitData,
  IMarketplaceKitItem,
  ISihDataset,
  ISteamStoreInventory,
  IUserStoreProfile,
} from '@dev-fast/types';
import { APP_PATHS, ModalNames, OrderStatusEnum, StateActionStatus } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { Actions } from '@ngxs/store';
import type { Observable } from 'rxjs';
import { filter, map, merge, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CurrencyComponent, CurrencyService } from '@app/core/currency';
import { GetItemSihStats } from '@app/core/state/p2p';
import { UserService } from '@app/core/user-service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { P2pStickerImageComponent } from '@app/shared/components/p2p-sticker-image/p2p-sticker-image.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SkinViewerComponent } from '@app/shared/components/skin-viewer/skin-viewer.component';
import { actionLoadStatus, skinNameNormalize } from '@app/shared/utils';

import { AboutSellerComponent } from './about-seller/about-seller.component';
import { InCasesComponent } from './in-cases/in-cases.component';
import { NameFloatBlockComponent } from './name-float-block/name-float-block.component';
import { P2pSkinModalService } from './p2p-skin-modal.service';
import { PackViewerComponent } from './pack-viewer/pack-viewer.component';
import { PriceComponent } from './price-action-block/price/price.component';
import { PriceActionBlockComponent } from './price-action-block/price-action-block.component';
import { SihChartComponent } from './sih-chart/sih-chart.component';
import type { SimilarDictionary } from './similar/const';
import { SimilarComponent } from './similar/similar.component';
import { getDiscount } from './utils';

@Component({
  selector: 'app-p2p-skin-modal',
  standalone: true,
  imports: [
    SihChartComponent,
    NameFloatBlockComponent,
    SkinViewerComponent,
    P2pStickerImageComponent,
    AboutSellerComponent,
    InCasesComponent,
    AsyncPipe,
    PriceActionBlockComponent,
    SimilarComponent,
    PackViewerComponent,
    MatIcon,
    JsonPipe,
    TranslateModule,
    CurrencyComponent,
    KeyValuePipe,
    NgTemplateOutlet,
    PriceComponent,
  ],
  providers: [P2pSkinModalService],
  templateUrl: './p2p-skin-modal.component.html',
  styleUrl: './p2p-skin-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO надо облегчить, вынести частично в сервис/ утилиты и тд
export class P2pSkinModalComponent implements OnInit {
  readonly #skinModalService = inject(P2pSkinModalService);
  readonly #dialogRef = inject(MatDialogRef<P2pSkinModalComponent>);
  readonly matDialogDataInjection = inject<IMarketplaceKitData | ISteamStoreInventory>(MAT_DIALOG_DATA);
  readonly #actions$ = inject(Actions);
  readonly #destroyRef = inject(DestroyRef);
  readonly #router = inject(Router);
  readonly #userService = inject(UserService);
  readonly #currencyService = inject(CurrencyService);

  readonly userBalance = toSignal(this.#userService.userBalance$);
  readonly sihDataset = toSignal<ISihDataset>(this.#skinModalService.sihDataset$, { requireSync: true });
  readonly selectedItem = signal<IMarketplaceKitItem | ISteamStoreInventory | null>(null);
  readonly getSihStatStatus = toSignal<StateActionStatus>(actionLoadStatus(this.#actions$, GetItemSihStats, 500));
  readonly sellerInfo = signal<IUserStoreProfile | null>(null);
  readonly isAuth = toSignal<boolean>(this.#skinModalService.isAuth$, { requireSync: true });
  readonly sellerName = computed<string>(() => this.#skinModalService.getSellerName(this.sellerInfo()));
  readonly sellerRating = computed<number>(() => this.#skinModalService.getSellerRating(this.sellerInfo()));
  readonly sellerIsOnline = computed<boolean>(() => this.#skinModalService.getSellerOnlineStatus(this.sellerInfo()));

  readonly skinImage = computed(() => {
    const item = this.selectedItem();
    return item ? item.baseItem.icon : '';
  });

  readonly skinRarity = computed(() => {
    const item = this.selectedItem();
    return item?.baseItem?.rarity || 'default';
  });

  readonly skinName = computed(() => {
    const item = this.selectedItem();
    return item?.baseItem?.skin || item?.baseItem?.name || 'skin name';
  });

  readonly selectedItemIds = toSignal<number[]>(
    this.#skinModalService.purchasing$.pipe(
      filter((val) => !!val),
      map((val) => val.filter((item) => item.status === OrderStatusEnum.NEW)),
      map((val) => val.map((item) => item.id)),
    ),
  );

  readonly mainItem = computed<IMarketplaceKitData | ISteamStoreInventory>(() => {
    return this.matDialogDataInjection;
  });

  readonly mainItemOverprice = computed<number>(() => {
    const mainItem = this.mainItem();
    return getDiscount(mainItem.price, 'recommendedPrice' in mainItem ? mainItem.recommendedPrice : 0);
  });

  readonly selectedItemOverprice = computed<number>(() => {
    const selectedItem = this.selectedItem();
    if (!selectedItem) {
      return 0;
    }
    return getDiscount(selectedItem.price, 'recommendedPrice' in selectedItem ? selectedItem.recommendedPrice : 0);
  });

  readonly mainItemDollarPrice = computed<number>(() => {
    const mainItem = this.mainItem();
    return this.#currencyService.convertCurrencyToCurrency('usd', 'fastcoins_refill', mainItem.price / 100);
  });

  readonly selectedItemDollarPrice = computed<number>(() => {
    const selectedItem = this.selectedItem();
    if (!selectedItem) {
      return 0;
    }
    return this.#currencyService.convertCurrencyToCurrency('usd', 'fastcoins_refill', selectedItem.price / 100);
  });

  readonly canBuyMainItem = computed(() => {
    const mainItem = this.mainItem();
    const isMarketItem = 'items' in mainItem;
    return isMarketItem && !mainItem.isUserSeller;
  });

  readonly mainItemInCart = computed(() => {
    const mainItem = this.mainItem();
    return this.itemInCart(mainItem);
  });

  readonly sihChartIsLoading = computed(() => {
    const status = this.getSihStatStatus();
    return status !== StateActionStatus.SUCCESS && status !== StateActionStatus.ERROR;
  });

  readonly isSingleSkin = computed(() => {
    return this.countItemsInPack() === 1;
  });

  readonly countItemsInPack = signal(1);
  readonly isMobile = toSignal<boolean>(this.#skinModalService.isMobileByBreakpoint$, { requireSync: true });

  readonly selectedItem$ = toObservable<IMarketplaceKitItem | ISteamStoreInventory | null>(this.selectedItem);
  readonly cases$: Observable<ICaseItemDtoV2[] | null> = this.selectedItem$.pipe(
    switchMap((item) => {
      return merge(of(null), this.#skinModalService.getAvailabilityInCases(item));
    }),
  );

  readonly similarItems$: Observable<SimilarDictionary | null> = this.selectedItem$.pipe(
    filter((item) => !!item),
    switchMap((item) => {
      return merge(of(null), this.#skinModalService.getSimilarItems(item));
    }),
  );

  ngOnInit(): void {
    // если кит из маркета
    if ('items' in this.matDialogDataInjection) {
      const marketData = this.matDialogDataInjection;
      const isUserSeller = marketData.isUserSeller;
      const [firstItem] = marketData.items;
      const length = marketData.items.length;
      this.#initPackSelectedItem(firstItem, length);
      this.countItemsInPack.set(length);
      if (!isUserSeller) {
        this.#getSellerInfo(marketData.id)
          .pipe(
            tap((sellerInfo) => {
              this.sellerInfo.set(sellerInfo);
            }),
            takeUntilDestroyed(this.#destroyRef),
          )
          .subscribe();
      }
      this.#getSihStat(firstItem.baseItem.name);
      return;
    }

    // если предмет из инвентаря
    this.selectedItem.set(this.matDialogDataInjection);
    this.#getSihStat(this.matDialogDataInjection.baseItem.name);
  }

  onBack(): void {
    this.selectedItem.set(null);
  }

  onItemInPack(item: IMarketplaceKitItem): void {
    this.selectedItem.set(item);
    this.#getSihStat(item.baseItem.name);
  }

  close(): void {
    this.#dialogRef.close();
  }

  onOpenCase(caseId: number): void {
    this.close();

    this.#router.navigate([APP_PATHS.games.cases.base, APP_PATHS.games.cases.case, caseId]);
  }

  navigateToSkinPage(selectedItem: IMarketplaceKitItem | ISteamStoreInventory): void {
    const { skin, shortName, name } = selectedItem.baseItem;
    if (skin && shortName && name) {
      this.#router.navigate([`/skins/item/${skinNameNormalize({ shortName, skin, name })}`]);
      this.close();
    }
  }

  onSelectItem(item: IMarketplaceKitData | ISteamStoreInventory): void {
    if (!('items' in item)) {
      return;
    }
    if (!this.isAuth()) {
      this.onLogin();
    }
    if (item.status === OrderStatusEnum.NEW) {
      this.#skinModalService.toggleSelected(item);
    }
  }

  itemInCart(item: IMarketplaceKitData | ISteamStoreInventory): boolean {
    const selectedItemIds = this.selectedItemIds() ?? [];
    return selectedItemIds.some((id) => 'id' in item && id === item.id);
  }

  onAddToCart(item: IMarketplaceKitData | ISteamStoreInventory): void {
    this.onSelectItem(item);
  }

  onBuyNow(item: IMarketplaceKitData | ISteamStoreInventory): void {
    if (!this.itemInCart(item)) {
      this.onSelectItem(item);
    }
    this.close();
    this.#skinModalService.openModal(ModalNames.P2P_BASKET);
  }

  onNavigateToCart(): void {
    this.close();
    this.#skinModalService.openModal(ModalNames.P2P_BASKET);
  }

  onRemoveFromCart(item: IMarketplaceKitData | ISteamStoreInventory): void {
    this.onSelectItem(item);
  }

  onRefill(): void {
    this.#router.navigate(['', { outlets: { modal: ['transaction', 'refill'] } }], { queryParamsHandling: 'merge' });
  }

  onLogin(): void {
    this.close();
    this.#skinModalService.openAuthModal();
  }

  #initPackSelectedItem(item: IMarketplaceKitItem, length: number): void {
    const isMobile = this.isMobile();
    if (isMobile && length !== 1) {
      this.selectedItem.set(null);
      return;
    }
    this.selectedItem.set(item);
  }

  #getSellerInfo(kitId: number): Observable<IUserStoreProfile> {
    return this.#skinModalService.getStoreByKitId(kitId);
  }

  #getSihStat(name: string): void {
    this.#skinModalService.getItemSihStats(name);
  }
}
