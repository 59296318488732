<div class="language-panel tw-size-full">
  <div class="language-panel__header tw-flex tw-flex-nowrap tw-gap-x-2 tw-pb-2">
    <button
      class="btn btn-semi-round btn-transparent-dark--design j-fs w-f"
      (click)="onCloseClick()"
    >
      <mat-icon
        class="icon lg"
        svgIcon="arrow-left-design"
      ></mat-icon>

      <span>{{ 'HEADER_SETTINGS.LANGUAGE' | translate }}</span>
    </button>
  </div>

  <div class="language-panel__list tw-flex tw-flex-col tw-gap-y-2 tw-py-4 tw-font-medium tw-overflow-y-scroll">
    @if (currentLanguage(); as currentLanguage) {
      @for (lang of locales(); track lang.path) {
        <button
          class="btn btn-semi-round btn-transparent-dark--design j-sb w-f"
          [class.active]="currentLanguage === lang.path"
          [disabled]="!lang.isActive"
          (click)="onLangChange(lang.path)"
        >
          <div class="f-a-center tw-flex tw-items-center tw-gap-x-4">
            <img
              lazyLoadImage
              class="tw-size-6 tw-object-fill tw-rounded-full"
              [src]="'//d2lomvz2jrw9ac.cloudfront.net/country-flags/' + lang.path.slice(3).toLowerCase() + '.png'"
            />
            <span>{{ lang.title }}</span>
          </div>

          <mat-radio-button
            class="languages-list-radio-btn"
            [checked]="currentLanguage === lang.path"
            [value]="lang.path"
          >
          </mat-radio-button>
        </button>
      }
    }
  </div>
</div>
