import {
  CLASSIC_PHASE_DURATIONS,
  CrossServices,
  EClassicPhase,
  IClassicPlayerConfig,
  IClassicStateDto,
  IGameSettings,
  ITradeItem,
  Trade,
} from '@dev-fast/types';
import { StateContext } from '@ngxs/store';
import { mapKeys, sumBy } from 'lodash-es';
import { switchMap, take, tap, timer } from 'rxjs';

import { getPlayerColorById } from '../classic.utils';
import { ChangePhase } from './classic.actions';
import { IClassicStateModel } from './classic.model';

const HUNDRED = 100;

/**
 * Возвращает продолжительность фазы countdown (в миллисекундах)
 * @param currentGameSettings - Объект с настройками текущей игры
 * @returns Продолжительность фазы countdown (в миллисекундах)
 */
export function getCountdownDuration(currentGameSettings: IGameSettings | null): number {
  return currentGameSettings?.timeoutMs ?? CLASSIC_PHASE_DURATIONS[EClassicPhase.COUNTDOWN];
}

export function getPhase(
  { dispatch }: StateContext<IClassicStateModel>,
  rafflingTimestampDiff: number | null | undefined,
  currentGameSettings: IGameSettings | null,
): EClassicPhase {
  if (!rafflingTimestampDiff) {
    return EClassicPhase.INITIAL;
  }

  const rafflingDuration = getRafflingDuration(currentGameSettings);
  const prizesDuration = getPrizesDuration(currentGameSettings);

  if (rafflingTimestampDiff >= 0) {
    return EClassicPhase.COUNTDOWN;
  }

  if (-rafflingTimestampDiff <= rafflingDuration) {
    const rafflingDue = rafflingDuration + rafflingTimestampDiff;

    timer(rafflingDue)
      .pipe(
        take(1),
        tap(() => dispatch(new ChangePhase(EClassicPhase.PRIZES))),
        switchMap(() => timer(prizesDuration)),
        tap(() => dispatch(new ChangePhase(EClassicPhase.RESETTING))),
      )
      .subscribe();

    return EClassicPhase.RAFFLING;
  }

  if (-rafflingTimestampDiff <= rafflingDuration + prizesDuration) {
    const prizesDue = rafflingDuration + prizesDuration + rafflingTimestampDiff;

    timer(prizesDue)
      .pipe(
        take(1),
        tap(() => dispatch(new ChangePhase(EClassicPhase.RESETTING))),
      )
      .subscribe();

    return EClassicPhase.PRIZES;
  }

  return EClassicPhase.RESETTING;
}

/**
 * Возвращает массив данных об игроках, сделавших ставку в текущем раунде
 * @param prize - Разыгрываемая в текущем раунде сумма
 * @param trades - Массив данных о сделанных в текущем раунде ставках
 * @returns Массив данных об игроках, сделавших ставку в текущем раунде
 */
export function getPlayers(prize: number, trades: Trade[]): IClassicPlayerConfig[] {
  const uniqPlayerIdsFromTrades = [...new Set(trades.map(({ playerId }) => playerId))];

  return uniqPlayerIdsFromTrades.map((id) => {
    const tradesFilteredByPlayerId = trades.filter(({ playerId }) => playerId === id);

    const avatar = tradesFilteredByPlayerId[0].playerImage;
    const color = getPlayerColorById(tradesFilteredByPlayerId[0].playerId);
    const itemsNumber = sumBy(tradesFilteredByPlayerId, 'itemsNum');
    const name = tradesFilteredByPlayerId[0].playerName;
    const totalPrice = sumBy(tradesFilteredByPlayerId, 'totalPrice');
    const totalChance = (totalPrice / prize) * HUNDRED;

    return {
      avatar,
      color,
      id,
      itemsNumber,
      name,
      totalChance,
      totalPrice,
    };
  });
}

/**
 * Возвращает продолжительность фазы prizes (в миллисекундах)
 * @param currentGameSettings - Объект с настройками текущей игры
 * @returns Продолжительность фазы prizes (в миллисекундах)
 */
export function getPrizesDuration(currentGameSettings: IGameSettings | null): number {
  const rafflingDuration = getRafflingDuration(currentGameSettings);

  return currentGameSettings
    ? currentGameSettings.endgameMs - rafflingDuration - CLASSIC_PHASE_DURATIONS[EClassicPhase.RESETTING]
    : CLASSIC_PHASE_DURATIONS[EClassicPhase.PRIZES];
}

/**
 * Возвращает продолжительность фазы raffling (в миллисекундах)
 * @param currentGameSettings - Объект с настройками текущей игры
 * @returns Продолжительность фазы raffling (в миллисекундах)
 */
export function getRafflingDuration(currentGameSettings: IGameSettings | null): number {
  return currentGameSettings?.rouletteMs ?? CLASSIC_PHASE_DURATIONS[EClassicPhase.RAFFLING];
}

export function mapStateResponse(
  response: Partial<IClassicStateDto>,
): Partial<Omit<IClassicStateModel, 'eventTime' | 'history' | 'phase' | 'players' | 'viewMode'>> {
  const mappingScheme = {
    md5: 'roundHash',
    number: 'roundNumber',
    rand: 'roundSecret',
    winner: 'winnerId',
  };

  return mapKeys(response, (value, key) => {
    return mappingScheme[key as keyof typeof mappingScheme] ?? key;
  });
}

//////////////////////////////////////////

/***
 * Метод возвращает номер победного тикета
 */
export const getWinnerTicket = (prize: number, secret: string | null): number | null => {
  if (!secret) {
    return null;
  }
  return Math.round(prize * parseFloat(secret));
};

/***
 * Метод возвращает победителя (из коллекции players)
 */
export const getWinnerPlayer = (players: IClassicPlayerConfig[], winnerId: number | null): IClassicPlayerConfig | undefined => {
  return players.find(({ id }) => id === winnerId);
};

/***
 * Метод возвращает выйгрышное оружие победителя
 */
export const getWinnerWeapon = (winTicket: number, trades: Trade[]): ITradeItem | undefined => {
  const winTrade = trades.find((trade) => {
    const [firstValue, lastValue] = trade.tickets;
    return winTicket >= firstValue && winTicket <= lastValue;
  });

  if (!winTrade) {
    throw new Error('Winner weapon not found!');
  }

  const items = winTrade.items[CrossServices.CSGO];
  return items.find(({ tickets = [] }) => {
    const [firstValue, lastValue] = tickets;
    return firstValue && winTicket >= firstValue && lastValue && winTicket <= lastValue;
  });
};
