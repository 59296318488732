<button
  class="btn btn-sm sq btn-round btn-transparent-dark--design close-btn"
  (click)="close()"
>
  <mat-icon
    [title]="'LOCAL.CASES.MODAL.REVISION.CLOSE' | translate"
    class="close-icon"
    [svgIcon]="'close'"
  >
  </mat-icon>
</button>
@if (project === ProjectEnum.MARKET) {
  <app-user-profile-market-content
    [userId]="userId"
    (close)="close()"
  ></app-user-profile-market-content>
} @else {
  <app-user-profile-content
    [userId]="userId"
    (close)="close()"
  >
  </app-user-profile-content>
}
