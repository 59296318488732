import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import type { ILocales } from '@dev-fast/types';
import { NewMenu } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import find from 'lodash-es/find';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatRadioModule, TranslateModule],
  selector: 'app-language-panel',
  standalone: true,
  styleUrl: './language-panel.component.scss',
  templateUrl: './language-panel.component.html',
})
export class LanguagePanelComponent {
  // Прочие переменные
  readonly locale = input<ILocales | null>();
  readonly locales = input<ILocales[] | null>();

  readonly closePanel = output<NewMenu>();
  readonly languageChange = output<ILocales>();

  readonly NewMenu = NewMenu;

  // Геттеры
  readonly currentLanguage = computed<string | undefined>(() => this.locale()?.path);

  // Прочие методы
  onCloseClick(): void {
    this.closePanel.emit(NewMenu.LANGUAGE);
  }

  onLangChange(path: string): void {
    const foundLocale = find(this.locales(), { path });

    if (foundLocale) {
      this.languageChange.emit(foundLocale);
    }
  }
}
