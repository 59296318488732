import { Injectable } from '@angular/core';
import type { EPhaseClass, IMarketplaceKitItem } from '@dev-fast/types';
import {
  EBackgroundClass,
  EDiscountClass,
  EWearType,
  MAX_ACCEPTABLE_RECOMMENDED_PRICE,
  RProductCardBackgroundClass,
  RProductCardPhaseClass,
} from '@dev-fast/types';

import { toShortRevisionWear } from '@app/shared/utils';
import { transformSkinUrl, transformStickerUrl } from '@app/shared/utils/skin-url';

@Injectable()
export class PackProductService {
  getItemImageSrc(item: IMarketplaceKitItem): string[] {
    return [transformSkinUrl(item.baseItem.icon)];
  }

  getKitPrice(item: IMarketplaceKitItem): number {
    return 'itemImage' in item ? 0 : item.price;
  }

  getDiscount(item: IMarketplaceKitItem): number {
    if (item.recommendedPrice && item.price < MAX_ACCEPTABLE_RECOMMENDED_PRICE) {
      return Math.round((1 - item.recommendedPrice / item.price) * 100);
    }

    return 0;
  }

  getItemDiscountClass(item: IMarketplaceKitItem): EDiscountClass {
    const discount = this.getDiscount(item);

    switch (true) {
      case discount > 50:
        return EDiscountClass.RED;
      case discount > 20:
        return EDiscountClass.ORANGE;

      default:
        return EDiscountClass.GREEN;
    }
  }

  getWearType(item: IMarketplaceKitItem): EWearType[] {
    const { exterior, statTrak, isSouvenir } = item.baseItem;
    const wearArr: EWearType[] = [];

    if (isSouvenir) {
      wearArr.push(EWearType.SV);
    } else if (statTrak) {
      wearArr.push(EWearType.ST);
    }

    if (exterior) {
      wearArr.push(toShortRevisionWear(exterior) as EWearType);
    }

    return wearArr;
  }

  getItemWear(item: IMarketplaceKitItem): number {
    const NUMBERS_AFTER_DOT = 5;
    const { float } = item;

    return parseFloat((float ?? 0).toFixed(NUMBERS_AFTER_DOT));
  }

  getItemPhase(item: IMarketplaceKitItem): string | null | undefined {
    const { phase } = item.baseItem;
    return phase;
  }

  getItemType(item: IMarketplaceKitItem): string {
    const { name, type, shortName, skin } = item.baseItem;

    if (!name || !skin) {
      return '';
    }

    return type === 'weapon' && shortName ? shortName : name.split('|')[0];
  }

  getItemName(item: IMarketplaceKitItem): string {
    const { name, type, shortName, skin } = item.baseItem;

    return skin ? skin : type === 'weapon' && shortName ? shortName : name;
  }

  getItemBadges(item: IMarketplaceKitItem): string[] {
    const { stickers } = item;
    return stickers ? stickers.map((sticker) => transformStickerUrl(sticker.icon)) : [];
  }

  getItemBackgroundClass(item: IMarketplaceKitItem): EBackgroundClass {
    const { rarity, color } = item.baseItem;
    const classKey = (rarity ?? color).toLowerCase();
    const palette = RProductCardBackgroundClass[classKey];

    return palette ?? EBackgroundClass.CONSUMER;
  }

  getItemPhaseClass(item: IMarketplaceKitItem): EPhaseClass | '' {
    const { phase } = item.baseItem;
    const classKey = (phase ?? '').toLowerCase();

    return RProductCardPhaseClass[classKey] ?? '';
  }
}
