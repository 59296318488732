@if (canShowSelf()) {
  <div
    class="tw-flex tw-flex-col tw-w-full"
    [delay]="0"
    (resized)="onResize($event)"
  >
    <div class="tw-text-base tw-font-semibold tw-text-gray-150">
      {{ 'P2P_SETTINGS.ITEM_DETAIL.SEARCH_CASES' | translate }}
    </div>

    <div class="tw-grid md:tw-max-w-[33.25rem] lg:tw-max-w-[40.625rem] tw-max-h-[25.25rem] tw-justify-items-center tw-gap-4 tw-mt-4">
      @for (item of cases() | slice: 0 : itemsToShow(); track item.id) {
        <app-case-card-new
          [name]="item.name"
          [isLoading]="isLoading()"
          [price]="item.lastRevision.price"
          [image]="item.backgroundImage || item.image"
          [chanceCount]="getMaxChance(item)"
          (click)="onCase(item)"
        />
      }
      @for (i of countEmptyCases() | repeat; track i) {
        <app-case-card-new
          [isPlaceholder]="true"
          [isLoading]="isLoading()"
        />
      }
    </div>

    @if (canShowAllCases()) {
      <button
        (click)="onShowAllCases()"
        class="tw-mt-3 tw-text-sm tw-font-normal tw-text-center tw-text-gray-150"
      >
        {{ 'P2P_SETTINGS.ITEM_DETAIL.VIEW_ALL' | translate }}
      </button>
    }
  </div>
}
