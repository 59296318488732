import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { PROJECT } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { EnvironmentService } from '@app/core/environment-service';

@Component({
  selector: 'app-tech-support',
  templateUrl: './tech-support.component.html',
  styleUrls: ['./tech-support.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, TranslateModule],
})
export class TechSupportComponent {
  readonly #environmentService = inject(EnvironmentService);
  readonly #dialogRef = inject(MatDialogRef<TechSupportComponent>);

  isRenew: boolean = this.#environmentService.isProject({ name: [PROJECT.FAST_NEW], exclude: false });
  email: string = this.#environmentService.getEnvironment().SUPPORTS_EMAIL;

  redirectLink = `mailto:${this.email}`;

  getDataBasedOnProjectType = (marketParams: string, renewParams: string): string => {
    return this.isRenew ? renewParams : marketParams;
  };

  close(): void {
    this.#dialogRef.close();
  }

  onCopy(event: MouseEvent): void {
    event.preventDefault();

    navigator.clipboard.writeText(this.email.toString());
  }
}
