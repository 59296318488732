import type {
  EClassicPhase,
  IClassicStateDto,
  IGameBet,
  IGameBetResponseDto,
  TClassicHistoryMode,
  TClassicViewMode,
  TradeOriginalItem,
} from '@dev-fast/types';

import type { IClassicStateModel } from './classic.model';

export class AddBet {
  static readonly type = '[Classic] add bet';

  constructor(readonly bet: IGameBet) {}
}

export class AddBetSuccess {
  static readonly type = '[Classic] add bet success';

  constructor(readonly response: IGameBetResponseDto) {}
}

export class ChangePhase {
  static readonly type = '[Classic] change phase';

  constructor(readonly newPhase: EClassicPhase) {}
}

export class ClearWinningsItems {
  static readonly type = '[Classic] clear winning items';
}

export class GetState {
  static readonly type = '[Classic] get state from api';
}

export class GetHistory {
  static readonly type = '[Classic] get history from api';
}

export class GetUserWinningsItems {
  static readonly type = '[Classic] get winning items';

  constructor(readonly winningItems: TradeOriginalItem[]) {}
}

export class ResetPartialState {
  static readonly type = '[Classic] reset partial state';

  constructor(readonly propertiesToClear: Array<keyof IClassicStateModel>) {}
}

export class ToggleHistoryMode {
  static readonly type = '[Classic] toggle history mode';

  constructor(readonly newHistoryMode: TClassicHistoryMode) {}
}

export class ToggleViewMode {
  static readonly type = '[Classic] toggle view mode';

  constructor(readonly newViewMode: TClassicViewMode) {}
}

export class UpdatePartialState {
  static readonly type = '[Classic] update partial state';

  constructor(readonly partialResponse: Partial<IClassicStateDto>) {}
}
