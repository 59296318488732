/**
 * Страницы, которые должны быть добавлены в SiteMap
 * комментарии [START-PARSE] и [END-PARSE]
 * нужны скрипту по созданию путей рендеров.
 * Они указывают где начинать и где заканчивать брать пути для формирования рендеров
 * Внутренние комментарии // пропускаются скриптом
 * Скрипты сидят тут: \fast-angular\scripts
 */
export const seoRoutes: string[] = [
  /*[[START-PARSE]]*/
  '/',
  '/faq',
  '/tos',
  '/privacy-policy',
  '/cookie-policy',
  '/case-battle',
  /*[[END-PARSE]]*/
];
