<div class="tw-absolute tw-overflow-visible tw-top-0 tw-translate-x-[12%] -tw-translate-y-[35%] -tw-z-10">
  <div class="tw-animate-spin-slow">
    <mat-icon
      class="!tw-size-96 tw-animate-fade-in-center"
      svgIcon="radiant-circle"
    ></mat-icon>
  </div>
</div>

<div class="tw-relative tw-flex tw-items-center tw-gap-4 tw-flex-col tw-p-8 wrapper">
  <button
    class="btn btn-transparent-darken--design btn-round btn-sm sq btn-close"
    (click)="close()"
  >
    <mat-icon
      class="icon"
      [svgIcon]="'close'"
    ></mat-icon>
  </button>

  <div class="tw-flex tw-relative tw-h-10 tw-w-full">
    <div class="tw-absolute tw-bottom-0 tw-flex tw-justify-center tw-w-full tw-h-min mask-image">
      @if (caseItems() | keyvalue; as casesStacks) {
        <ngx-flicking
          #flicking
          class="tw-w-full"
          [options]="flickingOptions()"
          [plugins]="flickingPlugins(flicking)"
        >
          @for (casesStack of casesStacks; track $index) {
            <div
              flicking-panel
              class="tw-w-28 tw-aspect-[3/4] tw-p-3"
              [class.tw-pointer-events-none]="uniqCasesAmount() === 1"
              [class]="isStartAnimation() ? startTranslate($index) : ''"
              [class.--startDuration]="isStartDuration() && isNeedDuration($index)"
            >
              <div class="tw-relative tw-w-full tw-h-full">
                @for (item of casesStack.value; track $index) {
                  <app-ui-case-card
                    class="tw-absolute tw-w-f tw-h-full tw-inset-0"
                    flicking-panel
                    [style]="cardsRotation($index, casesStack.value.length)"
                    [item]="item"
                    [showInput]="false"
                    [showRevision]="false"
                    [showDescription]="false"
                    [showTags]="false"
                    [isHovered]="false"
                  ></app-ui-case-card>
                }
              </div>
            </div>
          }
        </ngx-flicking>
      }
    </div>
  </div>

  <div class="tw-flex tw-text-4xl tw-text-center">{{ 'LOCAL.CASES.MODAL.BONUS.TITLE' | translate }}</div>

  <div class="tw-flex tw-text-sm tw-text-center">{{ 'LOCAL.CASES.MODAL.BONUS.DESCRIPTION' | translate }}</div>

  <button
    langRouterLink
    [routerLink]="['./game/cases/case', changedCaseItemId()]"
    class="btn semi-roun btn-inversion-yellow--design tw-w-min !tw-rounded-lg tw-mt-8"
    (click)="close()"
  >
    {{ 'LOCAL.CASES.MODAL.BONUS.OPEN' | translate }}
  </button>
</div>
