import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, model, signal, untracked, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import type { IExtraPrice, IP2pDepositItem, IUserP2pPermissionInterface } from '@dev-fast/types';
import {
  MAX_KIT_PER_EXCHANGE,
  MIN_ACCEPTABLE_OVERPRICE,
  MIN_STANDARD_OVERPRICE,
  ModalNames,
  SellVariant,
  SteamErrorsEnum,
  SteamErrorsEnumLocales,
} from '@dev-fast/types';
import { TabsComponent, TagComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import round from 'lodash-es/round';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { debounceTime, tap } from 'rxjs';

import { CurrencyComponent } from '@app/core/currency';
import { getSafeOverprice } from '@app/shared/utils';
import type { ISelectionMenuItem } from '@app/ui/components/lib/form-elements/autocomplete-menu/shared';
import { TabComponent } from '@app/ui/components/lib/tabs/components/tab/tab.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SihService } from '@app/widgets/sih';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SihCheckTooltipComponent } from '@app/widgets/sih/components/sih-check-tooltip/sih-check-tooltip.component';

import type { DepositBasketEngine } from '../../symbols';
import { SimpleSortingComponent } from '../shared/simple-sorting/simple-sorting.component';
import { SORTING_METHODS, TAB_OPTIONS } from './const';
import { ExchangeBasketCardComponent } from './exchange-basket-card/exchange-basket-card.component';

@Component({
  selector: 'app-new-exchange-basket',
  standalone: true,
  providers: [SihService],
  templateUrl: './new-exchange-basket.component.html',
  styleUrl: './new-exchange-basket.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CurrencyComponent,
    SihCheckTooltipComponent,
    TranslateModule,
    UpperCasePipe,
    MatSlider,
    ReactiveFormsModule,
    TabsComponent,
    TabComponent,
    MatIcon,
    ExchangeBasketCardComponent,
    NgScrollbarModule,
    MatSliderThumb,
    TagComponent,
    SimpleSortingComponent,
  ],
})
export class NewExchangeBasketComponent {
  readonly #dialogRef = inject(MatDialogRef<NewExchangeBasketComponent>);
  readonly #p2pExchangeBasketService: DepositBasketEngine = inject<DepositBasketEngine>(MAT_DIALOG_DATA);

  readonly #sihService = inject(SihService);

  minStandardOverprice = signal(MIN_STANDARD_OVERPRICE);

  nativeBreakpoint = toSignal<string>(this.#p2pExchangeBasketService.nativeBreakpoint$);

  overprice = toSignal<number, number>(this.#p2pExchangeBasketService.overprice$, { initialValue: MIN_ACCEPTABLE_OVERPRICE });
  overpriceText = computed(() => {
    const overprice = this.overprice();
    return {
      low: overprice < -4,
      medium: overprice >= -4 && overprice <= 10,
      high: overprice > 10,
    };
  });

  permissions = toSignal<IUserP2pPermissionInterface>(this.#p2pExchangeBasketService.p2pPermissions$);
  permissionErrorBtnText = computed<string>(() => this.formatErrBtnText(this.permissions()));
  isActionBtnDisabled = computed<boolean>(() => this.permissions()?.canTrade?.error === SteamErrorsEnum.STEAM_TRADE_BAN);

  selectedSumWithOverprice = toSignal<number | null>(this.#p2pExchangeBasketService.selectedSumWithOverprice$);

  selectedItems = toSignal<IP2pDepositItem[]>(this.#p2pExchangeBasketService.selectedItems$);
  selectedItemsHasChanged = computed(() => {
    const items = this.selectedItems();
    return items ? items.some((item) => item.extra.price > item.priceRange.start && item.extra.increase > MIN_ACCEPTABLE_OVERPRICE) : false;
  });

  kitCount = toSignal<number, number>(this.#p2pExchangeBasketService.kitCount$, { initialValue: 0 });
  kitCountText = computed<string>(() => `${this.kitCount()}/${MAX_KIT_PER_EXCHANGE}`);
  canAddKit = computed(() => this.kitCount() < MAX_KIT_PER_EXCHANGE);
  canShowSlider = computed(() => (this.isKitTab() && this.canAddKit()) || !this.isKitTab());
  showSihWarn = toSignal<boolean>(this.#sihService.showSihWarn$);
  canShowSihTooltip = computed(() => this.showSihWarn() && this.selectedItemsHasChanged());
  canProceedWithTrade = computed(() => {
    const { success, canTrade, canSteamAPI } = this.permissions() || {};
    return success && canTrade?.success && canSteamAPI?.success;
  });

  countSelectedItems = toSignal<number>(
    this.#p2pExchangeBasketService.countSelectedItems$.pipe(
      tap((count) => {
        if (count === 0) {
          this.onClose();
        }
      }),
    ),
  );

  readonly isKitTab = computed(() => {
    const countSelectedItems = this.countSelectedItems();
    if (!countSelectedItems) {
      return false;
    }
    return this.selectedId() === SellVariant.KIT && countSelectedItems > 1;
  });

  #defaultTab = computed(() => {
    const countSelectedItems = this.countSelectedItems();
    return countSelectedItems ? (countSelectedItems > 1 ? SellVariant.KIT : SellVariant.SINGLE) : SellVariant.SINGLE;
  });

  selectedId = model(this.#defaultTab());
  tabOptions = signal(TAB_OPTIONS);

  sortingMethods = signal<[ISelectionMenuItem, ISelectionMenuItem]>(SORTING_METHODS);
  overpriceControl = new FormControl<number | null>(this.overprice());
  openDepositSettings = signal(true);

  #overprice$ = toObservable(this.overprice);

  constructor() {
    //выставляем наценку в MIN_ACCEPTABLE_OVERPRICE
    this.resetOverprice();

    this.#overprice$
      .pipe(
        tap((value) => {
          this.overpriceControl.patchValue(value, { emitEvent: false });
        }),
        takeUntilDestroyed(),
      )
      .subscribe();

    this.overpriceControl.valueChanges
      .pipe(
        debounceTime(100),
        tap((val) => {
          this.updateOverprice(val || MIN_ACCEPTABLE_OVERPRICE);
        }),
        takeUntilDestroyed(),
      )
      .subscribe();

    effect(() => {
      const isKitTab = this.isKitTab();
      untracked(() => {
        this.selectedId.set(isKitTab ? SellVariant.KIT : SellVariant.SINGLE);
      });
    });
  }

  onSortingTypeChange(sortValue: ISelectionMenuItem): void {
    this.#p2pExchangeBasketService.changeSorting(sortValue.name === 'ASC');
  }

  updateOverprice(newOverprice: number): void {
    this.#p2pExchangeBasketService.updateOverprice(newOverprice);
  }

  removeSelectedItem = (item: IP2pDepositItem): void => {
    this.#p2pExchangeBasketService.removeDepositItem(item);
  };

  onSetupSteam = (): void => {
    this.#p2pExchangeBasketService.openModal(ModalNames.P2P_SETUP);
  };

  onClose = (): void => {
    this.#dialogRef.close();
  };

  sellSelectedItems = (): void => {
    this.#p2pExchangeBasketService.depositSelected(this.selectedId());
    this.onClose();
  };

  onClearExchangeBasket(): void {
    this.#p2pExchangeBasketService.resetSelected();
  }

  formatErrBtnText(permissions?: IUserP2pPermissionInterface): string {
    if (!permissions) {
      return '';
    }
    let title: string | undefined = 'P2P_SETTINGS.SETUP_TITLE';
    const errorMsg = permissions?.canTrade?.error || permissions?.canSteamAPI?.error || permissions?.error;
    if (errorMsg) {
      title = SteamErrorsEnumLocales[errorMsg];
    }
    return title ?? 'P2P_SETTINGS.SETUP_TITLE';
  }

  handleErrorAction(permissions?: IUserP2pPermissionInterface | null): void {
    // Если нет permissions, выходим
    if (!permissions) {
      return;
    }
    // Получаем сообщение об ошибке из permissions
    const errorMsg: string | undefined = permissions.canTrade?.error || permissions.canSteamAPI?.error || permissions.error;
    // Определяем карту URL на основе сообщения об ошибке
    const urlMap: Record<string, string> = {
      [SteamErrorsEnum.UNBINDING_STEAM]: '/account/main',
      [SteamErrorsEnum.STEAM_GUARD]: 'https://store.steampowered.com/mobile',
      [SteamErrorsEnum.PRIVATE_INVENTORY]: 'https://steamcommunity.com/my/edit/settings',
    };
    // Открываем соответствующий URL в новой вкладке, если он есть в карте
    if (errorMsg && urlMap[errorMsg]) {
      window.open(urlMap[errorMsg], '_blank');
    }
    // Если ошибка связана с невалидной торговой ссылкой или отсутствием API ключа, или ошибка не указана
    else if (errorMsg === SteamErrorsEnum.INVALID_TRADELINK || errorMsg === SteamErrorsEnum.NO_API_KEY || !errorMsg) {
      this.onSetupSteam();
    }
  }

  onPriceChange($event: number | null | undefined, entity: IP2pDepositItem): void {
    this.#p2pExchangeBasketService.setDepositById({ ...entity, extra: this.#calcExtraValue(entity, $event) });
  }

  onToggleDepositSettings(): void {
    const depositSettingsPanelState = !!this.openDepositSettings();

    this.openDepositSettings.set(!depositSettingsPanelState);
  }

  #calcExtraValue = (entity: IP2pDepositItem, newPrice: number | null | undefined): IExtraPrice => {
    const safeOverprice = getSafeOverprice(entity);
    let overprice = ((newPrice || entity.price) / entity.price) * 100 - 100;

    let priceForItem = round(entity.price + entity.price * (overprice / 100));

    if (priceForItem > entity.priceRange.end) {
      priceForItem = entity.priceRange.end;
      overprice = (entity.priceRange.end / entity.price) * 100 - 100;
    } else if (priceForItem < entity.priceRange.start) {
      priceForItem = entity.priceRange.start;
      overprice = (entity.priceRange.start / entity.price) * 100 - 100;
    }

    return {
      increase: round(overprice),
      price: priceForItem,
      safeOverprice,
    };
  };

  resetOverprice(): void {
    this.updateOverprice(MIN_ACCEPTABLE_OVERPRICE);
  }
}
