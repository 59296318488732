import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, ElementRef, inject, input, NgZone, output } from '@angular/core';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { ResizedEvent } from './resized.event';

@Directive({
  selector: '[resized]',
  standalone: true,
})
export class ResizedDirective implements OnInit, OnDestroy {
  readonly #isServer = inject<boolean>(IS_SERVER_TOKEN);
  readonly #zone = inject(NgZone);
  readonly #element = inject(ElementRef);
  ////
  readonly resized = output<ResizedEvent>();
  ////
  readonly delay = input<number>(300);
  readonly #observer?: ResizeObserver;
  #oldRect?: DOMRectReadOnly;
  #lastCallTime = 0;
  #isDestroyed = false;

  constructor() {
    if (!this.#isServer) {
      this.#observer = new ResizeObserver((entries) => this.#zone.run(() => this.#handleResize(entries)));
    }
  }

  ngOnInit(): void {
    if (!this.#isServer) {
      this.#observer?.observe(this.#element.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if (!this.#isServer) {
      this.#observer?.disconnect();
    }
    this.#isDestroyed = true;
  }

  #handleResize(entries: ResizeObserverEntry[]): void {
    if (this.#shouldThrottle()) {
      return;
    }

    this.#lastCallTime = Date.now(); // Обновление времени последнего вызова

    requestAnimationFrame(() => {
      if (this.#isDestroyed) {
        return;
      }
      this.#emitResizeEvent(entries);
    });
  }

  #shouldThrottle(): boolean {
    const now = Date.now();
    return now - this.#lastCallTime < this.delay();
  }

  #emitResizeEvent(entries: ResizeObserverEntry[]): void {
    if (entries.length === 0 || !entries[0]) {
      return;
    }
    const domSize = entries[0];
    const resizedEvent = new ResizedEvent(domSize.contentRect, this.#oldRect);
    this.#oldRect = domSize.contentRect;

    this.resized.emit(resizedEvent);
  }
}
