@if (canShowSelf()) {
  <div class="tw-flex tw-flex-col tw-min-h-80">
    <div class="tw-flex tw-gap-3 tw-justify-between tw-w-full tw-mb-3 tw-whitespace-nowrap tw-flex-wrap">
      <div class="tw-my-auto tw-text-base tw-font-semibold tw-tracking-normal tw-leading-6 tw-text-gray-150">
        {{ 'P2P_SETTINGS.ITEM_DETAIL.SIMILAR' | translate }}
      </div>
      @if (loading()) {
        <div class="loading-gradient tw-flex tw-w-1/2 tw-h-10 !tw-rounded-xl"></div>
      } @else {
        <app-ui-tabs
          class="button-tabs tw-w-fit"
          [(selectedId)]="selectedWearType"
        >
          @for (wearType of wearTypes(); track wearType.value) {
            <app-ui-tab
              class="tw-w-full"
              [id]="wearType.value"
              >{{ wearType.name }}
            </app-ui-tab>
          }
        </app-ui-tabs>
      }
    </div>
    <div class="tw-flex tw-flex-col tw-gap-1">
      @if (loading()) {
        <div class="loading-gradient tw-flex tw-w-full tw-h-14 !tw-rounded-xl"></div>
        <div class="loading-gradient tw-flex tw-w-full tw-h-14 !tw-rounded-xl"></div>
      } @else {
        @for (item of selectedItems() | slice: 0 : maxItemsToShow; track item.id) {
          @if (getKitItem(item); as kit) {
            <app-similar-card
              class="tw-flex tw-w-full tw-h-fit"
              [float]="kit.float"
              [price]="kit.price"
              [overprice]="getDiscount(kit)"
              [specialWearType]="getSpecialWearType(kit)"
              [stickers]="kit.stickers"
            >
              @if (isSelected(item.id)) {
                <div class="tw-flex tw-gap-2">
                  <button
                    class="tw-flex tw-justify-center tw-items-center tw-p-3 tw-w-10 tw-h-10 tw-bg-blue-500 tw-rounded-xl"
                    (click)="onNavigateToCart()"
                  >
                    <mat-icon
                      class="!tw-w-4 !tw-h-4"
                      [svgIcon]="'shopping-cart-transition'"
                    />
                  </button>
                  <button
                    class="tw-flex tw-justify-center tw-items-center tw-p-3 tw-w-10 tw-h-10 tw-bg-gray-600 tw-rounded-xl"
                    (click)="onRemoveFromCart(item)"
                  >
                    <mat-icon
                      class="!tw-w-4 !tw-h-4"
                      [svgIcon]="'shopping-cart-off'"
                    />
                  </button>
                </div>
              } @else {
                <div class="tw-flex tw-gap-2">
                  <div class="tw-block tw-h-10 tw-w-10"></div>
                  <button
                    class="tw-flex tw-justify-center tw-items-center tw-p-3 tw-w-10 tw-h-10 tw-bg-gray-600 tw-rounded-xl"
                    (click)="onAddToCart(item)"
                  >
                    <mat-icon
                      class="!tw-w-4 !tw-h-4 tw-text-gray-400"
                      [svgIcon]="'shopping-cart'"
                    />
                  </button>
                </div>
              }
            </app-similar-card>
          }
        } @empty {
          <div class="tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-grow">
            <app-ui-ghost [underLocale]="'P2P_SETTINGS.ITEM_DETAIL.SIMILAR_NOT_FOUND'" />
          </div>
        }
      }
    </div>

    @if (canShowAllSkins()) {
      <button
        class="tw-mt-3 tw-w-full tw-text-sm tw-text-center tw-text-gray-150"
        (click)="onNavigateToSkin()"
      >
        {{ 'P2P_SETTINGS.ITEM_DETAIL.VIEW_ALL' | translate }}
      </button>
    }
  </div>
}
