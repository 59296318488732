import {
  EClassicHistoryMode,
  EClassicPhase,
  EClassicViewMode,
  IClassicHistory,
  IClassicPlayerConfig,
  IClassicWidgetBlock,
  TClassicHistoryMode,
  TClassicViewMode,
  Trade,
  TradeOriginalItem,
} from '@dev-fast/types';

export interface IClassicStateModel {
  biggestBet: IClassicWidgetBlock[];
  history: IClassicHistory[];
  historyMode: TClassicHistoryMode;
  lastWinner: IClassicWidgetBlock[];
  luckyPlayer: IClassicWidgetBlock[];
  phase: EClassicPhase | null;
  players: IClassicPlayerConfig[];
  prize: number;
  rafflingTimestampDiff: number | null;
  roundHash: string;
  roundNumber: number;
  roundSecret: string | null;
  tape: string[] | null;
  trades: Trade[] | null;
  viewMode: TClassicViewMode;
  winnerId: number | null;
  winningItems: TradeOriginalItem[];
}

export const CLASSIC_INITIAL_STATE: IClassicStateModel = {
  biggestBet: [],
  history: [],
  historyMode: EClassicHistoryMode.ALL,
  lastWinner: [],
  luckyPlayer: [],
  phase: null,
  players: [],
  prize: 0,
  rafflingTimestampDiff: null,
  roundHash: '',
  roundNumber: 0,
  roundSecret: null,
  tape: null,
  trades: null,
  viewMode: EClassicViewMode.LIST,
  winnerId: null,
  winningItems: [],
};
