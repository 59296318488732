import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-user-profile-chips',
  templateUrl: './profile-chips.component.html',
  styleUrls: ['./profile-chips.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileMarketChipsComponent {
  readonly title = input<string>();
  readonly value = input<string | number | null>();
}
