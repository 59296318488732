import { inject, Injectable } from '@angular/core';
import type { UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';

import { ENVIRONMENTS } from '@app/core/environment-service';

@Injectable({
  providedIn: 'root',
})
export class OnlyDevGuard {
  readonly #environment = inject(ENVIRONMENTS);
  readonly #router = inject(Router);
  readonly #isDevelop = !this.#environment.production;

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.#isDevelop) {
      return true;
    } else {
      return this.#router.navigate(['/'], { replaceUrl: true });
    }
  }
}
