import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import type { IMarketplaceKitData, IOverpriceOptions } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import type { LotCardItem } from '@app/new-market/p2p/lot-card-item-info/lot-card-item-info.component';
import { LotCardItemInfoComponent } from '@app/new-market/p2p/lot-card-item-info/lot-card-item-info.component';
import { LotCardPriceInfoComponent } from '@app/new-market/p2p/lot-card-price-info/lot-card-price-info.component';
import { CountdownFormatPipe, StickerHashPipe } from '@app/shared/pipes';
import { getSkinColorsPalette, SKIN_RARITY_V2 } from '@app/shared/utils';

export interface LotCardItemExtended extends LotCardItem {
  price: number;
  sellerPrice?: number;
  recommendedPrice?: number;
}

export const BasketCardOverpriceConfig: IOverpriceOptions = {
  verySlow: 50,
  slow: 21,
  fast: 20,
};

@Component({
  selector: 'app-basket-item-card',
  standalone: true,
  templateUrl: './basket-item-card.component.html',
  styleUrl: './basket-item-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    LotCardItemInfoComponent,
    LotCardPriceInfoComponent,
    MatIcon,
    AsyncPipe,
    CountdownFormatPipe,
    TranslateModule,
    MatCheckbox,
    StickerHashPipe,
    NgTemplateOutlet,
  ],
})
export class BasketItemCardComponent {
  purchaseItem = input<IMarketplaceKitData>();
  nativeBreakpoint = input<string>();
  /** */
  readonly overpriceOptions = input<IOverpriceOptions>(BasketCardOverpriceConfig);
  remove = output<void>();

  lotInfoArr = computed<LotCardItemExtended[][]>(
    () =>
      this.purchaseItem()?.items.map((item) => [
        {
          baseItem: item.baseItem,
          color: getSkinColorsPalette((item.baseItem.rarity || item.baseItem.color).toLowerCase(), SKIN_RARITY_V2),
          float: item.float,
          stickers: item.stickers,
          price: item.price,
          recommendedPrice: item.recommendedPrice,
        },
      ]) ?? [],
  );

  kitCount = computed(() => (this.lotInfoArr().length > 1 ? this.lotInfoArr().length : null));

  getPriceInfo([lot]: LotCardItemExtended[]): { price: number; overprice: number; recommendedPrice: number } {
    return {
      price: lot?.price || 0,
      overprice: this.#getOverprice(lot?.recommendedPrice || 0, lot?.price || 0),
      recommendedPrice: lot?.recommendedPrice || 0,
    };
  }

  onRemove(): void {
    this.remove.emit();
  }

  #getOverprice(recommendedPrice: number, price: number): number {
    return Math.round((1 - recommendedPrice / price) * 100);
  }
}
