<div class="tw-flex tw-flex-col tw-w-full tw-h-full tw-relative tw-gap-[1.33rem] sm:tw-gap-4 3xl:tw-gap-6">
  <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-gap-1 tw-font-medium">
    <span class="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-150">
      {{ 'P2P_SETTINGS.ITEM_DETAIL.STEAM_STAT' | translate }}
    </span>
    @if (loading()) {
      <div class="loading-gradient tw-flex tw-w-1/2 tw-h-10 !tw-rounded-xl"></div>
    } @else if (canShowPlaceholder()) {
      <div></div>
    } @else {
      <app-ui-tabs
        class="button-tabs tw-w-fit"
        [(selectedId)]="selectedPeriod"
      >
        @for (period of periods(); track period.value) {
          <app-ui-tab
            class="tw-w-full tw-relative"
            [id]="period.value + ''"
            >{{ period.amount }}{{ period.title | translate }}
          </app-ui-tab>
        }
      </app-ui-tabs>
    }
  </div>

  @if (loading()) {
    <div class="loading-gradient tw-flex tw-w-full tw-h-40 !tw-rounded-xl"></div>
  } @else if (canShowPlaceholder()) {
    <div class="sih-chart-placeholder tw-flex tw-justify-center tw-items-center tw-h-40">
      <app-ui-ghost [underLocale]="'ERRORS.MARKET.SIH_STAT_ERROR'" />
    </div>
  } @else {
    <div class="p2p-modal__chart">
      @if (sihDataset()) {
        <canvas
          baseChart
          [data]="lineChartData()"
          [options]="lineChartOptions()"
          [type]="chartType"
        ></canvas>
      }
    </div>
  }

  <div class="tw-flex tw-flex-wrap">
    @if (loading()) {
      <div class="loading-gradient tw-flex tw-w-3/4 tw-h-7 !tw-rounded-xl"></div>
    } @else if (canShowPlaceholder()) {
      <div class="tw-block tw-h-7"></div>
    } @else {
      <div class="tw-flex tw-items-center tw-justify-start tw-gap-2 tw-mr-10">
        <div class="tw-flex tw-items-center tw-gap-2">
          <div class="tw-bg-green-500 tw-size-2 tw-rounded-full"></div>
          <span class="tw-text-xs tw-font-semibold tw-leading-none tw-text-gray-150">
            {{ 'P2P_SETTINGS.ITEM_DETAIL.STEAM_PRICE' | translate }}
          </span>
        </div>

        <div class="tw-text-xl tw-font-medium tw-text-gray-150">
          $
          {{ priceInUSD() | number: '1.1-2' }}
        </div>
      </div>

      <div class="tw-flex tw-items-center tw-justify-start tw-gap-2">
        <div class="tw-flex tw-items-center tw-gap-2">
          <div class="tw-bg-blue-500 tw-size-2 tw-rounded-full"></div>
          <span class="tw-text-xs tw-font-semibold tw-text-gray-150">
            {{ 'P2P_SETTINGS.ITEM_DETAIL.SALES' | translate }}
          </span>
        </div>

        <div class="tw-text-xl tw-font-medium tw-leading-none tw-text-gray-150">
          {{ sales() }}
        </div>
      </div>
    }
  </div>
</div>
